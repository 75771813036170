import { AuthenticatedService } from "@awex-api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

type IpWhitelistType = {
  ip?: string;
  ipWhitelist?: Array<string>;
};

export function IPBinding() {
  const [whitelist, setWhitelist] = useState<IpWhitelistType>({});
  const [currentIPisWhite, setCurrentIPisWhite] = useState<boolean>(false);
  const [isSetIpInProcess, setIsSetIpInProcess] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    checkIp();
  }, [whitelist]);

  function getIp(): void {
    AuthenticatedService.ipGet()
      .then((response) => {
        if (!response) {
          setWhitelist({});
          return;
        }
        setWhitelist(response);
      })
      .catch((error) => {
        console.error(error);
        setWhitelist({});
      });
  }

  function checkIp() {
    if (
      whitelist &&
      whitelist.ip &&
      whitelist.ipWhitelist &&
      whitelist.ipWhitelist.length > 0
    ) {
      if (whitelist.ipWhitelist.find((item) => item === whitelist.ip)) {
        setCurrentIPisWhite(true);
        return;
      }
    }
    setCurrentIPisWhite(false);
  }

  function handleBindingClick(): void {
    if (isSetIpInProcess || !whitelist || !whitelist.ip) return;
    setIsSetIpInProcess(true);
    AuthenticatedService.ipSet({
      ipWhitelist: [whitelist.ip],
    })
      .then((response) => {
        if (response && response.message === "Successfully updated") {
          toast.success(intl.formatMessage({ id: "ADDED_SUCCESSFULLY" }));
        } else {
          toast.error(intl.formatMessage({ id: "ERROR.ADDING_FAILED" }));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(intl.formatMessage({ id: "ERROR.ADDING_FAILED" }));
      })
      .finally(() => {
        getIp();
        setIsSetIpInProcess(false);
      });
  }

  return (
    <div className="settings-profile__select">
      <div className="settings-security__header">
        <h3 className="settings-security__title">
          <FormattedMessage id="IP_BINDING" />
        </h3>
      </div>

      <div className="settings-security__middle">
        <p className="settings-security__text">
          <FormattedMessage id="IP_BINDING.DESCRIPTION" />
        </p>
      </div>

      {!currentIPisWhite && (
        <button
          type="button"
          className="settings-security__btn main-btn"
          onClick={handleBindingClick}
        >
          <FormattedMessage id="BIND_CURRENT_IP" />
        </button>
      )}

      {currentIPisWhite && (
        <p className="settings-security__text settings-security__enter--green">
          <FormattedMessage
            id="CURRENT_IP"
            values={{
              ip: whitelist.ip,
            }}
          />
        </p>
      )}
    </div>
  );
}
