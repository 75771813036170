import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const LandingFooter: React.FC = () => {
  const intl = useIntl();

  return (
    <footer className="footer footer-app">
      <div className="footer__container">
        <div className="footer__inner">
          <div className="footer__lists">
            <ul className="footer__list">
              <li className="footer__item footer__item--header">
                <FormattedMessage id="LANDING.FOOTER.ABOUT_AWEX" />
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.ABOUT_US" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.OUR_TEAM" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.AWEX_BLOG" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.KNOWLEDGE_BASE" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.FAQ" />
                </a>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item--header">
                <FormattedMessage id="LANDING.FOOTER.STOCK_EXCHANGE" />
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="DASHBOARD" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.TRADE" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.EXCHANGE" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.RATES" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.COMMISSIONS" />
                </a>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item--header">
                <FormattedMessage id="LANDING.FOOTER.DOCUMENTATION" />
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.LICENSE" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.TERMS_OF_USE" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.PRIVACY_POLICY" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.ABOUT_RISK" />
                </a>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__item--header">
                <FormattedMessage id="LANDING.FOOTER.MORE" />
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.HOW_TO_BUY_BITCOIN" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.HOW_TO_BUY_ETH" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.HOW_TO_BUY_WITH_CARD" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.HOW_TO_WORK_WITH_FEATURES" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.BUY_CRYPTO_BY_FIAT" />
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  <FormattedMessage id="LANDING.FOOTER.CRYPTO_ACQUIRING_FOR_BUSINESS" />
                </a>
              </li>
            </ul>

            <div className="footer__socials">
              <h4 className="footer__socials-title">
                <FormattedMessage id="LANDING.FOOTER.COMMUNITY" />
              </h4>

              <div className="footer__socials-list">
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/dzen light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.DZEN.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/telegram light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.TELEGRAM.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/bitsmedia light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.BISTMEDIA.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/vk light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.VK.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/vc ru light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.VC.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/twitter light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.TWITTER.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/pikabu light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.PIKABU.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/inst light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.INSTAGRAM.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/facebook light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.FACEBOOK.ALT",
                    })}
                  />
                </a>
                <a className="footer__socials-item" href="">
                  <img
                    src="/img/icons/medium light ver.svg"
                    alt={intl.formatMessage({
                      id: "LOGO.MEDIUM.ALT",
                    })}
                  />
                </a>
              </div>

              <a className="footer__socials-link main-btn" href="#">
                <FormattedMessage id="SUPPORT" />
              </a>
            </div>
          </div>

          <div className="footer__app">
            <div className="footer__app-label">
              <FormattedMessage id="LANDING.FOOTER.APP_AWEX" />
            </div>

            <div className="footer__app-list">
              <a className="footer__app-link" href="#">
                <img
                  src="/img/icons/apple.svg"
                  alt={intl.formatMessage({ id: "LOGO.APP_STORE.ALT" })}
                />

                <span>
                  <FormattedMessage id="APP_STORE" />
                </span>
              </a>

              <a className="footer__app-link" href="#">
                <img
                  src="/img/icons/googlePlay.svg"
                  alt={intl.formatMessage({ id: "GOOGLE_PLAY" })}
                />

                <span>
                  <FormattedMessage id="GOOGLE_PLAY" />
                </span>
              </a>

              <a className="footer__app-link footer__app-link--row" href="#">
                <img
                  src="/img/icons/app-qr.svg"
                  alt={intl.formatMessage({ id: "ICON.DOWNLOAD.ALT" })}
                />

                <span>
                  <FormattedMessage id="DOWNLOAD_APP" />
                </span>
              </a>
            </div>
          </div>

          <div className="footer__copys">
            <p className="footer__copy-name">
              <FormattedMessage id="AWEX" />
            </p>
            <div className="footer__copy-text">
              <FormattedMessage
                id="COPYRIGHT"
                values={{
                  year: new Date().getFullYear(),
                }}
              />
              <a href="#" target="_blank">
                <FormattedMessage id="CRYPTO_EXPLORER_DMCC" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
