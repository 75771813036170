import { useEffect, useState } from "react";
import { AuthorizedService } from "@awex-api";
import { HistoryFilters } from "./HistoryFilters";
import { useShortString } from "../../../hooks/useShortString";
import { useInView } from "react-intersection-observer";
import { HistoryOperationsHeader } from "./HistoryOperationsHeader";
import { FormattedMessage } from "react-intl";
import daysjs from "dayjs";

interface TransactionsQuery {
  startTime?: string;
  endTime?: string;
  projectId?: string;
  currency?: string;
  type?: string;
  classType?: string;
}

interface History {
  id: number;
  orderId: number;
  date: number;
  userId: number;
  type: string; // 'debit' | ''
  class: string;
  paymentOrderAmount: number;
  paymentDepositAmount: number;
  paymentTotalAmount: number;
  paymentFeeAmount: number;
  currency: string;
  invoice: string;
  details: string;
  projectId: number;
}

interface HistoryOperationsProps {
  isFullFrame?: boolean;
}

export function HistoryOperations(props: HistoryOperationsProps) {
  const [shortingString, setString, shortString] = useShortString("", 8);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const [historyFilter, setHistoryFilter] = useState<TransactionsQuery>({});
  const [histories, setHistories] = useState<History[]>([]);
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [getingHistoryInProcess, setGetingHistoryInProcess] =
    useState<boolean>(false);

  useEffect(() => {
    getHistory();
  }, [historyFilter, historyPage]);

  useEffect(() => {
    scrollLaod();
  }, [inView]);

  function getHistory() {
    if (getingHistoryInProcess) return;
    setGetingHistoryInProcess(true);
    AuthorizedService.getTransactions(historyFilter, historyPage?.toString())
      .then((response) => {
        if (!response) {
          setHistories([]);
          return;
        }
        const newHistories =
          historyPage === 1
            ? [...response.list]
            : [...histories, ...response.list];
        setHistories(newHistories);
        setPages(response.pages);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setGetingHistoryInProcess(false);
      });
  }

  function changeHistoryFilter(newFilter: TransactionsQuery): void {
    const newHistoryFilter = {
      ...historyFilter,
      ...newFilter,
    };
    setHistoryFilter(newHistoryFilter);
    setHistoryPage(1);
  }

  function scrollLaod(): void {
    if (!inView) return;
    historyPage < pages && setHistoryPage(historyPage + 1);
  }

  return (
    <div className="history-operations">
      {!props.isFullFrame && <HistoryOperationsHeader />}

      <HistoryFilters
        isFullFrame={props.isFullFrame}
        setFilter={changeHistoryFilter}
      />

      <div
        className={`history-operations__container${
          props.isFullFrame ? " history-operations__container_full" : ""
        }`}
      >
        <ul className="history-operations__list">
          <li className="history-operations__item history-operations__item-header">
            <div className="history-operations__item-data">
              <FormattedMessage id="DATE" />
            </div>
            <div className="history-operations__item-time">
              <FormattedMessage id="TIME" />
            </div>
            <div className="history-operations__item-user">
              <FormattedMessage id="USER" />
            </div>
            <div className="history-operations__item-type">
              <FormattedMessage id="TYPE" />
            </div>
            <div className="history-operations__item-sum">
              <FormattedMessage id="AMOUNT_OF_OPERATION" />
            </div>
            <div className="history-operations__item-deposite">
              <FormattedMessage id="DEPOSIT" />
            </div>
            <div className="history-operations__item-check">
              <FormattedMessage id="BILL" />
            </div>
            <div className="history-operations__item-details">
              <FormattedMessage id="DETAILS" />
            </div>
          </li>

          {histories &&
            histories.length > 0 &&
            histories.map((history) => {
              const date = history.date ? history.date * 1000 : 0;

              return (
                <li className="history-operations__item" key={history.id}>
                  <div className="history-operations__item-data">{ date && daysjs(date).format("DD.MM.YYYY") }</div>
                  <div className="history-operations__item-time">{ date && daysjs(date).format("HH:MM")}</div>
                  <div className="history-operations__item-user">
                    {history.userId}
                  </div>
                  <div className="history-operations__item-type">
                    {history.type}
                  </div>
                  <div className="history-operations__item-sum">
                    {history.paymentOrderAmount} {history.currency}
                  </div>
                  <div className="history-operations__item-deposite">
                    {history.paymentDepositAmount} {history.currency}
                  </div>
                  <div className="history-operations__item-check">
                    {shortString(history.invoice)}
                  </div>
                  <div className="history-operations__item-details">
                    {history.details}
                  </div>
                </li>
              );
            })}

          <li className="history-operations__item" ref={ref}></li>
        </ul>
      </div>
    </div>
  );
}
