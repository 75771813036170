export const HOME_PAGE_PATH = "/";
export const ASSETS_ROUTE = "/dashboard/assets";
export const NOTIFICATIONS_PAGE = "/dashboard/notifications";

export const ROUTE = {
  HOME_PAGE_PATH: "/",
  HOME_PERSONAL_PAGE_PATH: "/personal",
  HOME_BUSINESS_PAGE_PATH: "/business",
  DASHBOARD_PATH: "/dashboard",
  INVOICE_PATH: "/dashboard/invoice",
  INVOICE_TEMPLATES_PATH: "/dashboard/invoice-templates",
  SUCCESSFULLY_INVOICE_PATH: "/dashboard/successfully-invoice",
  DEPOSITS_PATH: "/dashboard/deposits",
  DEPOSIT_RETENTION_PATH: "/dashboard/deposit-retention",
  DEPOSIT_WITHHOLD_REQUESTS: "/dashboard/deposit-withhold-requests",
  PROJECTS_PATH: "/dashboard/projects",
  PROJECTS_PROJECTID_PATH: "/dashboard/projects/",
  PROJECTS_NEW_PROJECT_PATH: "/dashboard/projects/new-project",
  SETTINGS_PATH: "/dashboard/settings",
  ASSETS_ROUTE_PATH: "/dashboard/assets",
  ASSETS_ROUTE_ASSETID_ACTION_PATH: "/dashboard/assets",
  INFOCENTER_PATH: "/dashboard/infocenter",
  DATE_PICKER_PATH: "/dashboard/date-picker",
  NOTIFICATIONS_PATH: "/dashboard/notifications",
  HISTORY_PATH: "/dashboard/history",
  REFERRAL_PATH: "/dashboard/referral",
  PAYMENT_CRYPTO_STAGE_PATH: "/dashboard/payment-crypto",
  PAYMENT_UNIQUEID_PATH: "/payment",
  INDEX_PATH: "/dashboard/index",
  AUTH_PATH: "/auth",
  ADMIN_AUTH_PATH: "/dashboard/admin/auth",
  INTEGRATION_PATH: "/dashboard/integration",
  INTEGRATION_API_PATH: "/dashboard/integration-api",
  INTEGRATION_CMS_MODULE_PATH: "/dashboard/integration-cms-module",
  INTEGRATION_HTML_WIDJET_PATH: "/dashboard/integration-html-widjet",
  REFERRAL_LINK_PATH: "/dashboard/referral-link",
  ADMIN_PATH: "/admin",
  ADMIN_MERCHANTS_PATH: "/admin/merchants",
  ADMIN_MERCHANT_STATS_SUBROUTE_PATH: "/dashboard/stats",
  ADMIN_STATS_PATH: "/admin/statistics",
  ADMIN_APPLICATIONS_PATH: "/admin/applications",
  ADMIN_APPLICATIONS_PROJECTS_PATH: "/admin/applications/projects",
  ADMIN_APPLICATIONS_PROJECTS_DETAILS_PATH:
    "/admin/applications/projects/:projectId",
  ADMIN_APPLICATIONS_OFFICE_ADDRESS_PATH: "/admin/applications/office-address",
  ADMIN_APPLICATIONS_OFFICE_ADDRESS_DETAILS_PATH:
    "/admin/applications/office-address/:applicationId",
  ADMIN_APPLICATIONS_OFFICE_ADDRESS_REQUEST_DETAILS_PATH:
    "/admin/applications/office-address/:applicationId/request",
  ADMIN_APPLICATIONS_CASH_ORDER: "/admin/applications/cash-order",
  ADMIN_APPLICATIONS_CASH_ORDER_DETAILS:
    "/admin/applications/cash-order/:applicationId",
  ADMIN_APPLICATIONS_DEPOSIT_PROCESSING:
    "/admin/applications/deposit-processing",
  ADMIN_COMMISSION_PATH: "/admin/commission",
  EMPLOYEE_ACTIVITY_PATH: "/dashboard/employee-activity",
  STATS_PATH: "/dashboard/stats",
  ORDER_CASH_PATH: "/dashboard/order-cash",
  DEPOSIT_WITHHOLD_PATH: "/dashboard/deposit-withhold"
};
