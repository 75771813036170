import { Helmet } from "react-helmet-async";
import { NotFoundErrorMessage } from "@components/NotFoundErrorMessage";
import { useIntl } from "react-intl";

export function UserAreaNotFoundPage() {
  const intl = useIntl();

  return (
    <div className="wrapper">
      <Helmet title={intl.formatMessage({ id: "PAGE_NOT_FOUND" })} />
      <NotFoundErrorMessage />
    </div>
  );
}
