import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ProjectValidation } from "src/types";

interface Validation {
  validation?: ProjectValidation | null;
  validationRequestedAt?: number | null;
}

export interface ProjectItemProps {
  id: string;
  name: string;
  tokenIcon: string;
  tokenSymbol: string;
  url: string;
  commissionPaidBy: "client" | "merchant";
  validation: Validation | null;
  onGeneratePaymentLink: (id: string) => void;
}

function Validation({
  validation,
  validationRequestedAt,
}: {
  validation?: ProjectValidation | null;
  validationRequestedAt?: number | null;
}) {
  if (validation === null && validationRequestedAt === null) {
    return (
      <span className="validtext_notvalidate">
        <FormattedMessage id="DRAFT" />
      </span>
    );
  }

  if (validation?.status === "onReview" || validation?.status === "waiting") {
    return (
      <span className="validtext_onvalidate">
        <FormattedMessage id="MODERATION" />
      </span>
    );
  }

  if (validation?.status === "rejected") {
    return (
      <span className="validtext_rejected">
        <FormattedMessage id="REJECTED" /> ({validation?.reject?.reason})
      </span>
    );
  }
  return <></>;
}

export function ProjectItem(props: ProjectItemProps) {
  const intl = useIntl();

  const handleGenerateLinkBtnClick = () => {
    props.onGeneratePaymentLink(props.id);
  };

  let commissionPayByLabel;
  switch (props.commissionPaidBy) {
    case "client": {
      commissionPayByLabel = intl
        .formatMessage({ id: "CUSTOMER" })
        .toLowerCase();
      break;
    }
    case "merchant": {
      commissionPayByLabel = intl
        .formatMessage({ id: "MERCHANT" })
        .toLowerCase();
      break;
    }
  }

  return (
    <li className="my-projects__item">
      <div className="my-projects__item-info">
        <Validation
          validation={props.validation?.validation}
          validationRequestedAt={props.validation?.validationRequestedAt}
        />

        <h3 className="my-projects__item-title main-title">
          {props.name}

          <Link to={ROUTE.PROJECTS_PROJECTID_PATH + props.id}>
            <img
              className="my-projects__item-icon"
              src="/img/icons/pen.svg"
              alt={intl.formatMessage({ id: "ICON.PEN.ALT" })}
            />
          </Link>
        </h3>

        <a
          className="my-projects__item-address"
          href={props.url}
          rel="noopener"
          target="_blank"
        >
          {props.url}
        </a>
      </div>

      <div className="my-projects__item-convertion">
        <div className="my-projects__item-for">
          <div className="my-projects__item-text">
            {intl.formatMessage({ id: "FORM.CONVERT_TO.LABEL" }).toLowerCase()}
          </div>

          <div className="my-projects__item-currency">
            <img
              className="my-projects__item-pic"
              src={"/img/actives/" + props.tokenIcon}
              alt={props.tokenSymbol}
            />

            <span className="my-projects__item-curr">{props.tokenSymbol}</span>
          </div>
        </div>

        <div className="my-projects__item-to">
          <div className="my-projects__item-text">
            {intl.formatMessage({ id: "FORM.FEE_PAYER.LABEL" }).toLowerCase()}
          </div>
          <div className="my-projects__item-client">{commissionPayByLabel}</div>
        </div>
      </div>

      <button
        className="my-projects__item-btn second-btn"
        onClick={handleGenerateLinkBtnClick}
      >
        <FormattedMessage id="GENERATE_PAYMENT_LINK" />
      </button>
    </li>
  );
}
