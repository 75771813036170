import { useEffect, useState } from "react";

type Sizes = "small";

type Items = {
  value: string;
  label: string;
};

interface TabsProps {
  items: Items[];
  onSelect: (index: number) => any;
  size?: Sizes;
  value?: number;
}

export default function Tabs(props: TabsProps) {
  const [tabStatusIndex, setStatusIndex] = useState<number>(0);
  const { items, onSelect, size, value } = props;
  let classSize: string = "";

  useEffect(() => {
    if (value !== undefined) {
      setStatusIndex(value);
    }
  }, [value]);

  switch (size) {
    case "small":
      classSize = "stats__selects-label";
      break;

    default:
      classSize = "stats__selects-tab";
      break;
  }

  function SelectStatusTab(event: any, idx: number) {
    event.preventDefault();
    if (value === undefined) {
      setStatusIndex(idx);
    }
    onSelect(idx);
  }

  return (
    <div className="stats__selects">
      {items.map((state, idx) => {
        return (
          <a
            href="#"
            key={state.value}
            className={`${classSize} ${idx === tabStatusIndex && "active"}`}
            onClick={(event) => {
              SelectStatusTab(event, idx);
            }}
          >
            {state.label}
          </a>
        );
      })}
    </div>
  );
}
