import { useId, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OtpLoginCode } from ".";
import { FormattedMessage, useIntl } from "react-intl";

interface gmailFormProps {
  sendOtpCdoe: (code: OtpLoginCode) => Promise<boolean>;
  sendEmail: () => void;
}

interface ConfirmModalFormData {
  code: string;
}

export default function GmailForm(props: gmailFormProps) {
  const { sendOtpCdoe, sendEmail } = props;
  const [emailIsSending, setEmailIsSending] = useState<boolean>(false);
  const codeId = useId();

  const intl = useIntl();

  const confirmFormSchema = yup.object({
    code: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<ConfirmModalFormData>({
    resolver: yupResolver(confirmFormSchema),
  });

  const handleGmailFormSubmit = handleSubmit((formData) => {
    sendCode(formData.code);
  });

  async function sendCode(code: string) {
    let rezult = await sendOtpCdoe({
      _2faType: "gmail",
      otp: code,
    });

    if (!rezult) {
      setError("code", {
        type: "custom",
        message: intl.formatMessage({ id: "ERROR.OTP_CODE_NOT_VALID" }),
      });
      return;
    }
    reset();
  }

  function resendEmail() {
    if (emailIsSending) return;
    sendEmail();
    setInterval(() => {
      setEmailIsSending(false);
    }, 15000);
  }

  return (
    <form className="modal-content__main" onSubmit={handleGmailFormSubmit}>
      <p className="modal-content__text">
        <FormattedMessage id="OTP.GMAIL.TITLE" />
        <button
          className="resend-btn"
          type="button"
          onClick={resendEmail}
          disabled={emailIsSending}
        >
          <FormattedMessage id="CONFIRMATION.SEND_CODE_AGAIN" />
        </button>
      </p>

      <div
        className={`my-projects__group project-group ${
          errors.code && "project-group--error"
        }`}
      >
        <label className="my-projects__label project-label" htmlFor={codeId}>
          <FormattedMessage id="GMAIL" />
        </label>
        <input
          className="my-projects__input project-input"
          id={codeId}
          type="text"
          placeholder={intl.formatMessage({
            id: "OTP.GMAIL.FORM.CODE.PLACEHOLDER",
          })}
          autoComplete="off"
          {...register("code")}
        />

        {errors.code?.message && (
          <div className="project-error">{errors.code.message}</div>
        )}
      </div>

      <button className="modal-content__btn second-btn" type="submit">
        <FormattedMessage id="CONFIRM" />
      </button>
    </form>
  );
}
