import React, { useEffect, useState } from "react"
import { DepositRetentionItem } from "../DepositRetentionPage/DepositRetentionItem"
import { Helmet } from "react-helmet-async"
import { AuthorizedService } from "@awex-api"
import toast from "react-hot-toast"
import { msg } from "@constants/messages"
import { FormattedMessage, useIntl } from "react-intl"
import { useInView } from "react-intersection-observer"
import { useLocation } from "react-router-dom"
import { DepositWithholdItem } from "./DepositWithholdItem"


// namespace OrderStatus {
//   export enum status {
//       WAIT = 'wait',
//       PAID = 'paid',
//       EXPIRED = 'expired',
//   }
// }

export type RetentionItemDeposit = {
    name?: string
    amount?: string
    currency?: string
    rate?: string
    returnTime?: number
    withholdFiles: string[]
    withholdAmount: number
    info: string
    withholdRejectReason: string
    withholdRejectedAt: number
    withholdApprovedAt: number
}

export type RetentionItem = {
  id?: number
  data?: {
      name?: string
      price?: string
      currency?: string
      rate?: string
      feePayee: boolean
      merchantName: string
      comment?: string | null
      convertTo: string | null
      feeAmount: string
      fee: number
  }
  deposit?: RetentionItemDeposit
  amount?: string
  depositAmount?: string
  depositReturnTime?: number
  buyerIdentifier?: string | null
  status?: "approved" | "waitMerchant" | "rejected" | "waitAdmin"
  createdAt?: number
  // comment: null,
  projectName: string
  projectWeb: string
}

type Retentions = {
  page: number
  pages: number
  list: Array<RetentionItem>
}

type RetentionsFilter = {
  page: number 
}


const RETENTIONS_FILTER_DEFAULT = {
  page: 1
}

export default function DepositWithholdRequestsPage() {
    const [retentions, setRetentions] = useState<Retentions | null>(null) //(RETENTIONS_DEFOULT)
    const [retentionsFilter, setRetentionsFilter] = useState<RetentionsFilter>(RETENTIONS_FILTER_DEFAULT)
    const intl = useIntl()
    const { ref, inView } = useInView({ threshold: 0.5, })
    const location = useLocation()
    const [activeRetentionId, setActiveRetentionId] = useState<number | null>(null)
  
  
    useEffect(() => {
      getRetentions()
    }, [retentionsFilter])
  
    useEffect(() => {
      scrollLaod()
    }, [inView])
  
    useEffect(() => {
      getActiveRetentionId()
    }, [location])
  
  
    function getActiveRetentionId() {
      if (location.state && "orderId" in location.state) {
          setActiveRetentionId(location.state.orderId)
      }
    }
  
    function getRetentions() {
      const { page } = retentionsFilter
      AuthorizedService.withholdRequestsList(page.toString())
      .then((response) => {
        if(!response) {
          toast.error(msg.UNEXPECTED_ERROR)
          return
        }
        const nowRetentionsList = retentions ? [ ...retentions.list ] : []
        const newRetentionsList =  response.page > 1 ? [ ...nowRetentionsList,  ...response.list] : [ ...response.list ]
        const newRetentions = {
          ...response,
          list: newRetentionsList
        }
  
        setRetentions(newRetentions)
      })
      .catch((error) => {
        console.error(error)
        toast.error(msg.SERVER_ERROR)
      })
    }
  
    function scrollLaod() {
      if(!retentions || 
        retentions.page === undefined || 
        retentions.pages === undefined || 
        retentions.page >= retentions.pages) {
          return
        }
      setRetentionsFilter((prev) => {
        return {
          ...prev,
          page: prev.page + 1 
        }
      })
    }

    return (
        <div className="wrapper">
            <Helmet
                title={intl.formatMessage({ id: "DEPOSIT_HOLD_REQUESTS" })}
            />
            <section className="deposit-retention">
                <div className="deposits__header deposits__header--start">
                    <h1 className="deposits__title main-title">
                        <FormattedMessage id="DEPOSIT_HOLD_REQUESTS" />
                    </h1>

                    <div className="deposits__info-count deposits__info-count--red">
                        { !!retentions?.list.length && retentions?.list?.length > 99 ? '99+' : retentions?.list?.length }
                    </div>
                </div>

                <ul className="deposit-retention__list">
                    {retentions?.list?.map((retention) => {
                        return (
                            <DepositWithholdItem item={retention} key={retention.id} updateData={getRetentions} isActive={retention.id === activeRetentionId} />
                        )
                    })}
                    { !!retentions?.list && (<li className="deposit-retention__item" ref={ref}></li>) }
                </ul>
            </section>
        </div>
    )
}