import { useId } from "react"
import { useIntl } from "react-intl"
import { Tooltip as ReactTooltip } from "react-tooltip"


interface ITooltipProps {
    content: string
}


export default function Tooltip({ content }: ITooltipProps) {
    const intl = useIntl()
    const id = useId()

    return (
        <>
            <img
                data-tooltip-id={id}
                className="my-projects__label-pic"
                src="/img/icons/tooltip.svg"
                alt={intl.formatMessage({ id: "ICON.TOOLTIP.ALT" })}
            />
            
            <ReactTooltip
                id={id}
                place="bottom"
                content={content}
            />
        </>
    )
}