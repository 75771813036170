import { useEffect } from "react";
import { ProjectItem } from "./ProjectItem";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getProjects } from "@store/projects/slice";
import { currencyToName } from "@constants/currency-names";
import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, useIntl } from "react-intl";

export function MyProjectsPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projects.data);

  const intl = useIntl();

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleGeneratePaymentLink = (projectId: string): void => {
    // alert("NOT IMPLEMENTED")
    // navigate('/invoice')
    navigate(ROUTE.INVOICE_PATH, { state: { projectId } });
  };

  return (
    <div className="wrapper">
      <Helmet title={intl.formatMessage({ id: "MY_PROJECTS" })} />

      <section className="my-projects">
        <div className="my-projects__header">
          <h1 className="my-projects__title main-title">
            <FormattedMessage id="MY_PROJECTS" />
          </h1>

          <div
            className="my-projects__added"
            role="button"
            onClick={() =>
              navigate(`${ROUTE.PROJECTS_PROJECTID_PATH}new-project`)
            }
          >
            <img
              className="my-projects__added-img"
              src="/img/icons/plus-circle.svg"
              alt={intl.formatMessage({ id: "ICON.PLUS.ALT" })}
            />

            <span className="my-projects__added-descr">
              <FormattedMessage id="ADD_PROJECT" />
            </span>
          </div>
        </div>

        <h2 className="main-title" hidden>
          <FormattedMessage id="LIST_OF_PROJECTS" />
        </h2>

        <div className="my-projects__items-wrapper">
          <ul className="my-projects__items">
            {projects &&
              projects.map(({ id, project }) => {
                let currency: string = "...";

                if (project.convertTo !== undefined) {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      currencyToName,
                      project.convertTo
                    )
                  ) {
                    currency = currencyToName[project.convertTo];
                  } else {
                    currency = project.convertTo;
                  }
                }

                return (
                  <ProjectItem
                    key={id}
                    id={id}
                    name={project.name}
                    tokenIcon="actives-1.png"
                    tokenSymbol={
                      (project.convertTo !== undefined &&
                        currencyToName[project.convertTo]) ||
                      "..."
                    }
                    url={project.urlWeb || "#"}
                    commissionPaidBy={project.feePayee ? "merchant" : "client"}
                    validation={
                      project
                        ? {
                            validation: project?.validation,
                            validationRequestedAt:
                              project?.validationRequestedAt,
                          }
                        : null
                    }
                    onGeneratePaymentLink={handleGeneratePaymentLink}
                  />
                );
              })}
          </ul>
        </div>
      </section>
    </div>
  );
}
