import { FormattedMessage } from "react-intl";
import { useIntegerFractionNumber } from "../../../hooks/useIntegerFractionNumber";

type ReferralScoreProps = {
  amount: number;
};

export function ReferralScore(props: ReferralScoreProps) {
  const { integer, fraction } = useIntegerFractionNumber(props.amount, 3);

  return (
    <div className="referral__score">
      <p className="referral__score-label">
        <FormattedMessage id="RECEIVED_FROM_REFERRALS" />
      </p>
      <div className="referral__score-sum">
        {integer} <span>,{fraction} $</span>
      </div>
    </div>
  );
}
