import { SupportingDocumentsForOfficeModalContainer } from "@containers/SupportingDocumentsForOfficeModalContainer";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
  officeAddress: any;
  deleteAddressHandler: (id: number) => void;
}

const OfficeAddressCard: React.FC<IProps> = ({
  officeAddress,
  deleteAddressHandler,
}) => {
  const [supportingDocumentsModalOpen, setSupportingDocumentsModalOpen] =
    useState<boolean>(false);

  const intl = useIntl();

  const handleCloseSupportingDocuments = () => {
    setSupportingDocumentsModalOpen(false);
  };

  return (
    <>
      <li key={officeAddress.id} className="settings-requisites__item">
        <div className="settings-requisites__item-block">
          {officeAddress?.data?.companyName}
        </div>

        <div className="settings-requisites__item-border"></div>

        <div className="settings-requisites__item-block settings-requisites__item--addresses">
          {officeAddress?.address}
        </div>

        <button
          type="button"
          className="settings-requisites__item-btn third-btn"
          onClick={() => setSupportingDocumentsModalOpen(true)}
        >
          <FormattedMessage id="SUPPORTING_DOCUMENTS" />
        </button>
        <button
          onClick={() => deleteAddressHandler(officeAddress?.id)}
          className="settings-requisites__item-delete-btn"
        >
          <img
            src="/img/icons/trash-red.svg"
            alt={intl.formatMessage({ id: "DELETE" })}
          />
        </button>
      </li>

      <SupportingDocumentsForOfficeModalContainer
        addressId={officeAddress?.id}
        address={officeAddress?.address}
        companyName={officeAddress?.data?.companyName}
        open={supportingDocumentsModalOpen}
        onClose={handleCloseSupportingDocuments}
      />
    </>
  );
};

export default OfficeAddressCard;
