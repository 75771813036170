import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card } from "src/generated/awex-api/models/Card";

interface IProps {
  card: Card;
  deleteCardHandler: (id: number) => void;
}

const CardItem: React.FC<IProps> = ({ card, deleteCardHandler }) => {
  const intl = useIntl();

  const cardProviderImage = card?.cardNumber?.startsWith("4") ? (
    <img src="/img/visa-card.png" alt="visa-card" />
  ) : card.cardNumber.startsWith("5") ? (
    <img src="/img/mastercard-card.png" alt="mastercard-card" />
  ) : (
    <img src="/img/mir-card.png" alt="mir-card" />
  );

  return (
    <li className="settings-requisites__item-wrapper">
      <div className="settings-requisites__item">
        <div className="settings-requisites__item-block">
          {cardProviderImage}
        </div>

        <div className="settings-requisites__item-block">
          {card.cardNumber}
          <span>({card.cardName})</span>
        </div>

        <div className="settings-requisites__item-border"></div>

        <div className="settings-requisites__item-block">
          <FormattedMessage id="UNTIL" /> {card.cardMonth}/
          {card.cardYear.slice(2)}
        </div>

        <div className="settings-requisites__item-border"></div>

        <div className="settings-requisites__item-block">
          <FormattedMessage id="AMOUNT_OF_OPERATIONS" /> 0
        </div>
      </div>
      <button
        onClick={() => deleteCardHandler(card?.id!)}
        className="settings-requisites__item-delete-btn"
      >
        <img
          src="/img/icons/trash-red.svg"
          alt={intl.formatMessage({ id: "ICON.REMOVE.ALT" })}
        />
      </button>
    </li>
  );
};

export default CardItem;
