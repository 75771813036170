import { AuthorizedService } from "@awex-api";
import React, { useEffect, useState } from "react";
import OfficeAddressList from "./OfficeAddressList";
import { NewAddressRequisiteModalContainer } from "@containers/NewAddressRequisiteModalContainer";
import { FormattedMessage } from "react-intl";

interface IProps {
  deleteAddressHandler: (id: number) => void;
}

const OfficeAddresses: React.FC<IProps> = ({ deleteAddressHandler }) => {
  const [officeAddresses, setOfficeAddresses] = useState<any[]>([]);
  const [newOfficeAddressModalOpen, setNewOfficeAddressModalOpen] =
    useState<boolean>(false);

  const handleCloseNewOfficeAddress = () => {
    setNewOfficeAddressModalOpen(false);
  };

  useEffect(() => {
    AuthorizedService.getOfficeAddresses().then((res) => {
      setOfficeAddresses(res.list);
    });
  }, [newOfficeAddressModalOpen, deleteAddressHandler]);

  return (
    <div className="settings-requisites__list-wrapper">
      <div className="settings-requisites__header">
        <h3 className="settings-requisites__title">
          <FormattedMessage id="MY_ADDRESSES" />
        </h3>

        <button
          className="settings-requisites__added"
          onClick={() => setNewOfficeAddressModalOpen(true)}
        >
          <FormattedMessage id="ADD_ADDRESS" />
        </button>
      </div>

      {officeAddresses?.length > 0 ? (
        <OfficeAddressList
          officeAddresses={officeAddresses}
          deleteAddressHandler={deleteAddressHandler}
        />
      ) : (
        <p className="settings-requisites__empty-list-text">
          <FormattedMessage id="YOU_HAVE_NO_ADDRESSES" />
        </p>
      )}

      <NewAddressRequisiteModalContainer
        open={newOfficeAddressModalOpen}
        onClose={handleCloseNewOfficeAddress}
      />
    </div>
  );
};

export default OfficeAddresses;
