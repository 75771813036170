import classNames from "classnames";
import { useDropdown } from "../../../../hooks/useDropdown";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../../../../contexts/LanguageContext";
import { useContext } from "react";
import { ProfileData } from "@awex-api";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import toast from "react-hot-toast";
import { setAccountProfile } from "@store/accountProfile/slice";

export const LanguageSelector: React.FC = () => {
  const langDropdown = useDropdown<HTMLDivElement>();
  const intl = useIntl();
  const context = useContext(LanguageContext);
  const dispatch = useAppDispatch();
  const languageProfile = useAppSelector(
    (state) => state.accountProfile.data?.language
  );
  const opts = useAppSelector((state) => state.accountProfile.data);

  const language =
    languageProfile === null ? context?.language : languageProfile;

  const handleOptionClick = (language: string) => {
    const requests: ProfileData = {
      name: opts?.name!,
      email: opts?.email!,
      telegram: opts?.telegram!,
      displayCurrency: opts?.displayCurrency!,
      language,
    };
    dispatch(setAccountProfile(requests))
      .unwrap()
      .then(() => {
        toast.success(<FormattedMessage id="SUCCESS.PROFILE_UPDATED" />);
      })
      .catch((error) => {
        console.error(error);
        toast.error(<FormattedMessage id="ERROR.FAILED_TO_UPDATE_PROFILE" />);
      });
    context?.switchLanguage(language);
    langDropdown.toggle(false);
  };

  return (
    <div
      className="settings-profile__select ___not_implemented"
      data-select-wrapper=""
      ref={langDropdown.containerRef}
      onClick={() => langDropdown.toggle()}
    >
      <div
        className={classNames("settings-profile__language", {
          active: langDropdown.opened,
        })}
        data-select-arrow=""
      >
        <FormattedMessage id="LANGUAGE_SELECTION" />
        {language === "ru" && (
          <div className="settings-profile__language-selected">
            <img
              src="/img/icons/ru.svg"
              alt={intl.formatMessage({ id: "RUSSIAN" })}
            />

            <img src="/img/icons/arrow-down.svg" alt="arrow-down" />
          </div>
        )}
        {language === "en" && (
          <div className="settings-profile__language-selected">
            <img
              src="/img/icons/en.svg"
              alt={intl.formatMessage({ id: "ENGLISH" })}
            />

            <img src="/img/icons/arrow-down.svg" alt="arrow-down" />
          </div>
        )}
        {language === "ch" && (
          <div className="settings-profile__language-selected">
            <img
              src="/img/icons/chi.svg"
              alt={intl.formatMessage({ id: "CHINESE" })}
            />

            <img src="/img/icons/arrow-down.svg" alt="arrow-down" />
          </div>
        )}
        {language === "vn" && (
          <div className="settings-profile__language-selected">
            <img
              src="/img/icons/vietnam.svg"
              alt={intl.formatMessage({ id: "VIETNAMESE" })}
            />

            <img src="/img/icons/arrow-down.svg" alt="arrow-down" />
          </div>
        )}
      </div>
      <ul
        className={classNames("invoice-project__group-list select-list", {
          active: langDropdown.opened,
        })}
        data-select-list=""
      >
        <li
          className="invoice-project__group-item select-item"
          data-select-item=""
          onClick={() => handleOptionClick("ru")}
        >
          <img
            src="/img/icons/ru.svg"
            alt={intl.formatMessage({ id: "RUSSIAN" })}
          />
        </li>

        <li
          className="invoice-project__group-item select-item"
          data-select-item=""
          onClick={() => handleOptionClick("en")}
        >
          <img
            src="/img/icons/en.svg"
            alt={intl.formatMessage({ id: "ENGLISH" })}
          />
        </li>

        <li
          className="invoice-project__group-item select-item"
          data-select-item=""
          onClick={() => handleOptionClick("ch")}
        >
          <img
            src="/img/icons/chi.svg"
            alt={intl.formatMessage({ id: "CHINESE" })}
          />
        </li>

        <li
          className="invoice-project__group-item select-item"
          data-select-item=""
          onClick={() => handleOptionClick("vn")}
        >
          <img
            src="/img/icons/vietnam.svg"
            alt={intl.formatMessage({ id: "VIETNAMESE" })}
          />
        </li>
      </ul>
    </div>
  );
};
