import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { getAccountBalance } from "@store/accountBalance/slice";
import { NavLink } from "react-router-dom";
import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, useIntl } from "react-intl";

interface Balance {
  balance: {
    int: string;
    fract: string;
  };
  currency: string;
}

export function CheckBalance() {
  const [userBalance, setUserBalance] = useState<Balance | null>(null);
  const dispatch = useAppDispatch();
  const accountBalance = useAppSelector((state) => state.accountBalance.data);

  const intl = useIntl();

  useEffect(() => {
    dispatch(getAccountBalance());
  }, []);

  useEffect(() => {
    formattingBalance();
  }, [accountBalance]);

  function formattingBalance(): void {
    const { balance, currency } = accountBalance;
    const balanceSegmented: string[] = balance.split(".");
    setUserBalance({
      balance: {
        int: balanceSegmented[0],
        fract: balanceSegmented[1] ? balanceSegmented[1] : "",
      },
      currency,
    });
  }

  return (
    <div className="about-check__balance">
      <div className="about-check__balance-inner">
        <div className="about-check__balance-labels">
          <div className="about-check__balance-label">
            <FormattedMessage id="GENERAL_BALANCE" />
          </div>

          <NavLink
            className="about-check__balance-statistic ___not_implemented"
            to={ROUTE.STATS_PATH}
          >
            <FormattedMessage id="STATISTIC" />
            <img
              className="about-check__balance-statistic-img"
              src="/img/icons/arrow-right.svg"
              alt={intl.formatMessage({ id: "ICON.ARROW_RIGHT.ALT" })}
            />
          </NavLink>
        </div>

        {userBalance && (
          <div className="about-check__balance-sum">
            {userBalance.balance.int}
            <span>
              {userBalance.balance.fract && <>,{userBalance.balance.fract}</>}
              {userBalance.currency}
            </span>
          </div>
        )}

        <div className="about-check__graphic">
          <img
            className="about-check__graphic-img"
            src="/img/balance-graphic.svg"
            alt={intl.formatMessage({ id: "IMAGE.BALANCE_GRAPHIC.ALT" })}
          />
        </div>
      </div>

      <NavLink
        to={ROUTE.ORDER_CASH_PATH}
        className="about-check__btn main-btn"
        style={{
          width: "max-content",
        }}
      >
        <FormattedMessage id="ORDER_CASH_TO_OFFICE" />
      </NavLink>
    </div>
  );
}
