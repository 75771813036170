import { FormattedMessage } from "react-intl";

export interface ConenctionErrorMessageProps {
  onRetry?: () => void;
}

export function InternalErrorMessage(props: ConenctionErrorMessageProps) {
  return (
    <section className="page-404">
      <div className="page-404__inner">
        <p className="page-404__descr">
          <FormattedMessage id="ERROR.INTERNAL_ERROR" />
        </p>
        {!!props.onRetry && (
          <button className="page-404__link main-btn" onClick={props.onRetry}>
            <FormattedMessage id="RETRY" />
          </button>
        )}
      </div>
    </section>
  );
}
