import { useEffect, useState } from "react";
import { DepositItem } from "../DepositItem";
import { useInView } from "react-intersection-observer";
import { Deposit } from "..";
import { FormattedMessage } from "react-intl";

interface DepositsListPropsTyeps {
  depositsList: Deposit[];
  onLoadMore: () => void;
}

export function DepositsList(props: DepositsListPropsTyeps) {
  const { onLoadMore, depositsList } = props;
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    scrollLaod();
  }, [inView]);

  function scrollLaod(): void {
    if (!inView) return;
    onLoadMore();
  }

  return (
    <div className="history-operations__container history-operations__container_full">
      <ul className="deposits__list">
        <li className="deposits__item-header">
          <div className="deposits__item-status"></div>
          <div className="deposits__item-id">
            <FormattedMessage id="TABLE.NAME" />
          </div>
          <div className="deposits__item-data">
            <FormattedMessage id="DATE" />
          </div>
          <div className="deposits__item-status-deposite">
            <FormattedMessage id="TABLE.DEPOSIT_STATUS" />
          </div>
          <div className="deposits__item-sum">
            <FormattedMessage id="AMOUNT" />
          </div>
          <div className="deposits__item-data-end">
            <FormattedMessage id="TABLE.DATE_OF_EXPIRATION" />
          </div>
          {/* <div className="deposits__item-status-application"> Статус заявки </div> */}
          <div className="deposits__item-commets">
            <FormattedMessage id="TABLE.COMMENT" />
          </div>
        </li>
      </ul>
      {/* deposits__list-container */}
      <ul className="deposits__list">
        {depositsList.map((deposit) => {
          return <DepositItem deposit={deposit} key={deposit.id} />;
        })}

        <li ref={ref}></li>
      </ul>
    </div>
  );
}
