import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import daysjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type ChartData = {
  time: number;
  value: number;
};

interface StatsChartProps {
  data: ChartData[] | null;
}

type Datasets = {
  data: number[];
  backgroundColor: string;
  hoverBackgroundColor: string;
};

type ChartDataType = {
  labels: string[];
  datasets: Datasets[];
};

const ASPECT_RATIO_BREAKPOINT = 479;
const CHART_COLOR: string = "#D1D1D1";
const CHART_ACTIVE_COLOR: string = "#FED602";
const BACKGROUND_COLOR: string = "#F6F6F6";
const TEXT_COLOR: string = "#6F6F6F";

const ChartDataTypeDefault: ChartDataType = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: CHART_COLOR,
      hoverBackgroundColor: CHART_ACTIVE_COLOR,
    },
  ],
};

const screenWidth = window.screen.width;

const options = {
  responsive: true,
  aspectRatio: screenWidth > ASPECT_RATIO_BREAKPOINT ? 3 : 2,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: BACKGROUND_COLOR,
      titleColor: TEXT_COLOR,
      bodyColor: TEXT_COLOR,
      displayColors: false,
    },
  },
  options: {
    scales: {
      y: {
        ticks: {
          callback: function (value: string): string {
            return "$" + value;
          },
        },
      },
    },
  },
};

export default function StatsChart(props: StatsChartProps) {
  const { data } = props;
  const [chartData, setChartData] =
    useState<ChartDataType>(ChartDataTypeDefault);

  useEffect(() => {
    calcChartData();
  }, [data]);

  function calcChartData() {
    if (!data) {
      setChartData(ChartDataTypeDefault);
      return;
    }
    let labels: string[] = [];
    let dataSet: number[] = [];

    data.forEach((item) => {
      let time = daysjs(item.time * 1000).format("DD.MM");
      labels.push(time);
      dataSet.push(Number(item.value));
    });
    const newChartData: ChartDataType = {
      labels: labels,
      datasets: [
        {
          data: dataSet,
          backgroundColor: CHART_COLOR,
          hoverBackgroundColor: CHART_ACTIVE_COLOR,
        },
      ],
    };
    setChartData(newChartData);
  }

  return (
    <div className="stats__graphik">
      {data && <Bar options={options} data={chartData} />}
    </div>
  );
}
