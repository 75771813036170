import { FormattedMessage } from "react-intl";
import { HistoryOperations } from "../HomePage/HistoryOperations";

export function OperationsHistoryPage() {
  return (
    <div className="wrapper">
      <section className="settings operations-history">
        <div className="deposits__header">
          <h1 className="deposits__title main-title">
            <FormattedMessage id="OPERATIONS_HISTORY" />
          </h1>
          <a className="operations-history__exel main-btn" href="#">
            {" "}
            <FormattedMessage id="TO_EXCEL" />{" "}
          </a>
        </div>

        <div className="settings__inner">
          <div className="settings__list" data-payment-details-content>
            <a className="settings__item active" href="#">
              <FormattedMessage id="PAYMENTS" />
            </a>
            <img
              className="settings__list-arrow"
              src="/img/icons/arrow-down-white.svg"
              alt=""
              data-payment-details-btn
            />
            <a className="settings__item" href="#">
              <FormattedMessage id="DEPOSITS" />
            </a>
            <a className="settings__item" href="#">
              <FormattedMessage id="WITHDRAWAL" />
            </a>
            <a className="settings__item" href="#">
              <FormattedMessage id="REFERRAL_ACCRUALS" />
            </a>
            <a className="settings__item" href="#">
              <FormattedMessage id="SWAP" />
            </a>
          </div>

          <div className="operations-history__content">
            <HistoryOperations isFullFrame={true} />
          </div>
        </div>
      </section>
    </div>
  );
}
