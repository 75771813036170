import { useId } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OtpLoginCode } from ".";
import { FormattedMessage, useIntl } from "react-intl";

interface GoogleAuthFormProps {
  sendOtpCdoe: (code: OtpLoginCode) => Promise<boolean>;
}

interface ConfirmModalFormData {
  code: string;
}

export default function GoogleAuthForm(props: GoogleAuthFormProps) {
  const { sendOtpCdoe } = props;
  const codeId = useId();

  const intl = useIntl();

  const confirmFormSchema = yup.object({
    code: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<ConfirmModalFormData>({
    resolver: yupResolver(confirmFormSchema),
  });

  const handleGoogleAuthFormSubmit = handleSubmit((formData) => {
    sendCode(formData.code);
  });

  async function sendCode(code: string) {
    let rezult = await sendOtpCdoe({
      _2faType: "googleAuthenticator",
      otp: code,
    });

    if (!rezult) {
      setError("code", {
        type: "custom",
        message: intl.formatMessage({ id: "ERROR.OTP_CODE_NOT_VALID" }),
      });
      return;
    }
    reset();
  }

  return (
    <form className="modal-content__main" onSubmit={handleGoogleAuthFormSubmit}>
      <div
        className={`my-projects__group project-group ${
          errors.code && "project-group--error"
        }`}
      >
        <label className="my-projects__label project-label" htmlFor={codeId}>
          <FormattedMessage id="GOOGLE_AUTHENTICATOR" />
        </label>
        <input
          className="my-projects__input project-input"
          id={codeId}
          type="text"
          placeholder={intl.formatMessage({
            id: "OTP.GOOGLE_AUTH.FORM.CODE.PLACEHOLDER",
          })}
          autoComplete="off"
          {...register("code")}
        />

        {errors.code?.message && (
          <div className="project-error">{errors.code.message}</div>
        )}
      </div>

      <button className="modal-content__btn second-btn" type="submit">
        <FormattedMessage id="CONFIRM" />
      </button>
    </form>
  );
}
