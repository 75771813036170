import { useState } from "react";
import { SelectCurrencyModal } from "../../../components/SelectCurrenyModal";
import { FormattedMessage, useIntl } from "react-intl";

export function PaymentMethodForm() {
  const [currencySelectorOpened, setCurrencySelectorOpened] = useState(false);
  const intl = useIntl();

  const handleCurrencySelectorClose = () => {
    setCurrencySelectorOpened(false);
  };

  return (
    <>
      <form className="payment__from payment-form" action="#">
        <div className="payment-form__group">
          <p className="payment-form__label">
            <FormattedMessage id="CHOOSE_PAYMENT_METHOD" />
          </p>

          <div className="payment-form__radios">
            <div className="payment-form__radio">
              <input
                className="payment-form__radio-input"
                type="radio"
                name="pay"
                id="pay1"
                checked
              />

              <label className="payment-form__radio-label" htmlFor="pay1">
                <FormattedMessage id="CRYPTO" />
              </label>
            </div>

            <div className="payment-form__radio">
              <input
                className="payment-form__radio-input"
                type="radio"
                name="pay"
                id="pay2"
              />

              <label className="payment-form__radio-label" htmlFor="pay2">
                <FormattedMessage id="CASH_PAYMENT" />
              </label>
            </div>

            <div className="payment-form__radio">
              <input
                className="payment-form__radio-input"
                type="radio"
                name="pay"
                id="pay3"
              />

              <label className="payment-form__radio-label" htmlFor="pay3">
                <FormattedMessage id="RUSSIAN_CARD" />
              </label>
            </div>

            <div
              className="tooltip"
              data-tooltip={intl.formatMessage({ id: "RUSSIAN_CARD.LIMITS" })}
              data-tooltip-pos="right"
              data-tooltip-length="medium"
            >
              <img
                className="payment-form__radio-pic"
                src="/img/icons/tooltip.svg"
                alt="tooltip"
              />
            </div>
          </div>

          <div className="about-deposit__generation-select invoice__generation-select">
            <div className="about-deposit__generation-selected">
              <div className="about-deposit__generation-info">
                <input
                  className="about-deposit__generation-input"
                  type="number"
                  placeholder={intl.formatMessage({
                    id: "FORM.AMOUNT.PLACEHOLDER",
                  })}
                  value="9.650"
                />
              </div>

              <div
                className="about-deposit__generation-currency open-modal-btn"
                onClick={() => setCurrencySelectorOpened(true)}
              >
                <div className="about-deposit__generation-curr">
                  <img src="/img/usdt.png" alt="" />
                  RUB
                </div>

                <img
                  className="about-deposit__generation-img"
                  src="/img/icons/arrow-down.svg"
                  alt="arrow-down"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="payment-form__group">
          <p className="payment-form__label">
            <FormattedMessage id="CHOOSE_METHOD_FOR_DEPOSIT_RETURN" />
          </p>

          <div className="payment-form__radios">
            <div className="payment-form__radio">
              <input
                className="payment-form__radio-input"
                type="radio"
                name="pay"
                id="pay4"
              />

              <label className="payment-form__radio-label" htmlFor="pay4">
                <FormattedMessage id="CRYPTO" />
              </label>
            </div>

            <div className="payment-form__radio">
              <input
                className="payment-form__radio-input"
                type="radio"
                name="pay"
                id="pay5"
                checked
              />

              <label className="payment-form__radio-label" htmlFor="pay5">
                <FormattedMessage id="RUSSIAN_CARD" />
              </label>
            </div>
          </div>

          <div className="my-projects__group project-group">
            <label className="my-projects__label project-label" htmlFor="#">
              <FormattedMessage id="FORM.CARD_NUMBER.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "FORM.CARD_NUMBER.PLACEHOLDER",
              })}
            />
          </div>

          <div className="my-projects__group project-group">
            <label className="my-projects__label project-label" htmlFor="#">
              <FormattedMessage id="FORM.FULL_NAME.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "FORM.FULL_NAME.PLACEHOLDER",
              })}
            />
          </div>
        </div>

        <div className="payment-form__group">
          <p className="payment-form__label">
            <FormattedMessage id="SEND_NOTIFICATION_TO_EMAIL" />
          </p>

          <div className="my-projects__group project-group">
            <label className="my-projects__label project-label" htmlFor="#">
              <FormattedMessage id="REGISTRATION.FORM.EMAIL.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "REGISTRATION.FORM.EMAIL.PLACEHOLDER",
              })}
            />
          </div>
        </div>

        <div className="payment-form__btns">
          <button className="payment-form__btn second-btn">
            <FormattedMessage id="PAY" />
          </button>

          <button className="payment-form__btn blue-btn">
            <img src="/img/icons/WalletConnect.svg" alt="" />
          </button>

          <button className="payment-form__btn second-btn">
            <img src="/img/icons/payment-form-awex.svg" alt="" />
          </button>
        </div>

        <div className="payment-form__footer">
          <FormattedMessage
            id="PAYMENT_AGREEMENT"
            values={{
              a: (chunks: any) => <a href="#">{chunks}</a>,
            }}
          />
        </div>
      </form>
      <SelectCurrencyModal
        open={currencySelectorOpened}
        onClose={handleCurrencySelectorClose}
      />
    </>
  );
}
