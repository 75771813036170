import React, { useEffect, useState } from "react";
import classes from "./SupportingOfficeDocumentCard.module.css";
import { AdminSupportingDocumentsViewModalContainer } from "@containers/AdminSupportingDocumentViewModalContainer";


interface IProps {
  document: string;
}


const IMAGE_TYPES = ['jpg', 'jpeg', 'png']
const PATH_BASE = 'https://awex.freeblock.site'
const UPLOAD_FILES_PATH = '/api/uploaded-files'


const SupportingOfficeDocumentCard: React.FC<IProps> = ({ document }) => {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isImage, setIsImage] = useState(false);


  useEffect(() => {
    const fileType = document.split('.').pop()
    if(fileType && IMAGE_TYPES.indexOf(fileType.toLowerCase()) < 0) {
      setIsImage(false)
      return
    }
    setIsImage(true)
  }, [document])


  const handleCloseFile = () => {
    setIsFileOpen(false);
  };

  const handleOpenFile = () => {
    if(isImage) {
      setIsFileOpen(true);
      return
    }
    window.open(`${PATH_BASE}${UPLOAD_FILES_PATH}/${document}`)
  };

  return (
    <>
      <li className={classes["card-container"]} onClick={handleOpenFile}>
        {document}
      </li>

      { isImage && (<AdminSupportingDocumentsViewModalContainer
          open={isFileOpen}
          onClose={handleCloseFile}
          fileName={document}
        />
      )}
    </>
  );
};

export default SupportingOfficeDocumentCard;
