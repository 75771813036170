import { DescribeProblemModal } from "@components/DescribeProblemModal";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

export interface DescribeProblemModalContainerProps {
  open: boolean;
  onClose: () => void;
}

export function DescribeProblemModalContainer(
  props: DescribeProblemModalContainerProps
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const intl = useIntl();

  useEffect(() => {
    setError(null);
  }, [props.open]);

  const handleSubmitProblem = (opts: { problem: string }) => {
    setError(intl.formatMessage({ id: "NOT_IMPLEMENTED" }));
  };

  return (
    <DescribeProblemModal
      open={props.open}
      loading={loading}
      error={error || undefined}
      onClose={props.onClose}
      onSubmitProblem={handleSubmitProblem}
    />
  );
}
