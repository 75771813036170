import { FormattedMessage } from "react-intl"
import { RetentionItemDeposit } from ".."


interface DepositWithholdCommentsProps {
    status?: "approved" | "waitMerchant" | "rejected" | "waitAdmin"
    data: RetentionItemDeposit | undefined
}

export default function DepositWithholdComments({ data, status }: DepositWithholdCommentsProps) {

    if(!data) return (<></>)

    switch(status) {
        case "waitMerchant": 
            return (
                <div className="deposit-retention__comments">
                    <div className="deposit-retention__label">
                        <FormattedMessage id="MORE_INFORMATION_REQUIRED" />:
                    </div>
                    <p className="deposit-retention__descr">
                        { data.info }
                    </p>
                </div>
            )

        case "approved": 
            return (
                <div className="deposit-retention__comments">
                    <div className="deposit-retention__label">
                        <FormattedMessage id="CONFIRMED" />
                    </div>
                </div>
            )

        case "rejected": 
            return (
                <div className="deposit-retention__comments">
                    <div className="deposit-retention__label">
                        <FormattedMessage id="REJECTED_FOR_REASON" />:
                    </div>
                    <p className="deposit-retention__descr">
                        { data.withholdRejectReason }
                    </p>
                </div>
            )

        default:
            return (
                <div className="deposit-retention__comments">
                    <div className="deposit-retention__label">
                        <FormattedMessage id="UNDER_CONSIDERATION" />
                    </div>
                </div>
            )
    }    
}