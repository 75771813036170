
interface CheckboxProps {
    id: string
    label: string
    attrs: any
}


export default function Checkbox(props: CheckboxProps) {
    const { id, label, attrs } = props
    
    return (
        <div className="checkbox-group">
            <input className="checkbox-input"
                type="checkbox"
                id={id}
                {...attrs}
            />
            <label
                className="checkbox-label"
                htmlFor={id}
            >
                <div className="checkbox-decor"></div> { label }
            </label>
        </div>
    )
}