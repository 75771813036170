import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import FileInput from "@components/Form/FileInput";
import { FormattedMessage, useIntl } from "react-intl";

export interface SupportingDocumentsForOfficeAddressModalProps {
  open: boolean;
  loading: boolean;
  error?: string;
  onClose: () => void;
  onSubmitSupportingDocuments: (opts: { files: File[] }) => void;
}

export type SupportingDocumentsForOfficeAddressModalFormData = {
  files: any[];
};

const DEFAULT_FORM_DATA: SupportingDocumentsForOfficeAddressModalFormData = {
  files: [],
};

const SupportingDocumentsForOfficeAddressModal: React.FC<
  SupportingDocumentsForOfficeAddressModalProps
> = ({ open, loading, error, onClose, onSubmitSupportingDocuments }) => {
  const intl = useIntl();

  const SupportingDocumentsForOfficeFormSchema = yup.object({
    files: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            intl.formatMessage({ id: "ERROR.MAX_SIZE_FOR_FILE" }),
            (value: any) => {
              // Check if the file size is less than or equal to 1e7 bytes (10 MB)
              return !value || (value && value.size <= 1e7);
            }
          )
      )
      .min(1, intl.formatMessage({ id: "FORM.REQUIRED" }))
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<SupportingDocumentsForOfficeAddressModalFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(SupportingDocumentsForOfficeFormSchema),
  });

  useEffect(() => {
    reset();
  }, [open]);

  const handleAddNewAddressRequisiteFormSubmit = handleSubmit((formData) => {
    onSubmitSupportingDocuments({ files: formData.files });
  });

  return (
    <div className={classNames("modal modal-enter", { show: open })}>
      <form
        className="modal-content"
        onSubmit={handleAddNewAddressRequisiteFormSubmit}
      >
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />

            <h2>
              <FormattedMessage id="ADD_SUPPORTING_DOCUMENTS" />
            </h2>
          </div>

          <button type="button" className="close-modal-btn" onClick={onClose}>
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <Controller
            name="files"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FileInput
                label={intl.formatMessage({ id: "ATTACH_DOCUMENTS" })}
                errors={errors}
                value={value}
                accept="image/*,application/pdf"
                onChange={onChange}
              />
            )}
          />

          {(errors.root && errors.root.message && (
            <div className="modal-content__error">{errors.root.message}</div>
          )) ||
            (errors.files && errors.files[0]?.message && (
              <div className="modal-content__error">
                {errors?.files[0]?.message.toString()}
              </div>
            ))}
        </div>

        <button
          type="submit"
          className="modal-content__btn second-btn"
          disabled={loading}
        >
          <FormattedMessage id="SEND" />
        </button>
      </form>
    </div>
  );
};

export default SupportingDocumentsForOfficeAddressModal;
