import { AuthorizedService } from "@awex-api"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import SupportingOfficeDocumentList from "../AdminOfficeAddressDetails/SupportingOfficeDocumentList"
import { useIntegerFractionNumber } from "../../../../app/hooks/useIntegerFractionNumber"
import { ROUTE } from "@constants/path-locations"


export type TDepositProcessingData = {
    id: number
    data: {
      name: string
      price: number
      currency: string
      rate: number
      feePayee: true
      merchantName: string
      comment: string
      convertTo: string
      feeAmount: number
      fee: number
    }
    deposit: {
      name: string
      amount: number
      currency: string
      rate: number
      depositDuration: number
      returnTime: number
      withholdAmount: number
      withholdFiles: string[]
      info?: string
      withholdRejectReason?: string
      withholdComment?: string
    }
    amount: number
    depositAmount: number
    depositReturnTime: number
    buyerIdentifier: string
    status: string
    createdAt: number
    projectName: string
    projectWeb: string
}


const AdminDepositProcessingDetail: FC = () => {
    const [data, setDepositData]  = useState<TDepositProcessingData | null>(null)
    const { id } = useParams()
    const navigate = useNavigate()
    const intl = useIntl()
    const [requestInfoText, setRequestInfoText] = useState<string>('')
    const [requestInfoTextError, setRequestInfoTextError] = useState<boolean>(false)
    const [inProcess, setInProcess] = useState<boolean>(false)
    const { integer, fraction, setData } = useIntegerFractionNumber(0)


    useEffect(() => {
        getDepositData(id)
    }, [id])


    function getDepositData(id: string | undefined): void {
        if(!id) return
        AuthorizedService.administratorDepositsGet('1', undefined, undefined, undefined, Number(id)) /** Добавить поиск по ID заказа */
        .then((resp) => {
            if(!resp) {
                toast.error(intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" }))
            }
            const depositData = resp.list.filter((item) => {
                return id === item.id.toString()
            })[0]
            setData(depositData.depositAmount)
            setDepositData(depositData)
        })
        .catch((error) => {
            toast.error("Не удалось загрузить содержимое. ошибка сервера: " + error.message)
        })
    }

    function back(ev: React.MouseEvent<HTMLAnchorElement>): void {
        ev.preventDefault()
        navigate(ROUTE.ADMIN_APPLICATIONS_DEPOSIT_PROCESSING)
    }

    function requestInfoBtnClickHandle(): void {
        if(!id || inProcess) return

        if(requestInfoText.length <= 5) {
            toast.error("Нужно заполнить коментарий для мерчанта")
            setRequestInfoTextError(true)
            return
        }
        setInProcess(true)
        AuthorizedService.withholdRequestInfo(id.toString(), { info: requestInfoText })
        .then((resp) => {
            console.log('withholdRequestInfo resp', resp)
            if(!resp) {
                toast.error("Непредвиденная ошибка")
                return
            }
            toast.success("Запрос отправлен")
            setRequestInfoText("")
        })
        .catch((error) => {
            toast.error("Ошибка сервера: " + error.message)
        })
        .finally(() => {
            setInProcess(false)
        })
    }

    function requestInfoTextChange(ev: ChangeEvent<HTMLTextAreaElement>): void {
        setRequestInfoText(ev.target.value)
        requestInfoTextError && setRequestInfoTextError(false)
    }

    function rejectButtonClickHandle(): void {
        if(!id || inProcess) return

        if(requestInfoText.length <= 5) {
            toast.error("Нужно заполнить коментарий для мерчанта")
            setRequestInfoTextError(true)
            return
        }
        setInProcess(true)
        AuthorizedService.rejectWithholdRequest(id.toString(), { withholdRejectReason: requestInfoText })
        .then((resp) => {
            console.log('rejectWithholdRequest resp', resp)
            if(!resp) {
                toast.error("Непредвиденная ошибка")
                return
            }
            toast.success("Заявка отклонена")
            setRequestInfoText("")
        })
        .catch((error) => {
            toast.error("Ошибка сервера: " + error.message)
        })
        .finally(() => {
            setInProcess(false)
        })
    }

    function approveButtonClickHandle(): void {
        if(!id || inProcess) return
        setInProcess(true)
        AuthorizedService.approveWithholdRequest(id)
        .then((resp) => {
            console.log('approveWithholdRequest resp', resp)
            if(!resp) {
                toast.error("Непредвиденная ошибка")
                return
            }
            toast.success("Подтверждено!")
            setTimeout(() => {
                navigate(-1)
            }, 1500)
        })
        .catch((error) => {
            toast.error("Не удалось подтвердить. Ошибка сервера: " + error.message)
        })
        .finally(() => {
            setInProcess(false)
        })
    }


    return (
        <div className="admin-statistic admin-applications-detal">
            <div className="admin-statistic__container">
                <a className="admin-applications__back" href="#"
                    onClick={back}
                >
                    <img src="/img/icons/angle-left-circle.svg" alt="angle-left-circle" />
                    <span>Вернуться к Заявкам</span>
                </a>

                { !!data && (
                    <div className="admin-applications__main">
                        <ul className="admin-applications__list admin-applications__list--six-column">
                            <li className="admin-applications__item admin-applications__item-header">
                                <div className="admin-applications__item-content">
                                    Номер
                                </div>

                                <div className="admin-applications__item-content">
                                    Номер/ID мерчанта
                                </div>

                                <div className="admin-applications__item-content">
                                    Дата заявки
                                </div>

                                <div className="admin-applications__item-content"></div>
                            </li>

                            <li className="admin-applications__item">
                                <div className="admin-applications__item-content">{ data?.id }</div>
                                <div className="admin-applications__item-content">{ data?.buyerIdentifier ? data?.buyerIdentifier : '...' }</div>
                                <div className="admin-applications__item-content">{ new Date(data.createdAt * 1000).toLocaleDateString() }</div>

                                <div className="admin-applications__item-content">
                                    Депозит
                                    <span>{ data?.deposit.amount }</span>
                                </div>

                                <div className="admin-applications__item-content">
                                    Сумма удержания
                                    <span>{ data?.deposit.withholdAmount }</span>
                                </div>

                                <div className="admin-applications__item-content"></div>
                            </li>
                        </ul>

                        <div className="admin-applications__request">
                            <p className="admin-applications__request-label">
                                Запрос на удержание депозита
                            </p>

                            <div className="admin-legal__infos">
                                <div className="admin-legal__info">
                                    <div className="admin-legal__info-header">
                                        <p className="admin-legal__info-descr">
                                            Депозит 
                                            #{ data?.id }:
                                        </p>

                                        <div className="admin-legal__info-deposite">
                                            { integer },<span>{ fraction }$</span>
                                        </div>
                                    </div>

                                    <div className="admin-legal__info-blocks">
                                        <div className="admin-legal__info-block">
                                            <div className="admin-legal__info-top">
                                                <p className="admin-legal__info-label">
                                                    Проект:
                                                </p>

                                                <p className="admin-legal__info-label">{ data.projectName }</p>
                                                <a href={data.projectWeb} className="admin-legal__info-descr">{ data.projectWeb }</a>
                                            </div>
                                        </div>

                                        <div className="admin-legal__info-block">
                                            <div className="admin-legal__info-top">
                                                <p className="admin-legal__info-label">
                                                    ID клиента:
                                                </p>

                                                <p className="admin-legal__info-descr">#{ data?.buyerIdentifier ? data?.buyerIdentifier : '...' }</p>
                                                {/* <p className="admin-legal__info-descr"> ... </p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="admin-legal__info-comments">
                                        <div className="admin-legal__info-top">
                                            <p className="admin-legal__info-label">
                                                Комментарий:
                                            </p>
                                            
                                            <p className="admin-legal__info-descr">
                                                { data?.deposit.withholdComment }
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="admin-legal__documents-wrapper">
                                    <div className="admin-legal__documents-header">
                                        <div className="admin-legal__info-top">
                                            <p className="admin-legal__info-label">
                                                Сумма депозита:
                                            </p>
                                            <p className="admin-legal__info-descr">{ data?.depositAmount } USD</p>
                                        </div>

                                        <div className="admin-legal__info-top">
                                            <p className="admin-legal__info-label">
                                                Сумма удержания
                                            </p>
                                            <p className="admin-legal__info-descr">{ data?.deposit.withholdAmount } USD</p>
                                        </div>
                                    </div>


                                    <div className="admin-legal__documents">
                                        <p className="admin-legal__documents-label">
                                            Подтверждаюшие документы
                                        </p>

                                        {/* { data?.deposit.withholdFiles.map((doc) => {
                                            return (
                                                <div className="admin-legal__document"
                                                    key={doc}
                                                >
                                                    <span>{ doc }</span>
                                                    <img src="/img/icons/file-grey.svg" alt="" />
                                                </div>
                                            )
                                        }) } */}
                                        
                                        <SupportingOfficeDocumentList
                                            documents={data?.deposit.withholdFiles || []}
                                        />

                                    </div>

                                    <div className="admin-legal__documents-footer">
                                        <div className={`admin-legal__input-group project-group ${requestInfoTextError ? "error" : ""}`}>
                                            <label className="admin-legal__input-label project-label" htmlFor="#">
                                                Комментарий для клиента:
                                            </label>

                                            <textarea className="admin-legal__input-textarea project-textarea" placeholder="Нарушение правил, порча имущества и еще что-то и что-то"
                                                value={requestInfoText}
                                                onChange={requestInfoTextChange}
                                            ></textarea>

                                            <ul>
                                                { data?.deposit?.info && (<li>
                                                    <strong>Запрошена доп. информация: </strong>
                                                    {data?.deposit?.info}
                                                </li>) }

                                                { data?.deposit?.withholdRejectReason && (<li>
                                                    <strong>Отклонен по причине: </strong>
                                                    {data?.deposit?.withholdRejectReason}
                                                </li>) }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="admin-applications__footer">
                                <button type="button" className="admin-applications__btn main-btn"
                                    disabled={inProcess}
                                    onClick={rejectButtonClickHandle}
                                >
                                    Отклонить
                                </button>

                                <button type="button" className="admin-applications__btn third-btn"
                                    disabled={inProcess}
                                    onClick={requestInfoBtnClickHandle}
                                >
                                    Запросить информацию
                                </button>

                                <button type="button" className="admin-applications__btn second-btn"
                                    disabled={inProcess}
                                    onClick={approveButtonClickHandle}
                                >
                                    Подтвердить
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AdminDepositProcessingDetail