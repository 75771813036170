import React, { useEffect, useState } from "react";
import classes from "./AdminApplicationAreaNavbar.module.css";
import { NavLink } from "react-router-dom";
import { AuthorizedService, ProjectListAdmin } from "@awex-api";
import { ROUTE } from "@constants/path-locations";
import { OfficeAddressAdminItem } from "src/generated/awex-api/models/OfficeAddressAdminItem";
import { TDepositProcessingData } from "../../../../../app/pages/AdminApplicationsPage/AdminDepositProcessingDetail";


const AdminApplicationAreaNavbar: React.FC = () => {
  const [projectApplications, setProjectApplications] = React.useState<ProjectListAdmin[]>([]);
  const [officeAddressApplications, setOfficeAddressApplications] = useState<OfficeAddressAdminItem[]>([]);
  const [depositProcessing, setDepositProcessing] = useState<TDepositProcessingData[]>([]);


  useEffect(() => {
    AuthorizedService.adminProjectsList().then((res) => {
      setProjectApplications(res.list!);
    });
    AuthorizedService.getAdminOfficeAddresses().then((res) => {
      setOfficeAddressApplications(res.list);
    });
    AuthorizedService.administratorDepositsGet().then((res) => {
      setDepositProcessing(res.list);
    });
  }, []);


  const amountOfNewProjectApplications = projectApplications.filter(
    (application) => application?.validation?.status === "new"
  ).length;

  const amountOfNewOfficeAddressApplications = officeAddressApplications.filter(
    (application) => application?.validation?.status === "new"
  ).length;

  const amountDepositProcessing = depositProcessing.filter(
    (application) => application?.status === "waitAdmin"
  ).length

  const AdminApplicationsPageNav = [
    {
      title: "Обработка депозитов",
      path: "/admin/applications/deposit-processing",
      amountOfNew: amountDepositProcessing,
      disable: false,
    },
    {
      title: "Увеличение количества проектов",
      path: "/admin/applications/projects",
      amountOfNew: amountOfNewProjectApplications,
      disable: false,
    },
    {
      title: "Изменение/добавление юр.счета",
      path: "/admin-applications-page/bank-account",
      disable: true,
    },
    {
      title: "Изменение/добавление юр. адреса",
      path: ROUTE.ADMIN_APPLICATIONS_OFFICE_ADDRESS_PATH,
      amountOfNew: amountOfNewOfficeAddressApplications,
      disable: false,
    },
    {
      title: "Заказ наличных в офис",
      path: ROUTE.ADMIN_APPLICATIONS_CASH_ORDER,
      disable: false,
    },
  ];


  const handleClick = (e: any, disable: boolean) => {
    if (disable) e.preventDefault();
  };


  return (
    <nav className={classes["container"]}>
      <ul className={classes["navigation-list"]}>
        {AdminApplicationsPageNav.map((item, index) => (
          <li key={index} className={classes["navigation-list__li"]}>
            <NavLink
              to={item.path}
              onClick={(e) =>
                item.disable ? handleClick(e, item.disable) : null
              }
              className={({ isActive }) =>
                isActive
                  ? classes["navigation-list__item--active"]
                  : classes["navigation-list__item"]
              }
            >
              {item.title}
              {item?.amountOfNew && item?.amountOfNew > 0 ? (
                <span className={classes["navigation-list__amount"]}>
                  {item?.amountOfNew}
                </span>
              ) : null}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AdminApplicationAreaNavbar;
