import React, { useEffect, useState } from "react";
import CardList from "./CardList";
import { AuthorizedService } from "@awex-api";
import { TCardList } from "src/generated/awex-api/models/CardList";
import { NewCardModalContainer } from "@containers/NewCardModalContainer";
import { FormattedMessage } from "react-intl";

interface IProps {
  deleteCardHandler: (id: number) => void;
}

const Cards: React.FC<IProps> = ({ deleteCardHandler }) => {
  const [cards, setCards] = useState<TCardList>();
  const [isNewCardModalOpen, setIsNewCardModalOpen] = useState<boolean>(false);

  const handleOpenNewCardModal = () => {
    setIsNewCardModalOpen(true);
  };

  const handleCloseNewCardModal = () => {
    setIsNewCardModalOpen(false);
  };

  useEffect(() => {
    AuthorizedService.getCardsList().then((res) => {
      if (res) {
        setCards(res);
      }
    });
  }, [isNewCardModalOpen, deleteCardHandler]);

  return (
    <div className="settings-requisites__list-wrapper">
      <div className="settings-requisites__header">
        <h3 className="settings-requisites__title">
          <FormattedMessage id="MY_CARDS" />
        </h3>

        <button
          className="settings-requisites__added"
          type="button"
          onClick={handleOpenNewCardModal}
        >
          <FormattedMessage id="ADD_CARD" />
        </button>
      </div>

      {cards?.list?.length! > 0 ? (
        <CardList cards={cards!} deleteCardHandler={deleteCardHandler} />
      ) : (
        <p className="settings-requisites__empty-list-text">
          <FormattedMessage id="YOU_HAVE_NO_CARDS" />
        </p>
      )}

      <NewCardModalContainer
        open={isNewCardModalOpen}
        onClose={handleCloseNewCardModal}
      />
    </div>
  );
};

export default Cards;
