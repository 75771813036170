import { AuthenticatedService, ProfileData } from "@awex-api";
import React, { createContext, useEffect, useState } from "react";

const LanguageContext = createContext<{
  language: string;
  switchLanguage: (newLocale: string) => void;
} | null>(null);

const getProfile = async () => {
  const profile = await AuthenticatedService.profileGet();
  return profile;
};

interface IProps {
  children: React.ReactNode;
}

const LanguageContextProvider: React.FC<IProps> = ({ children }) => {
  const isAuth = JSON.parse(localStorage.getItem("user")!);
  const lang = localStorage.getItem("LANGUAGE") || "ru";
  const [language, setLanguage] = useState<string>(lang || "ru");

  const switchLanguage = (newLocale: string) => {
    if (
      newLocale === "ru" ||
      newLocale === "en" ||
      newLocale === "ch" ||
      newLocale === "vn"
    ) {
      localStorage.setItem("LANGUAGE", newLocale);
      setLanguage(newLocale);
    }
  };

  useEffect(() => {
    if (isAuth) {
      (async () => {
        const profile = await getProfile();
        if (profile?.language) {
          localStorage.setItem("LANGUAGE", profile.language);
          setLanguage(profile?.language!);
        }
      })();
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
