import { ROUTE } from "@constants/path-locations";
import { AuthModalContainer } from "@containers/AuthModalContainer";
import { BlockProfileModalContainer } from "@containers/BlockProfileModalContainer";
import { DescribeProblemModalContainer } from "@containers/DescribeProblemModalContainer";
import { RecoverModalContainer } from "@containers/RecoverModalContainer";
import { RegisterModalContainer } from "@containers/RegisterModalContainer";
import { useAppSelector } from "@store/hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";

const LandingBusinessPage: React.FC = () => {
  const [authModalOpened, setAuthModalOpened] = useState(false);
  const [registerModalOpened, setRegisterModalOpened] = useState(false);
  const [recoverModalOpened, setRecoverModalOpened] = useState(false);
  const [describeProblemModalOpened, setDescribeProblemModalOpened] =
    useState(false);
  const [blockProfileModalOpened, setBlockProfileModalOpened] = useState(false);

  const authorized = useAppSelector((state) => state.auth.user !== undefined);

  const navigate = useNavigate();
  const intl = useIntl();

  const handleStartButton = () => {
    if (authorized) {
      navigate(ROUTE.DASHBOARD_PATH);
    } else {
      handleSignInBtnClick();
    }
  };

  const handleSignInBtnClick = () => {
    setAuthModalOpened(!authModalOpened);
  };

  const handleRecoverBtnClick = () => {
    setRecoverModalOpened(!recoverModalOpened);
  };

  const handleCloseAuthModal = () => {
    setAuthModalOpened(false);
  };

  const handleCloseRegisterModal = () => {
    setRegisterModalOpened(false);
  };

  const handleCloseDescribeProblemModal = () => {
    setDescribeProblemModalOpened(false);
  };

  const handleNavRegister = () => {
    setAuthModalOpened(false);
    setRegisterModalOpened(true);
  };

  const handleNavRecover = () => {
    setAuthModalOpened(false);
    setRecoverModalOpened(true);
  };

  const handleNavDescribeProblem = () => {
    setAuthModalOpened(false);
    setDescribeProblemModalOpened(true);
  };

  const onNavBlockProfile = () => {
    setAuthModalOpened(false);
    setBlockProfileModalOpened(true);
  };

  const handleCloseBlockProfileModal = () => {
    setBlockProfileModalOpened(false);
  };

  return (
    <main className="main main--profile-filling">
      <section className="landing-page landing-page-second">
        <div className="landing-page__container">
          <div className="landing-page__inner">
            <div className="landing-page__hero">
              <div className="landing-page__hero-banner">
                <img
                  src="/img/landing-page__hero-3.png"
                  alt={intl.formatMessage({
                    id: "IMAGE.AWEX_FIRST_SCREENSHOT.ALT",
                  })}
                />
              </div>

              <div className="landing-page__hero-main">
                <h1 className="landing-page__hero-title">
                  <FormattedMessage id="LANDING.BUSINESS.TITLE" />
                </h1>

                <div className="landing-page__telegram">
                  <p className="landing-page__telegram-label">
                    <FormattedMessage id="LANDING.BUSINESS.SUBTITLE" />
                  </p>

                  <button
                    className="landing-page__btn second-btn"
                    onClick={handleStartButton}
                  >
                    <FormattedMessage id="LANDING.BUSINESS.BTN.CONNECT" />
                  </button>
                </div>

                <div className="landing-page__hero-footer">
                  <h2 className="landing-page__hero-subtitle">
                    <FormattedMessage id="LANDING.CREATE_INVOICE" />
                  </h2>

                  <p className="landing-page__hero-text">
                    <FormattedMessage id="LANDING.MAXIMAL_COMFORT" />
                  </p>
                </div>
              </div>

              <div className="landing-page__hero-banner landing-page__hero-banner--second">
                <img
                  src="/img/landing-page__hero-4.png"
                  alt={intl.formatMessage({
                    id: "IMAGE.AWEX_SECOND_SCREENSHOT.ALT",
                  })}
                />
              </div>
            </div>

            <div className="landing-page__partners">
              <h3 className="landing-page__partners-label">
                <FormattedMessage id="LANDING.WE_ARE_COOPERATE" />
              </h3>

              <ul className="landing-page__partners-list">
                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-1.svg"
                    alt={intl.formatMessage({ id: "LOGO.METAMASK.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-2.png"
                    alt={intl.formatMessage({ id: "LOGO.TRUSTWALLET.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-3.svg"
                    alt={intl.formatMessage({ id: "LOGO.BINANCE.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-4.png"
                    alt={intl.formatMessage({ id: "LOGO.PANCAKE.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-5.png"
                    alt={intl.formatMessage({ id: "LOGO.TELEGRAM.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-6.svg"
                    alt={intl.formatMessage({ id: "LOGO.BITPAY.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-7.svg"
                    alt={intl.formatMessage({ id: "LOGO.ATOMIC_WALLET.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-8.png"
                    alt={intl.formatMessage({ id: "LOGO.RAINBOW.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-9.svg"
                    alt={intl.formatMessage({ id: "LOGO.IM_TOKEN.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-10.svg"
                    alt={intl.formatMessage({ id: "LOGO.ARGENT.ALT" })}
                  />
                </li>

                <li className="landing-page__partners-item landing-page__partners-item--last">
                  <img
                    className="landing-page__partners-pic"
                    src="/img/partners/landing-page__partners-11.png"
                    alt={intl.formatMessage({ id: "LOGO.COINBASE.ALT" })}
                  />
                </li>
              </ul>
            </div>

            <div className="landing-page__benifits">
              <div className="landing-page__benifits-block">
                <h4 className="landing-page__benifits-title">
                  <FormattedMessage id="LANDING.CAN_USE_WITHOUT_IP" />
                </h4>

                <p>
                  <FormattedMessage id="LANDING.AVAILABLE_FOR_ALL" />
                </p>
              </div>

              <div className="landing-page__benifits-block">
                <h4 className="landing-page__benifits-title">
                  <FormattedMessage id="LANDING.INTERNATIONAL_PAYMENTS" />
                </h4>

                <p>
                  <FormattedMessage id="LANDING.MINIMAL_COMMISSION" />
                </p>
              </div>

              <div className="landing-page__benifits-block">
                <h4 className="landing-page__benifits-title">
                  <FormattedMessage id="SUPPORT" />
                </h4>

                <p>
                  <FormattedMessage id="LANDING.24/7" />
                </p>
              </div>
            </div>

            <div className="landing-page__counts">
              <div className="landing-page__counts-group">
                <FormattedMessage
                  id="LANDING.COUNTRIES"
                  values={{
                    p: (chunks: any) => <p>{chunks}</p>,
                    span: (chunks: any) => <span>{chunks}</span>,
                  }}
                />
              </div>

              <div className="landing-page__counts-border"></div>

              <div className="landing-page__counts-group">
                <FormattedMessage
                  id="LANDING.CRYPTO_AVAILABLE"
                  values={{
                    p: (chunks: any) => <p>{chunks}</p>,
                    span: (chunks: any) => <span>{chunks}</span>,
                  }}
                />
              </div>

              <div className="landing-page__counts-border"></div>

              <div className="landing-page__counts-group">
                <FormattedMessage
                  id="LANDING.MERCHANTS_AMOUNT"
                  values={{
                    p: (chunks: any) => <p>{chunks}</p>,
                    span: (chunks: any) => <span>{chunks}</span>,
                  }}
                />
              </div>
            </div>

            <div className="landing-page__started">
              <h3 className="landing-page__started-title">
                <FormattedMessage id="LANDING.GET_STARTED" />
              </h3>

              <button
                className="landing-page__started-link main-btn"
                onClick={handleStartButton}
              >
                <FormattedMessage id="LANDING.BTN.TRY_NOW" />
              </button>
            </div>

            <div className="landing-page__working">
              <div className="landing-page__working-info">
                <h3 className="landing-page__working-title">
                  <FormattedMessage id="LANDING.BUSINESS.HOW_TO_START" />
                </h3>

                <p className="landing-page__working-descr">
                  <FormattedMessage id="LANDING.BUSINESS.INTEGRATE" />
                </p>

                <a className="landing-page__working-link second-btn" href="#">
                  <FormattedMessage id="BTN.MORE_DETAILS" />
                </a>
              </div>
              <div className="landing-page__working-picture"></div>
            </div>

            <div className="landing-page__started">
              <h3 className="landing-page__started-title">
                <FormattedMessage id="LANDING.BUSINESS.FIRST_SERVICE" />
              </h3>

              <p className="landing-page__hero-text">
                <FormattedMessage id="LANDING.BUSINESS.SERVICE" />
              </p>
            </div>

            <div className="landing-page__blocks">
              <div className="landing-page__block">
                <p>
                  <FormattedMessage id="LANDING.BUSINESS.CONVENIENT_DEPOSIT_MANAGEMENT" />
                </p>
              </div>

              <div className="landing-page__block landing-page__block--yellow">
                <p>
                  <FormattedMessage id="LANDING.BUSINESS.RETURN_DEPOSIT" />
                </p>
              </div>

              <div className="landing-page__block">
                <p>
                  <FormattedMessage id="LANDING.BUSINESS.PAYOUT_AND_RETENTION" />
                </p>
              </div>
            </div>

            <div className="landing-page__grade">
              <div className="landing-page__grade-picture">
                <img src="/img/landing-page__grade-picture.png" alt="" />
              </div>

              <div className="landing-page__grade-info">
                <h3 className="landing-page__grade-title">
                  <FormattedMessage id="LANDING.BUSINESS.TEXT_MODE" />
                </h3>

                <p className="landing-page__grade-descr">
                  <FormattedMessage id="LANDING.BUSINESS.INSTRUCTION_AFTER_REGISTRATION" />
                </p>

                <button
                  className="landing-page__grade-link second-btn"
                  onClick={handleStartButton}
                >
                  <FormattedMessage id="LANDING.BTN.TRY_NOW" />
                </button>
              </div>
            </div>

            <div className="landing-page__started">
              <h3 className="landing-page__started-title">
                <FormattedMessage id="LANDING.BUSINESS.FOR_WHO" />
              </h3>

              <p className="landing-page__hero-text">
                <FormattedMessage id="LANDING.BUSINESS.A_LOT_OF_SETTINGS" />
              </p>
            </div>

            <div className="landing-page__services">
              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.CAR_RENTAL" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.ABILITY_TO_MANAGE_DEPOSITS" />
                </p>
              </div>

              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.ONLINE_SERVICES" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.ABILITY_TO_MANAGE_DEPOSITS" />
                </p>
              </div>

              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.GAME_SERVICES" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.AVAILABLE_PAYMENT_WITH_CRYPTO" />
                </p>
              </div>

              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.ONLINE_SHOPS" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.INCREASE_CONVERSATION" />
                </p>
              </div>

              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.TELEGRAM_BOTS" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.AVAILABLE_PAYMENT_WITH_CRYPTO" />
                </p>
              </div>

              <div className="landing-page__services-item">
                <h4 className="landing-page__services-label">
                  <FormattedMessage id="LANDING.BUSINESS.HIGH_RISK_PROJECTS" />
                </h4>

                <p className="landing-page__services-descr">
                  <FormattedMessage id="LANDING.BUSINESS.WE_ARE_HELP_YOU" />
                </p>
              </div>
            </div>

            <div className="landing-page__integration">
              <div className="landing-page__integration-info">
                <h3 className="landing-page__integration-title">
                  <FormattedMessage id="LANDING.BUSINESS.CRM_INTEGRATION" />
                </h3>

                <button
                  className="landing-page__integration-link second-btn"
                  onClick={handleStartButton}
                >
                  <FormattedMessage id="LANDING.BUSINESS.BTN.CONNECT" />
                </button>
              </div>
              <div className="landing-page__integration-picture">
                <img src="/img/landing-page__integration-pic.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="chat__container">
          <div className="chat">
            <div className="chat__btn">
              <img
                src="/img/icons/chat-icon.svg"
                alt={intl.formatMessage({ id: "ICON.CHAT.ALT" })}
              />
            </div>
          </div>
        </div>
      </section>

      <AuthModalContainer
        open={authModalOpened}
        onClose={handleCloseAuthModal}
        onNavRegister={handleNavRegister}
        onNavRecover={handleNavRecover}
        onNavDescribeProblem={handleNavDescribeProblem}
        onNavBlockProfile={onNavBlockProfile}
      />

      <RegisterModalContainer
        open={registerModalOpened}
        onClose={handleCloseRegisterModal}
      />

      <RecoverModalContainer
        open={recoverModalOpened}
        onClose={handleRecoverBtnClick}
      />

      <DescribeProblemModalContainer
        open={describeProblemModalOpened}
        onClose={handleCloseDescribeProblemModal}
      />

      <BlockProfileModalContainer
        open={blockProfileModalOpened}
        onClose={handleCloseBlockProfileModal}
      />
    </main>
  );
};

export default LandingBusinessPage;
