import { DepositsFiltersSelect } from "@components/DepositsFilterSelect";
import {
  DepositsFilterDate,
  DateRange,
} from "../../../components/DepositsFilterDate";
import { StatsFiltersType } from "..";
import { useEffect, useState } from "react";
import { AuthorizedService } from "@awex-api";
import { useIntl } from "react-intl";

interface StatsFiltersProps {
  setFilters: (newFilter: StatsFiltersType) => void;
}

interface SelectFilterType {
  value: string;
  options: { value: string; label: string }[];
}

const defaultDateFilterValue: DateRange = {
  from: undefined,
  to: undefined,
};

export default function StatsFilters(props: StatsFiltersProps) {
  const { setFilters } = props;
  const intl = useIntl();

  const projectsFilterDefault: SelectFilterType = {
    value: "",
    options: [{ value: "", label: intl.formatMessage({ id: "ALL" }) }],
  };

  const [projectFilter, setProjectFilter] = useState<SelectFilterType>(
    projectsFilterDefault
  );
  const [dateFilter, setDateFilter] = useState<DateRange | undefined>(
    defaultDateFilterValue
  );

  useEffect(() => {
    getProjects();
  }, []);

  function getProjects() {
    AuthorizedService.projectNames()
      .then((response) => {
        if (!response || !response.list) return;
        const newProjectOptions = response.list.map((project) => {
          return {
            value: project.id.toString(),
            label: project.name,
          };
        });
        const newProjectFilter: SelectFilterType = {
          value: projectFilter.value,
          options: [
            { value: "", label: intl.formatMessage({ id: "ALL" }) },
            ...newProjectOptions,
          ],
        };
        setProjectFilter(newProjectFilter);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleProjectFilterChange(value: string) {
    const newProjectFilter = {
      ...projectFilter,
      value,
    };
    setProjectFilter(newProjectFilter);
    setFilters({
      projectId: value !== "" ? value : undefined,
    });
  }

  function handleDateFilterChange(value?: DateRange) {
    if (!value) {
      setDateFilter(defaultDateFilterValue);
      return;
    }
    setDateFilter(value);
  }

  function dateFilterChangeFine() {
    setFilters({
      startTime: dateFilter?.from
        ? Date.parse(dateFilter.from.toString()) / 1000
        : undefined,
      endTime: dateFilter?.to
        ? Date.parse(dateFilter.to.toString()) / 1000
        : undefined,
    });
  }

  return (
    <div className="stats__filters">
      <DepositsFiltersSelect
        label={intl.formatMessage({ id: "PROJECT" })}
        value={projectFilter.value}
        options={projectFilter.options}
        onChange={handleProjectFilterChange}
      />

      <DepositsFilterDate
        label={intl.formatMessage({ id: "DATE" })}
        value={dateFilter}
        onChange={handleDateFilterChange}
        onClose={dateFilterChangeFine}
      />
    </div>
  );
}
