import { useEffect, useState } from "react";
import { ReferralsFilters } from "./ReferralsFilters";
import { AuthorizedService } from "@awex-api";
import toast from "react-hot-toast";
import daysjs from "dayjs";
import { useInView } from "react-intersection-observer";
import { isNull } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

type Referral = {
  referralId?: number;
  createdAt?: number;
  status?: "active" | "inActive";
  amount?: number;
};

export type ReferralFilters = {
  status?: string;
  startTime?: number;
  endTime?: number;
};

const referralStatuses = new Map([
  ["active", "Активный"],
  ["inActive", "Не активный"],
]);

export function ReferralsTable() {
  const [referralsList, setReferralsList] = useState<Referral[]>([]);
  const [referralsIsLoading, setReferralsIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [referralFilters, setReferralFilters] = useState<ReferralFilters>({});

  const intl = useIntl();

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    getReferrals();
  }, [page, referralFilters]);

  useEffect(() => {
    scrollLaod();
  }, [inView]);

  function getReferrals(): void {
    if (referralsIsLoading) return;
    setReferralsIsLoading(true);
    const { status, startTime, endTime } = referralFilters;
    AuthorizedService.referralsList(page.toString(), status, startTime, endTime)
      .then((response) => {
        if (!response || !response.list || response.list.length === 0) {
          setReferralsList([]);
          setPage(1);
          setPages(1);
          return;
        }
        const newreferralsList =
          response.page === 1
            ? [...response.list]
            : [...referralsList, ...response.list];
        setReferralsList(newreferralsList);
        setPages(Number(response.pages));
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }));
        console.error(error);
      })
      .finally(() => {
        setReferralsIsLoading(false);
      });
  }

  function scrollLaod(): void {
    if (!inView) return;
    if (page < pages) {
      setPage(page + 1);
    }
  }

  function changeReferralFilters(newFilter: ReferralFilters): void {
    if (!isNull(newFilter)) {
      const newReferralFilters = {
        ...referralFilters,
        ...newFilter,
      };
      setReferralFilters(newReferralFilters);
      setPage(1);
    }
  }

  return (
    <div className="history-operations">
      <div className="history-operations__label">
        <h3 className="history-operations__title main-title">
          <FormattedMessage id="REFERRALS" />
        </h3>
      </div>

      <ReferralsFilters setFilter={changeReferralFilters} />

      <ul className="history-operations__list">
        <li className="history-operations__item history-operations__item-header">
          <div className="history-operations__item-data">
            <FormattedMessage id="REFERRAL_ID" />
          </div>
          <div className="history-operations__item-time">
            <FormattedMessage id="STATUS" />
          </div>
          <div className="history-operations__item-user">
            <FormattedMessage id="RECIEVED" />
          </div>
          <div className="history-operations__item-type">
            <FormattedMessage id="DATE" />
          </div>
        </li>
      </ul>

      <div className="history-operations__container history-operations__container_full">
        <ul className="history-operations__list">
          {referralsList &&
            referralsList.map((referral) => {
              const data = referral.createdAt ? referral.createdAt * 1000 : 0;
              const status = referral.status
                ? referralStatuses.get(referral.status)
                : "";

              return (
                <li
                  className="history-operations__item"
                  key={referral.referralId}
                >
                  <div className="history-operations__item-data">
                    {referral.referralId}
                  </div>
                  <div className="history-operations__item-time">{status}</div>
                  <div className="history-operations__item-user">
                    {referral.amount}$
                  </div>
                  <div className="history-operations__item-type">
                    {daysjs(data).format("DD.MM.YYYY")}
                  </div>
                </li>
              );
            })}

          <li className="history-operations__item" ref={ref}></li>
        </ul>
      </div>
    </div>
  );
}
