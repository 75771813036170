import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { RetentionItem } from ".."
import { useNavigate } from "react-router-dom"
import { ROUTE } from "@constants/path-locations"
import { useFormatCurrency } from "../../../hooks/useFormatCurrency"
import { AuthorizedService } from "@awex-api"
import toast from "react-hot-toast"


interface DepositRetentionItemProps {
  item: RetentionItem
  updateData: () => void
  isActive: boolean
}


export function DepositRetentionItem({item, updateData, isActive}: DepositRetentionItemProps) {
  console.log("RetentionItem", item)
  const { id, data, deposit, buyerIdentifier, amount, depositAmount } = item
  const [sumUSDInteger, sumUSDFractional] =  useFormatCurrency(Number(depositAmount)) //useFormatCurrency(Number(depositAmount)) //(deposit?.amount))
  const navigate = useNavigate()
  const intl = useIntl()
  const [actvie, setActive] = useState<boolean>(false)


  useEffect(() => {
    if(isActive) {
      setTimeout(() => {
        setActive(true)
      }, 200)
      return
    }
    setActive(false)
  }, [isActive])


  function withholdButtonHandler() {
    navigate(ROUTE.DEPOSIT_WITHHOLD_PATH, {
      replace: false,
      state: { retentionItem: item }
    })
  }

  function returnFullButtonHandler() {
    if(!id) return
    AuthorizedService.returnDeposit(id.toString())
    .then((resp) => {
      if(!resp) {
        toast.error(intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED"}))
      }
      toast.success(intl.formatMessage({ id: "SUCCESS.DEPOSIT_RETURNED"}))
    })
    .catch((error) => {
      console.error(error)
      toast.error(intl.formatMessage({ id: "ERROR.RETURN_DEPOSIT_FAILED"}))
    })
    .finally(() => {
      updateData()
    })
  }


  return (
    <li className="deposit-retention__item">
      <div className={`deposit-retention__inner ${ actvie ? 'deposit-retention__inner-active' : '' }`}>
        <div className="deposit-retention__info deposit-retention__info--1200">
          <div className="deposit-retention__info-groups">
            <div className="deposit-retention__deposit">
              <div className="deposit-retention__deposit-label">
                <FormattedMessage id="CURRENT_DEPOSIT" /> #{ id }
              </div>

              <div className="deposit-retention__deposit-sum">
                { sumUSDInteger }
                <span>,{ sumUSDFractional }USDT</span>
              </div>
            </div>

            <div className="deposit-retention__info-group">
              <div className="deposit-retention__project">
                <div className="deposit-retention__project-label">
                  <FormattedMessage id="PROJECT" />:
                </div>

                <div className="deposit-retention__project-name">
                  { item.projectName }
                </div>

                <a className="deposit-retention__project-link"
                  target="_blank"
                  rel="noopener"
                  href={item.projectWeb}
                >
                  { item.projectWeb }
                </a>
              </div>


              { !!buyerIdentifier && (
                <div className="deposit-retention__id">
                  <div className="deposit-retention__label">
                    <FormattedMessage id="ID_CUSTOMER" />
                  </div>

                  <p className="deposit-retention__descr">#{ buyerIdentifier }</p>
                

                  {/* <p className="deposit-retention__descr">({ props.clientName }) ...</p> */}
                </div>
              )}
            </div>
          </div>

          <div className="deposit-retention__about">
            <div className="deposit-retention__comments">
              <div className="deposit-retention__label">
                <FormattedMessage id="TABLE.COMMENT" />:
              </div>

              <p className="deposit-retention__descr">{ data?.name }</p>
            </div>

            <form className="deposit-retention__form" action="#">
              <div className="deposit-retention__item-btns deposit-retention__item-btns--first">
                <button className="deposit-retention__btn third-btn"
                  type="button"
                  onClick={withholdButtonHandler}
                >
                  <FormattedMessage id="WITHHOLD_PART_OF_THE_DEPOSIT" />
                </button>
              </div>

              <div className="deposit-retention__item-btns deposit-retention__item-btns--first">
                <button className="deposit-retention__btn second-btn"
                  type="button"
                  onClick={returnFullButtonHandler}
                >
                  <FormattedMessage id="FULL_REFUND" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </li>
  )
}