import { useEffect, useId, useMemo, useState } from "react";
import * as yup from "yup";
import { Helmet } from "react-helmet-async";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { AppProject } from "../../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useWatch } from "react-hook-form";
import { InvoiceProjectSelector } from "./InvoiceProjectSelector";
import { InvoiceCurrencySelector } from "./InvoiceCurrencySelector";
import { getProjects } from "@store/projects/slice";
import { AuthorizedService } from "@awex-api";
import toast from "react-hot-toast";
import usePortal from "react-useportal";
import { PaymentLinkModal } from "@components/PaymentLinkModal";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useCurrencies } from "../../hooks/useCurrencies";
import { ROUTE } from "@constants/path-locations";
import Tabs from "@components/Tabs";
import convertingСurrencies from "@constants/convertingСurrencies";
import Checkbox from "@components/Checkbox";
import { FormattedMessage, useIntl } from "react-intl";
import { daysToMiliseconds, milisecondsToDays } from "../../../utils/depositReturnTime";
// import { daysToTimeStamp, timeStampToDays } from "../../../utils/depositReturnTime";


type ConvertTo = "fiat" | "stablecoin"

interface InvoiceFormData {
  projectId?: string;
  name: string;
  amount: string;
  currency: string;
  feePayee?: number;
  useConvertTo?: boolean;
  useDeposit?: boolean;
  depositCurrency?: string;
  depositAmount?: string;
  depositReturnAt?: number;
  isTemplate?: boolean;
}

interface OrderTemplateData {
  userId: number
  name: string
  price: number
  currency: string
  projectId: number
  depositAmount: number
  // depositReturnTime: number
  depositDuration: number
  convertTo?: ConvertTo
}

interface OrderTemplate {
  id: number;
  data: OrderTemplateData;
  created_at: number;
}

type InvoiceStates = "new" | "edit" | "template";


const DEFAULT_PROJECTS: { id: string; project: AppProject }[] = [];
const DEFAULT_CURRENCIES: { currency: string; name?: string; rate?: string }[] = [];
const CONVERT_CURRENCIES = convertingСurrencies;
const SECONDS_IN_DAY = 86400


export function InvoicePage() {
  const dispatch = useAppDispatch();
  const nameId = useId();
  const amountId = useId();
  const useConvertToId = useId();
  const useDepositId = useId();
  const depositAmountId = useId();
  const depositReturnAtId = useId();
  const isTemplateId = useId();
  const projects = useAppSelector((state) => state.projects.data || DEFAULT_PROJECTS);
  const projectsError = useAppSelector((state) => state.projects.error);
  const [depositCurrencies, depositCurrenciesLoading] = useCurrencies(DEFAULT_CURRENCIES);
  const [invoiceCurrencies, invoiceCurrenciesLoading] = useCurrencies(DEFAULT_CURRENCIES);
  const [paymentLinkModalOpened, setPaymentLinkModalOpened] = useState(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const [paymentDescription, setPaymentDescription] = useState<string | undefined>(undefined);
  const [formProcess, setFormProcess] = useState<InvoiceStates>("new"); // The form is universal, therefore it has several states:
  /*
   * 'new'       : New payment without template. Normal state. Has a checkbox with saving the payment template
   * 'template'  : Create a payment using a template. Has a chexbox for saving changes to the template
   * 'edit'      : Editing a template. Does not allow creating a new payment
   * */
  const [editableOrderId, setEditableOrderId] = useState<number>(0);
  const { Portal } = usePortal();
  const location = useLocation();
  const intl = useIntl();

  const invoiceFormValidator = yup.object({
    projectId: yup.string().when("useDeposit", {
      is: true,
      then: (schema) =>
        schema.required(intl.formatMessage({ id: "FORM.REQUIRED" })),
    }),
    name: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" }))
      .max(256),
    amount: yup
      .string()
      .transform((value) => {
        value = value.replace(/,/gi, ".");
        value = value.replace(/-/gi, "");
        const floatvalue = parseFloat(value);
        return typeof floatvalue === "number" && isNaN(floatvalue)
          ? undefined
          : value;
      })
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
    currency: yup
      .string()
      .required(intl.formatMessage({ id: "ERROR.CURRENCY_NOT_SELECTED" })),
    feePayee: yup.number(),
    useConvertTo: yup.bool(),
    useDeposit: yup.bool(),
    depositCurrency: yup.string(),
    depositAmount: yup
      .string()
      .transform((value) => {
        value = value.replace(/,/gi, ".");
        value = value.replace(/-/gi, "");
        const floatvalue = parseFloat(value);
        return typeof floatvalue === "number" && isNaN(floatvalue)
          ? undefined
          : value;
      })
      .when("useDeposit", {
        is: true,
        then: (schema) =>
          schema.required(intl.formatMessage({ id: "FORM.REQUIRED" })),
      }),
    depositReturnAt: yup
      .number()
      .transform((value) =>
        typeof value === "number" && isNaN(value) ? undefined : value
      )
      .when("useDeposit", {
        is: true,
        then: (schema) =>
          schema
            .moreThan(0)
            .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
      }),
    isTemplate: yup.bool(),
  });

  const {
    register,
    setValue,
    setFocus,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InvoiceFormData>({
    resolver: yupResolver(invoiceFormValidator),
  });

  const useConvertToValue = useWatch({ control, name: "useConvertTo" });
  const useDepositValue = useWatch({ control, name: "useDeposit" });
  const projectId = useWatch({ control, name: "projectId" });
  const projectOptions = useMemo(() => {
    const activeProjects = projects.filter((project) => {
      return (
        project.project.validation &&
        project.project.validation.status !== "waiting"
      );
    });
    return activeProjects.map(({ id, project }) => ({
      value: id,
      label: project.name,
      key: id,
    }));
  }, [projects]);


  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  useEffect(() => {
    checkLocationProjectId();
    checkLocationOrderTemplate();
  }, [location, projects, setValue]);

  useEffect(() => {
    getFeePayee()
  }, [projectId])

  useEffect(() => {
    if(useConvertToValue) {
      setValue("depositCurrency", "usdt")
      return
    }
    setValue("depositCurrency", "")
  }, [useConvertToValue])


  function getFeePayee() {
    if (!projectId) return;
    const project = projects.find((item) => {
      return item.id === projectId;
    });
    const value = project?.project.feePayee ? 1 : 0;
    setValue("feePayee", value);
  }

  function checkLocationProjectId() {
    if (
      !location ||
      !("state" in location) ||
      !location.state ||
      !("projectId" in location.state)
    )
      return;
    setValue("projectId", location.state.projectId);
  }

  function checkLocationOrderTemplate() {
    if (
      !location ||
      !("state" in location) ||
      !location.state ||
      !("templateData" in location.state)
    )
      return

    const process: InvoiceStates = location.state.templateData.process
    setFormProcess(process)
    const orderTemplate: OrderTemplate = location.state.templateData.template

    if (!("data" in orderTemplate)) return
    setEditableOrderId(orderTemplate.id)
    const data: OrderTemplateData = orderTemplate.data
    "projectId" in data && setValue("projectId", data.projectId.toString())
    "name" in data && setValue("name", data.name)

    if ("depositAmount" in data && data.depositAmount) {
      const amount = data.depositAmount
        ? data.depositAmount.toString()
        : undefined;
      setValue("depositAmount", amount);
      setValue("useDeposit", true);
    }
    "depositDuration" in data && setValue("depositReturnAt", data.depositDuration)
    "depositReturnTime" in data && setFocus("depositReturnAt")
    "price" in data && setValue("amount", data.price.toString())
    "currency" in data && setValue("currency", data.currency)
    "convertTo" in data && setValue("useConvertTo", !!data.convertTo)
    "feePayee" in data && setValue("feePayee", data.feePayee ? 1 : 0)
  }

  const handleInvoiceFormSubmit = handleSubmit((formData) => {
    let projectId: number | undefined = undefined;
    if (formData.projectId) {
      projectId = parseInt(formData.projectId, 10);
      if (isNaN(projectId)) return;
    }
    const name = formData.name;
    const price = parseFloat(formData.amount);
    const currency = formData.currency;
    const isTemplate = formData.isTemplate;
    let buyerIdentifier: string | undefined = undefined;
    let depositAmount: number | undefined = undefined;
    if (formData.useDeposit) {
      depositAmount = formData.depositAmount
        ? parseFloat(formData.depositAmount)
        : 0;
    }
    const depositDuration = formData.depositReturnAt === undefined ? undefined : daysToMiliseconds(formData.depositReturnAt) // = formData.depositReturnAt === undefined ? undefined : daysToTimeStamp(formData.depositReturnAt)
    const feePayee = formData.feePayee === 1
    const convertTo: ConvertTo | undefined = formData.useConvertTo ? 'stablecoin' : undefined   // ***** crutch

    if (formProcess === "new" || formProcess === "template") {
      AuthorizedService.orderCreate({
        name,
        price,
        currency,
        feePayee,
        projectId,
        buyerIdentifier,
        depositAmount,
        depositDuration,
        isTemplate,
        convertTo,
      })
        .then((response) => {
          if (response.uniqueId) {
            setPaymentLinkModalOpened(true);
            setPaymentToken(response.uniqueId);
            setPaymentDescription(formData.name);
          } else {
            toast.error(intl.formatMessage({ id: "ERROR.PAYMENT_LINK_ERROR" }));
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(intl.formatMessage({ id: "ERROR.PAYMENT_LINK_ERROR" }));
        });
    }

    if (formProcess === "edit" && editableOrderId > 0) {
      AuthorizedService.setOrderTemplate(editableOrderId.toString(), {
        name,
        price,
        currency,
        feePayee,
        projectId: Number(projectId),
        buyerIdentifier,
        // comment: '',
        depositAmount: depositAmount ? depositAmount : 0,   //Number(depositAmount),
        depositDuration,
        //depositReturnTime: depositReturnTime ? depositReturnTime : 0,   //Number(depositReturnTime),
        convertTo: formData.useConvertTo ? 'stablecoin' : undefined   // ***** crutch
      })
        .then((response) => {
          if (response) {
            toast.success(intl.formatMessage({ id: "SUCCESS.CHANGES_SAVED" }));
            return;
          }
          toast.error(intl.formatMessage({ id: "ERROR.CHANGES_NOT_SAVED" }));
        })
        .catch((error) => {
          console.error(error);
          toast.error(intl.formatMessage({ id: "ERROR.CHANGES_NOT_SAVED" }));
        });
    }
  });

  const handlePaymentLinkModalClose = () => {
    setPaymentLinkModalOpened(false);
  };

  function daysToTimeStamp(days: number): number {
    const timeNowSeconds = Math.trunc(Date.now() / 1000)
    const daysInSeconds = days * SECONDS_IN_DAY
    return timeNowSeconds + daysInSeconds
  }


  return (
    <div className="wrapper">
      <Helmet title={intl.formatMessage({ id: "INVOICING" })} />
      <section className="invoice">
        <div className="invoice__header">
          <div className="invoice__header-label">
            <h1 className="invoice__title main-title">
              <FormattedMessage id="INVOICING" />
            </h1>
          </div>

          <NavLink
            to={ROUTE.INVOICE_TEMPLATES_PATH}
            className="invoice__header-link second-btn"
          >
            <FormattedMessage id="CHOOSE_TEMPLATE" />
          </NavLink>
        </div>

        <form className="invoice__wrapper" onSubmit={handleInvoiceFormSubmit}>
          <Controller
            control={control}
            name="projectId"
            render={({ field }) => {
              return (
                <InvoiceProjectSelector
                  value={field.value}
                  options={projectOptions}
                  error={errors?.projectId?.message}
                  onChange={(...attr) => {
                    console.log("attr", attr);
                    field.onChange(...attr);
                  }}
                  onBlur={field.onBlur}
                />
              );
            }}
          />

          <div className="invoice-project__group project-group invoice__group-textarea">
            <label
              className="invoice-project__label project-label"
              htmlFor={nameId}
            >
              <FormattedMessage id="FORM.NAME.LABEL" />
            </label>

            <textarea
              className="invoice-project__textarea project-textarea"
              id={nameId}
              placeholder={intl.formatMessage({ id: "FORM.NAME.PLACEHOLDER" })}
              {...register("name")}
            ></textarea>
            {errors.name?.message && (
              <div className="project-error">{errors.name.message}</div>
            )}
          </div>

          <div className="invoice-project__groups project-groups">
            <div
              className="invoice-project__group invoice-project__group--transparent project-group"
              style={{
                border: "none",
              }}
            >
              <div className="invoice-project__group invoice-project__group-changes">
                <div className="invoice-project__radios">
                  <div className="invoice-project__label project-label">
                    <Checkbox
                      id={useConvertToId}
                      label={intl.formatMessage({
                        id: "FORM.CONVERT_TO.LABEL",
                      })}
                      attrs={register("useConvertTo")}
                    />

                    {/* <div className="invoice-project__radio-container">
                      <div className="invoice-project__radio-group">
                        <input
                          className="invoice-project__radio"
                          type="radio"
                          name="marka"
                          id="radio10"
                          defaultChecked
                        />

                        <label
                          className={classNames(
                            "invoice-project__radio-label",
                            !useConvertToValue &&
                              "invoice-project__radio-label--disabled"
                          )}
                          htmlFor="radio10"
                        >
                          Фиат
                        </label>
                      </div>

                      <div className="invoice-project__radio-group">
                        <input
                          className="invoice-project__radio"
                          type="radio"
                          name="marka"
                          id="radio11"
                        />

                        <label
                          className={classNames(
                            "invoice-project__radio-label",
                            !useConvertToValue &&
                              "invoice-project__radio-label--disabled"
                          )}
                          htmlFor="radio11"
                        >
                          Крипто
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <Controller
                  control={control}
                  name="depositCurrency"
                  render={({ field }) => {
                    return (
                      <DepositCurrencySelector
                        currency={field.value}
                        currencies={depositCurrencies}
                        loading={depositCurrenciesLoading}
                        disabled={useConvertToValue !== true}
                        onChange={field.onChange}
                      />
                    );
                  }}
                /> */}

                <Controller
                  name="depositCurrency"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InvoiceCurrencySelector
                        currency={field.value}
                        currencies={CONVERT_CURRENCIES}
                        loading={depositCurrenciesLoading}
                        disabled={useConvertToValue !== true}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </div>
            </div>

            {/* <div className="invoice-project__group invoice-project__group--transparent project-group">
              <div className="invoice-project__radios">
                <div
                  className={classNames(
                    "invoice-project__label project-label",
                    !useConvertToValue && "invoice-project__label--disabled"
                  )}
                >
                  Комиссию оплачивает:
                </div>

                <div className="invoice-project__radio-container">
                  <div className="invoice-project__radio-group">
                    <input
                      className="invoice-project__radio"
                      type="radio"
                      name="pay"
                      id="radio12"
                      defaultChecked
                    />

                    <label
                      className={classNames(
                        "invoice-project__radio-label",
                        !useConvertToValue &&
                          "invoice-project__radio-label--disabled"
                      )}
                      htmlFor="radio12"
                    >
                      Мерчант
                    </label>
                  </div>

                  <div className="invoice-project__radio-group">
                    <input
                      className="invoice-project__radio"
                      type="radio"
                      name="pay"
                      id="radio13"
                    />

                    <label
                      className={classNames(
                        "invoice-project__radio-label",
                        !useConvertToValue &&
                          "invoice-project__radio-label--disabled"
                      )}
                      htmlFor="radio13"
                    >
                      Клиент
                    </label>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="invoice-project__group invoice-project__group--transparent project-group "
              style={{
                border: "none",
              }}
            >
              <div className="invoice-project__radios">
                <div className="invoice-project__label project-label">
                  <FormattedMessage id="FORM.FEE_PAYER.LABEL" />
                </div>

                <Controller
                  name="feePayee"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Tabs
                        items={[
                          {
                            value: intl.formatMessage({ id: "MERCHANT" }),
                            label: intl.formatMessage({ id: "MERCHANT" }),
                          },
                          {
                            value: intl.formatMessage({ id: "CUSTOMER" }),
                            label: intl.formatMessage({ id: "CUSTOMER" }),
                          },
                        ]}
                        onSelect={field.onChange}
                        value={field.value}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="invoice-project__label project-label invoice__group-textarea">
            <div className="checkbox-group">
              <input
                className="checkbox-input"
                type="checkbox"
                id={useDepositId}
                {...register("useDeposit")}
              />
              <label className="checkbox-label" htmlFor={useDepositId}>
                <div className="checkbox-decor"></div>{" "}
                <FormattedMessage id="FORM.USE_DEPOSIT.LABEL" />
              </label>
            </div>
          </div>

          <div className="invoice-project__groups project-groups">
            <div
              className={classNames(
                "invoice-project__group project-group",
                useDepositValue !== true && "invoice-project__group--disabled"
              )}
            >
              <label
                className={classNames(
                  "invoice-project__label project-label",
                  useDepositValue !== true && "invoice-project__label--disabled"
                )}
                htmlFor={depositAmountId}
              >
                <FormattedMessage id="DEPOSIT" />
              </label>

              <input
                className="invoice-project__input project-input"
                id={depositAmountId}
                type="text"
                placeholder={intl.formatMessage({
                  id: "FORM.DEPOSIT.PLACEHOLDER",
                })}
                disabled={useDepositValue !== true}
                {...register("depositAmount")}
              />
              {errors.depositAmount?.message && (
                <div className="project-error">
                  {errors.depositAmount.message}
                </div>
              )}
            </div>

            <div
              className={classNames(
                "invoice-project__group project-group",
                useDepositValue !== true && "invoice-project__group--disabled"
              )}
            >
              <label
                className={classNames(
                  "invoice-project__label project-label",
                  useDepositValue !== true && "invoice-project__label--disabled"
                )}
                htmlFor={depositReturnAtId}
              >
                <FormattedMessage id="FORM.DEPOSIT_TERM.LABEL" />
              </label>

              <input
                className="invoice-project__input project-input"
                id={depositReturnAtId}
                type="number"
                placeholder={intl.formatMessage({
                  id: "FORM.DEPOSIT_TERM.PLACEHOLDER",
                })}
                disabled={useDepositValue !== true}
                {...register("depositReturnAt", { valueAsNumber: true })}
              />
              {errors.depositReturnAt?.message && (
                <div className="project-error">
                  {errors.depositReturnAt?.message}
                </div>
              )}
            </div>
          </div>

          <div className="about-deposit__generation-select invoice__generation-select">
            <div className="about-deposit__generation-selected about-deposit__generation-selected--not-reverse">
              <div className="about-deposit__generation-info">
                <label
                  className="about-deposit__generation-title"
                  htmlFor={amountId}
                >
                  <FormattedMessage id="AMOUNT" />
                </label>

                <input
                  className="about-deposit__generation-input"
                  id={amountId}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "FORM.AMOUNT.PLACEHOLDER",
                  })}
                  {...register("amount")}
                />
              </div>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => {
                  return (
                    <InvoiceCurrencySelector
                      currency={field.value}
                      currencies={invoiceCurrencies}
                      loading={invoiceCurrenciesLoading}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </div>
            {errors.amount?.message && (
              <div className="project-error">{errors.amount.message}</div>
            )}
            {errors.currency?.message && (
              <div className="project-error">{errors.currency.message}</div>
            )}
          </div>

          {errors.root?.message && (
            <div className="my-projects__error">{errors.root.message}</div>
          )}

          {projectsError && (
            <div className="my-projects__error">
              <FormattedMessage id="ERROR.CANT_GET_PROJECTS" />
            </div>
          )}

          <div className="invoice-project__item-btns my-projects__item-btns flex-column">
            <button type="submit" className="invoice-project__btn second-btn">
              {(formProcess === "new" || formProcess === "template") && (
                <FormattedMessage id="GENERATE_PAYMENT_LINK" />
              )}
              {formProcess === "edit" && <FormattedMessage id="SAVE_CHANGES" />}
            </button>

            {formProcess === "new" && (
              <div className="checkbox-group pt15">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  id={isTemplateId}
                  {...register("isTemplate")}
                />
                <label className="checkbox-label" htmlFor={isTemplateId}>
                  <div className="checkbox-decor"></div>{" "}
                  <FormattedMessage id="SAVE_AS_TEMPLATE" />
                </label>
              </div>
            )}
          </div>
        </form>
      </section>

      {paymentToken !== null && (
        <Portal>
          <PaymentLinkModal
            open={paymentLinkModalOpened}
            token={paymentToken}
            text={paymentDescription}
            onClose={handlePaymentLinkModalClose}
          />
        </Portal>
      )}
    </div>
  );
}