import { NOTIFICATIONS_PAGE, ROUTE } from "@constants/path-locations";
import { NityfySubType, accountNotificationsType, useAccountNotifications } from "../../../../app/hooks/useAccountNotifications";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";


export interface NotificationsListProps {
  notifications: accountNotificationsType[];
  unread: number;
  isOpenList: boolean;
  onClose: () => void;
}


const ITEMS_LIMIT: number = 5;


export function NotificationsList({
  notifications,
  unread,
  isOpenList,
  onClose,
}: NotificationsListProps) {
  const { setStatusRead } = useAccountNotifications();
  const navigate = useNavigate();
  const intl = useIntl();


  function openNotifications(notificationID?: number): void {
    onClose();

    if (notificationID) {
      navigate(NOTIFICATIONS_PAGE, {
        replace: false,
        state: { notificationID },
      });
      return;
    }
    navigate(NOTIFICATIONS_PAGE, { replace: false });
  }

  function handleNotificationItemClick(notificationID: number, notificationType: string, notificationSubType: NityfySubType, orderId?: number) {
    if(notificationSubType) {
      setStatusRead(notificationID, true);
      let navigateLink

      switch (notificationSubType) {
        case "return":
          navigateLink = ROUTE.DEPOSIT_RETENTION_PATH
          break;
        case "infoRequested":
          navigateLink = ROUTE.DEPOSIT_WITHHOLD_REQUESTS
          break;
        default:
          navigateLink = ROUTE.DEPOSIT_RETENTION_PATH
          break;
      }
      navigate(navigateLink, {
        replace: false,
        state: { orderId }
      })
      return
    }
    openNotifications(notificationID)
  }


  return (
    <div
      className={`modal modal-notifications modal-genation-links${
        isOpenList ? " show" : ""
      }`}
      id="notifications"
      onClick={onClose}
    >
      <div className="modal-content" onClick={(ev) => ev.stopPropagation()}>
        <div className="modal-content__header">
          <h4 className="modal-content__title">
            <FormattedMessage
              id="NOTIFICATIONS"
              values={{
                span: (chunks: any) => <span>{chunks}</span>,
                unread: unread && unread > 0 ? unread : "",
              }}
            />
          </h4>

          <button className="close-modal-btn" onClick={onClose}>
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="notifications__accordion accordion">
          {notifications.slice(0, ITEMS_LIMIT).map((item) => {
            return (
              <div
                className={`accordion__item${
                  item.read ? " accordion__item--disabled" : ""
                }`}
                data-accordion-item
                key={item.id}
                onClick={() => handleNotificationItemClick(item.id, item.type, item.sub_type, item.data.orderId)}
              >
                <div className="accordion__header">{item.short}</div>
              </div>
            );
          })}

          <button
            type="button"
            className="modal-content__btn third-btn"
            onClick={() => openNotifications()}
          >
            <FormattedMessage id="OPEN_ALL" />
          </button>
        </div>
      </div>
    </div>
  );
}
