import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthorizedService } from "@awex-api";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  deleteProject,
  getProjects,
  updateProject,
} from "@store/projects/slice";
import {
  EditProjectForm,
  EditProjectFormData,
} from "@components/EditProjectForm";
import { EditProjectFooter } from "./EditProjectFooter";
import { EditProjectHeader } from "./EditProjectHeader";
import { toast } from "react-hot-toast";
import { AppProject } from "src/types";
import { getCompanies } from "@store/companies/slice";
import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, useIntl } from "react-intl";

const DEFAULT_CURRENCIES: { name: string; type: "fiat" | "stablecoin" }[] = [];

export function EditProjectPage() {
  const navigate = useNavigate();

  const { projectId } = useParams();

  const dispatch = useAppDispatch();
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const projects = useAppSelector((state) => state.projects.data);

  const intl = useIntl();

  const project = useMemo(() => {
    if (projects === undefined) {
      return undefined;
    }
    if (projectId === undefined) {
      return undefined;
    }
    const listItem = projects.find((listItem) => listItem.id === projectId);
    if (listItem === undefined) {
      return undefined;
    }
    const project = listItem.project;
    return project;
  }, [projects, projectId]);

  const [currenciesLoading, setCurrenciesLoading] = useState(false);
  const [currenciesLoadingError, setCurrencyLoadingError] = useState("");
  const [currencies, setCurrenceis] = useState(DEFAULT_CURRENCIES);
  const companiesLoading = useAppSelector((state) => state.companies.loading);
  const companies = useAppSelector((state) => state.companies.data);

  useEffect(() => {
    setCurrenciesLoading(true);
    AuthorizedService.currenciesList()
      .then((response) => {
        if (response.convertTo) {
          const nextCurrencies: {
            name: string;
            type: "fiat" | "stablecoin";
          }[] = [];
          for (const { name, type } of response.convertTo) {
            if (name && type) {
              if (type === "fiat") {
                nextCurrencies.push({ name, type });
              } else if (type === "stablecoin") {
                nextCurrencies.push({ name, type: "stablecoin" });
              }
            }
          }
          setCurrenceis(nextCurrencies);
        }
      })
      .catch((error) => {
        setCurrencyLoadingError(
          error?.message ||
            intl.formatMessage({ id: "ERROR.FAILED_TO_LOAD_CURRENCIES" })
        );
      })
      .finally(() => {
        setCurrenciesLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getCompanies());
  }, [dispatch]);

  const handleDeleteBtnClick = () => {
    if (projectId) {
      dispatch(deleteProject({ id: projectId }));
      toast.success(intl.formatMessage({ id: "SUCCESS.PROJECT_REMOVED" }));
    }
    navigate(ROUTE.PROJECTS_PATH);
  };

  const handleSubmit = (formData: EditProjectFormData) => {
    if (!projectId) {
      return;
    }

    const companyId = parseInt(formData.companyId, 10);

    const projectData: AppProject = {
      companyId: companyId,
      name: formData.name,
      description: formData.description,
      feePayee: formData.feePayee,
      paymentBills: formData.paymentBills,
      paymentWeb: formData.paymentWeb,
      paymentTelegram: formData.paymentTelegram,
      activity: formData.activity,
      convertTo: formData.convertTo?.length ? "stablecoin" : undefined, // formData.convertTo : undefined,
      urlWeb: formData.urlWeb,
      urlNotification: formData.urlNotification,
      urlPaymentSuccess: formData.urlPaymentSuccess,
      urlPaymentFailure: formData.urlPaymentFailure,
    };

    dispatch(updateProject({ id: projectId, project: projectData }))
      .unwrap()
      .then(() => {
        toast.success(intl.formatMessage({ id: "SUCCESS.PROJECT_UPDATED" }));
      })
      .catch((error) => {
        console.error(error);
        if (project?.validation?.status === "onReview") {
          toast.error(
            intl.formatMessage({ id: "ERROR.PROJECT_ON_MODERATION" })
          );
          return;
        }
        toast.error(
          intl.formatMessage({ id: "ERROR.FAILED_TO_UPDATE_PROJECT" })
        );
      });
  };

  function handleValidateBtnClick() {
    if (!projectId) return;
    AuthorizedService.projectValidate(projectId?.toString())
      .then((response) => {
        if (!response) return;
        if (response.message === "Successfully updated") {
          toast.success(
            intl.formatMessage({ id: "SUCCESS.PROJECT_SENT_FOR_MODERATION" })
          );
          return;
        }
        toast.error(
          intl.formatMessage({
            id: "ERROR.FAILED_TO_SEND_PROJECT_FOR_MODERATION",
          })
        );
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.FAIL_HAPPENED" }));
      })
      .finally(() => {
        dispatch(getProjects());
      });
  }

  if (!project && !projectsLoading) {
    navigate(ROUTE.PROJECTS_PATH, { replace: true });
    return null;
  }

  return (
    <div className="wrapper">
      <section className="my-projects">
        <div className="my-projects__header">
          <h1 className="my-projects__title main-title">
            <FormattedMessage id="DASHBOARD.SIDEBAR.PROJECTS" />
          </h1>

          <div
            className="my-projects__added"
            role="button"
            onClick={handleDeleteBtnClick}
          >
            <img
              className="my-projects__added-img"
              src="/img/icons/trash.svg"
              alt={intl.formatMessage({ id: "DELETE" })}
            />

            <span className="my-projects__added-descr">
              <FormattedMessage id="REMOVE_PROJECT" />
            </span>
          </div>
        </div>

        <EditProjectForm
          project={project}
          loading={companiesLoading || currenciesLoading || projectsLoading}
          companies={companies}
          onSubmit={handleSubmit}
          header={<EditProjectHeader project={project} />}
          footer={
            <EditProjectFooter
              validationStatus={
                project?.validation && project?.validation !== null
                  ? true
                  : false
              }
              onValidate={handleValidateBtnClick}
            />
          }
        />
      </section>
    </div>
  );
}
