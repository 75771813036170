import { useAppDispatch, useAppSelector } from "@store/hooks";
import { EmployeeList } from "./EmployeeList";
import {
  deleteTeamMember,
  disableTeamMember,
  enableTeamMember,
  getTeamMembers,
  teamMemberSelectors,
} from "@store/accountTeam/slice";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

export interface EmployeeListContainerProps {
  className?: string;
  onNavAddEmployee: () => void;
}

export function EmployeeListContainer(props: EmployeeListContainerProps) {
  const dispatch = useAppDispatch();
  const employees = useAppSelector((state) =>
    teamMemberSelectors.selectAll(state.accountTeam.data)
  );

  const intl = useIntl();

  useEffect(() => {
    dispatch(getTeamMembers());
  }, []);

  const handleEnableEmployee = (employeeId: string) => {
    dispatch(enableTeamMember({ id: employeeId }))
      .then(() => {
        toast.success(intl.formatMessage({ id: "WORKER_IS_BLOCKED" }));
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.FAILED_TO_BLOCK_WORKER" }));
      });
  };

  const handleDisableEmployee = (employeeId: string) => {
    dispatch(disableTeamMember({ id: employeeId }))
      .then(() => {
        toast.success(intl.formatMessage({ id: "WORKER_IS_BLOCKED" }));
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.FAILED_TO_BLOCK_WORKER" }));
      });
  };

  const handleDeleteEmployee = (employeeId: string) => {
    dispatch(deleteTeamMember({ id: employeeId }))
      .then(() => {
        toast.success(intl.formatMessage({ id: "SUCCESS.WORKER_IS_REMOVED" }));
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          intl.formatMessage({ id: "ERROR.FAILED_TO_REMOVE_WORKER" })
        );
      });
  };

  return (
    <EmployeeList
      className={props.className}
      employees={employees}
      onNavAddEmployee={props.onNavAddEmployee}
      onEnableEmployee={handleEnableEmployee}
      onDisableEmployee={handleDisableEmployee}
      onDeleteEmployee={handleDeleteEmployee}
    />
  );
}
