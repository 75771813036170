import { ROUTE } from "@constants/path-locations";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IAdminDepositItem } from "src/generated/awex-api/models/AdminDepositItem";

interface IProps {
  application: IAdminDepositItem;
}

const AdminDepositCard: React.FC<IProps> = ({ application }) => {
  const navigate = useNavigate()

  console.log('application: ', application)

  // waitMerchant, rejected

  let className: string
  let statusLabel: string | undefined

  switch(application.status) {
    // case "read":
    //   className = "";
    //   break;
    case "waitAdmin":
      className = "admin-applications__item--waiting-dark";
      statusLabel = "Ожидает действий оператора";
      break;
    case "waitMerchant":
      className = "admin-applications__item--waiting-grey";
      statusLabel = "Ожидает действий мерчанта";
      break;
    case "rejected":
      className = "admin-applications__item--rejected";
      statusLabel = "Отклонен";
      break;
    case "approved":
      className = "admin-applications__item--sussfily";
      statusLabel = "Подтвержден";
      break;
    default:
      className = ""
  }


  function handleDepositItemClick() {
    navigate(`${ROUTE.ADMIN_APPLICATIONS_DEPOSIT_PROCESSING}/${application.id}`);
  }
  

  return (
    <li className={clsx("admin-applications__item", className)}
      onClick={handleDepositItemClick}
    >
      <div className="admin-applications__item-content">{application?.id}</div>

      <div className="admin-applications__item-content">
        ID { application?.buyerIdentifier ? application?.buyerIdentifier : '...'}
      </div>

      <div className="admin-applications__item-content">
        {new Date(application?.createdAt * 1000).toLocaleDateString()} 
      </div>

      <div className="admin-applications__item-content">
        Депозит <span>{application?.depositAmount}</span>
      </div>

      <div className="admin-applications__item-content">
        Сумма удержания <span>{application?.deposit.withholdAmount}</span>
      </div>

      <div className="admin-applications__item-content">
        <span>{statusLabel}</span>

        {application.status === "approved" && (
          <img src="/img/icons/check-circle-green.svg" alt="" />
        )}
        {application.status === "rejected" && (
          <img src="./img/icons/red-close.svg" alt="" />
        )}
      </div>
    </li>
  );
};

export default AdminDepositCard;
