import React, { useState } from "react";
import Cards from "./Cards";
import OfficeAddresses from "./OfficeAddresses";
import usePortal from "react-useportal";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { AuthorizedService } from "@awex-api";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

export const RequisitesTab = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [confirmationModalText, setConfirmationModalText] =
    useState<string>("");
  const [deleteConfirmationData, setDeleteConfirmationData] = useState<{
    id: number;
    element: "CARD" | "ADDRESS";
  }>();

  const intl = useIntl();

  const { Portal } = usePortal();

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    alert(intl.formatMessage({ id: "NOT_IMPLEMENTED" }));
  };

  const deleteCardHandler = (id: number) => {
    setConfirmationModalText(
      intl.formatMessage({ id: "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CARD" })
    );
    setDeleteConfirmationData({ id, element: "CARD" });
    setIsConfirmationModalOpen(true);
  };

  const deleteAddressHandler = (id: number) => {
    setConfirmationModalText(
      intl.formatMessage({
        id: "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_OFFICE_ADDRESS",
      })
    );
    setDeleteConfirmationData({ id, element: "ADDRESS" });
    setIsConfirmationModalOpen(true);
  };

  const deleteCardConfirmationHandler = (
    data: { id: number; element: "CARD" | "ADDRESS" },
    answer: boolean
  ) => {
    if (!answer) {
      setIsConfirmationModalOpen(false);
      return;
    }
    data?.element === "CARD"
      ? AuthorizedService.deleteCard(data.id)
          .then(() => {
            toast.success(intl.formatMessage({ id: "SUCCESS.CARD_REMOVED" }));
            setIsConfirmationModalOpen(false);
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: "ERROR.DELETE" }));
            setIsConfirmationModalOpen(false);
          })
      : AuthorizedService.deleteOfficeAddress(data.id)
          .then(() => {
            toast.success(
              intl.formatMessage({ id: "SUCCESS.ADDRESS_REMOVER" })
            );
            setIsConfirmationModalOpen(false);
          })
          .catch(() => {
            toast.error(intl.formatMessage({ id: "ERROR.DELETE" }));
            setIsConfirmationModalOpen(false);
          });
  };

  return (
    <>
      <div className="settings-requisites__lists">
        <Cards deleteCardHandler={deleteCardHandler} />

        <div className="settings-requisites__list-wrapper ___not_implemented">
          <div className="settings-requisites__header">
            <h3 className="settings-requisites__title">
              <FormattedMessage id="MY_ACCOUNTS" />
            </h3>

            <a
              className="settings-requisites__added"
              href="#"
              onClick={handleClick}
            >
              <FormattedMessage id="ADD_ACCOUNT" />
            </a>
          </div>

          <ul className="settings-requisites__list">
            <li className="settings-requisites__item">
              <div className="settings-requisites__item-block">
                <img src="/img/mir-card.png" alt="mir-card" />
              </div>

              <div className="settings-requisites__item-block">
                <span>****</span>
                <span>****</span>
                <span>****</span>
                <span> 1234</span>
                <span>(My card#1)</span>
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="UNTIL" /> 12/28
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="AMOUNT_OF_OPERATIONS" /> 158
              </div>
            </li>

            <li className="settings-requisites__item">
              <div className="settings-requisites__item-block">
                <img src="/img/mir-card.png" alt="mir-card" />
              </div>

              <div className="settings-requisites__item-block">
                <span>****</span>
                <span>****</span>
                <span>****</span>
                <span> 1234</span>
                <span>(My card#1)</span>
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="UNTIL" /> 12/28
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="AMOUNT_OF_OPERATIONS" /> 158
              </div>
            </li>

            <li className="settings-requisites__item">
              <div className="settings-requisites__item-block">
                <img src="/img/mir-card.png" alt="mir-card" />
              </div>

              <div className="settings-requisites__item-block">
                <span>****</span>
                <span>****</span>
                <span>****</span>
                <span> 1234</span>
                <span>(My card#1)</span>
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="UNTIL" /> 12/28
              </div>

              <div className="settings-requisites__item-block">
                <FormattedMessage id="AMOUNT_OF_OPERATIONS" /> 158
              </div>
            </li>
          </ul>
        </div>

        <OfficeAddresses deleteAddressHandler={deleteAddressHandler} />

        <Portal>
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            text={confirmationModalText}
            data={deleteConfirmationData}
            answer={deleteCardConfirmationHandler}
          />
        </Portal>
      </div>
    </>
  );
};
