import { FormattedMessage } from "react-intl";

export function WaitForCrypto() {
  return (
    <div className="payment__wait">
      <div className="payment__wait-header">
        <h1 className="payment__wait-title main-title">
          <FormattedMessage id="TRANSACTION.PROCESSING" />
        </h1>

        <img className="payment__wait-img" src="/img/icons/loader.svg" alt="" />
      </div>

      <p className="payment__wait-text">
        <FormattedMessage id="TRANSACTION.PROCESSING.TIMING" />
      </p>
    </div>
  );
}
