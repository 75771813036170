import { FormattedMessage, useIntl } from "react-intl";

export interface EditProjectFooterProps {
  validationStatus: boolean;
  onValidate: () => void;
}

export function EditProjectFooter(props: EditProjectFooterProps) {
  const intl = useIntl();

  return (
    <div className="my-projects__item-btns">
      {!props.validationStatus && (
        <button
          type="button"
          className="my-projects__btn second-btn"
          onClick={props.onValidate}
        >
          <FormattedMessage id="SEND_TO_VALIDATION" />
        </button>
      )}

      <button type="submit" className="my-projects__btn second-btn">
        <FormattedMessage id="SAVE_DATA" />
      </button>
    </div>
  );
}
