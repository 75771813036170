import { AuthenticatedService } from "@awex-api";
import { useEffect, useState } from "react";
import GoogleAuthenticator from "./GoogleAuthenticator";
import Gmail from "./Gmail";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

type TwoFaType = "googleAuthenticator" | "gmail" | "facebook";

export type OtpEnable = {
  _2faType: TwoFaType;
  otp: string;
};

export default function GoogleTwoFA() {
  const [twoFaPoints, setTwoFaPoints] = useState<string[]>([]);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isEnableInProcess, setIsEnableInProcess] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    getTwoFaPoints();
  }, []);

  function getTwoFaPoints() {
    AuthenticatedService.otpEnabled()
      .then((reponse) => {
        if (!reponse) {
          return;
        }
        setTwoFaPoints(reponse.enabled);
      })
      .catch((error) => {
        console.error(error);
        setTwoFaPoints([]);
      });
  }

  function checkTwoFaPoints(type: TwoFaType): boolean {
    const status = twoFaPoints.find((item) => {
      return item === type;
    });
    return status ? true : false;
  }

  function handleSnapButtonClick() {
    setIsFormOpen(true);
  }

  function otpEnable(value: OtpEnable) {
    if (isEnableInProcess) return;
    setIsEnableInProcess(true);

    AuthenticatedService.otpEnable(value)
      .then((response) => {
        if (!response) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }

        if (!response.valid) {
          toast.error(intl.formatMessage({ id: "ERROR.OTP_CODE_NOT_VALID" }));
          return;
        }
        toast.success(intl.formatMessage({ id: "SUCCESS.CHANGES_SAVED" }));
        getTwoFaPoints();
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 400) {
          toast.error(intl.formatMessage({ id: "ERROR.OTP_CODE_NOT_VALID" }));
          return;
        }
        toast.error(intl.formatMessage({ id: "ERROR.CONNECTION_FAILED   `" }));
      })
      .finally(() => {
        setIsEnableInProcess(false);
      });
  }

  return (
    <div className="settings-profile__select">
      <div className="settings-security__header">
        <h3 className="settings-security__title">
          <FormattedMessage id="GOOGLE_AUTHENTICATION" />
        </h3>
      </div>

      <div className="settings-security__middle">
        <p className="settings-security__text">
          <FormattedMessage id="GOOGLE_AUTHENTICATION.DESCRIPTION" />
        </p>
      </div>

      {twoFaPoints.length === 0 && !isFormOpen && (
        <button
          className="settings-security__btn main-btn"
          type="button"
          onClick={handleSnapButtonClick}
        >
          <FormattedMessage id="BIND" />
        </button>
      )}

      {(twoFaPoints.length > 0 || isFormOpen) && (
        <div className="settings-profile__select">
          <div className="settings-security__middle">
            <p className="settings-security__text">
              <FormattedMessage id="CHOOSE_2FA" />
            </p>
          </div>

          <div className="d-flex gap_15 flex-wrap">
            <GoogleAuthenticator
              otpEnable={otpEnable}
              isGlobalProcess={isEnableInProcess}
              status={checkTwoFaPoints("googleAuthenticator")}
            />

            <Gmail
              otpEnable={otpEnable}
              isGlobalProcess={isEnableInProcess}
              status={checkTwoFaPoints("gmail")}
            />
          </div>
        </div>
      )}
    </div>
  );
}
