import { FormattedMessage } from "react-intl";
import formatFloatNumber from "../../../../utils/formatFloatNumber";
import { StatsDataObject } from "..";


interface StatsFooterProps {
  data: StatsDataObject | null;
}


export default function StatsFooter(props: StatsFooterProps) {
  const { data } = props;


  function getTrendClassName(data: string): string {
    const number = parseFloat(data)
    if(number === 0) return "grey"
    return number > 0 ? "green" : "red"
  }


  return (
    <div className="stats__footer">
      {data && (
        <ul className="stats__list">
          {data.paidSum !== undefined && (
            <li className="stats__item stats__item--full">
              <div className="stats__item-label">
                <FormattedMessage id="GENERAL_AMOUNT_USD" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.paidSum) } </div>
              { data.paidSum24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.paidSum24Percents)}`}>{ data.paidSum24Percents } </span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.paidNumber !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="PAID_BILLS" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.paidNumber) } </div>
              { data.paidNumber24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.paidNumber24Percents)}`}>{ data.paidNumber24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.createdNumber !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="CREATED_BILLS" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.createdNumber) } </div>
              { data.createdNumber24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.createdNumber24Percents)}`}>{ data.createdNumber24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.partialPaidNumber !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="PARTIALLY_PAID" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.partialPaidNumber) } </div>
              { data.partialPaidNumber24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.partialPaidNumber24Percents)}`}>{ data.partialPaidNumber24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.returnedNumber !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="RETURNED_DEPOSITS" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.returnedNumber) } </div>
              { data.returnedNumber24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.returnedNumber24Percents)}`}>{ data.returnedNumber24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.returnedSum !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="RETURNED_DEPOSITS_AMOUNT" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.returnedSum) } </div>
              { data.returnedSum24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.returnedSum24Percents)}`}>{ data.returnedSum24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.withheldNumber !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="NUMBER_OF_DEPOSITS_WITHHELD" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.withheldNumber) } </div>
              { data.withheldNumber24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.withheldNumber24Percents)}`}>{ data.withheldNumber24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}

          {data.withheldSum !== undefined && (
            <li className="stats__item">
              <div className="stats__item-label">
                <FormattedMessage id="AMOUNT_OF_DEPOSITS_WITHHELD" />
              </div>
              <div className="stats__item-sum"> { formatFloatNumber(data.withheldSum) } </div>
              { data.withheldSum24Percents && (
                <div className="stats__item-footer">
                  <span className={`stats__item-percent ${getTrendClassName(data.withheldSum24Percents)}`}>{ data.withheldSum24Percents }</span>{" "}
                  <FormattedMessage id="FOR_TODAY" />
                </div>
              )}
            </li>
          )}
        </ul>
      )}
    </div>
  );
}