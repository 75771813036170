import { ROUTE } from "@constants/path-locations";
import { AuthModalContainer } from "@containers/AuthModalContainer";
import { BlockProfileModalContainer } from "@containers/BlockProfileModalContainer";
import { DescribeProblemModalContainer } from "@containers/DescribeProblemModalContainer";
import LanguagesModalContainer from "@containers/LanguagesModalContainer";
import { RecoverModalContainer } from "@containers/RecoverModalContainer";
import { RegisterModalContainer } from "@containers/RegisterModalContainer";
import { useAppSelector } from "@store/hooks";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../contexts/LanguageContext";

const LandingHeader: React.FC = () => {
  const [authModalOpened, setAuthModalOpened] = useState(false);
  const [registerModalOpened, setRegisterModalOpened] = useState(false);
  const [recoverModalOpened, setRecoverModalOpened] = useState(false);
  const [describeProblemModalOpened, setDescribeProblemModalOpened] =
    useState(false);
  const [blockProfileModalOpened, setBlockProfileModalOpened] = useState(false);
  const [isLanguagesModalOpen, setIsLanguagesModalOpen] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const context = useContext(LanguageContext);

  const intl = useIntl();

  const toggleMenu = () => {
    setMenuActive((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const authorized = useAppSelector((state) => state.auth.user !== undefined);

  const handleSignInBtnClick = () => {
    setAuthModalOpened(!authModalOpened);
  };

  const handleRecoverBtnClick = () => {
    setRecoverModalOpened(!recoverModalOpened);
  };

  const handleCloseAuthModal = () => {
    setAuthModalOpened(false);
  };

  const handleCloseRegisterModal = () => {
    setRegisterModalOpened(false);
  };

  const handleCloseDescribeProblemModal = () => {
    setDescribeProblemModalOpened(false);
  };

  const handleNavRegister = () => {
    setAuthModalOpened(false);
    setRegisterModalOpened(true);
  };

  const handleNavRecover = () => {
    setAuthModalOpened(false);
    setRecoverModalOpened(true);
  };

  const handleNavDescribeProblem = () => {
    setAuthModalOpened(false);
    setDescribeProblemModalOpened(true);
  };

  const onNavBlockProfile = () => {
    setAuthModalOpened(false);
    setBlockProfileModalOpened(true);
  };

  const handleCloseBlockProfileModal = () => {
    setBlockProfileModalOpened(false);
  };

  const handleCloseLanguagesModal = () => {
    setIsLanguagesModalOpen(false);
  };

  const handleLanguagesModalOpen = () => {
    setIsLanguagesModalOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991.98) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header header-app">
      <nav className="nav">
        <div className="nav__container">
          <div className="nav__list">
            <div className="nav__logo">
              <Link to={ROUTE.HOME_PAGE_PATH}>
                <img
                  src="/img/sidebar/logo.svg"
                  alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
                />
              </Link>
            </div>
            <ul className="nav__menu">
              <li className="nav__item" data-select-wrapper>
                <Link
                  to={ROUTE.HOME_PERSONAL_PAGE_PATH}
                  className="nav__item-link"
                  data-scroll
                >
                  <FormattedMessage id="LANDING.HEADER.NAV.PERSONAL" />
                </Link>
              </li>

              <li className="nav__item" data-select-wrapper>
                <Link
                  to={ROUTE.HOME_BUSINESS_PAGE_PATH}
                  className="nav__item-link"
                  data-scroll
                >
                  <FormattedMessage id="LANDING.HEADER.NAV.BUSINESS" />
                </Link>
              </li>
            </ul>
            <div className="nav__others">
              {context?.language === "ru" && (
                <div
                  className="sidebar__user"
                  onClick={handleLanguagesModalOpen}
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/icons/ru.svg"
                    alt={intl.formatMessage({ id: "ICON.RUSSIAN_FLAG.ALT" })}
                  />

                  <span>RU</span>
                </div>
              )}
              {context?.language === "en" && (
                <div
                  className="sidebar__user"
                  onClick={handleLanguagesModalOpen}
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/icons/en.svg"
                    alt={intl.formatMessage({ id: "ENGLISH" })}
                  />

                  <span>EN</span>
                </div>
              )}
              {context?.language === "ch" && (
                <div
                  className="sidebar__user"
                  onClick={handleLanguagesModalOpen}
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/icons/chi.svg"
                    alt={intl.formatMessage({ id: "CHINESE" })}
                  />

                  <span>CH</span>
                </div>
              )}
              {context?.language === "vn" && (
                <div
                  className="sidebar__user"
                  onClick={handleLanguagesModalOpen}
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/icons/vietnam.svg"
                    alt={intl.formatMessage({ id: "VIETNAMESE" })}
                  />

                  <span>VN</span>
                </div>
              )}

              {authorized ? (
                <Link
                  to={ROUTE.DASHBOARD_PATH}
                  className="sidebar__user sidebar__user--ls"
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/sidebar/user-black.svg"
                    alt="user"
                  />

                  <span>
                    <FormattedMessage id="DASHBOARD" />
                  </span>
                </Link>
              ) : (
                <button
                  className="landing-page__btn second-btn"
                  onClick={handleSignInBtnClick}
                >
                  <FormattedMessage id="LANDING.HEADER.NAV.SIGN_IN" />
                </button>
              )}
            </div>

            <div className="header-mobile__container">
              {authorized ? (
                <Link
                  to={ROUTE.DASHBOARD_PATH}
                  className="sidebar__user sidebar__user--ls"
                >
                  <img
                    className="sidebar__user-icon"
                    src="/img/sidebar/user-black.svg"
                    alt="user"
                  />

                  <span>
                    <FormattedMessage id="DASHBOARD" />
                  </span>
                </Link>
              ) : (
                <button
                  className="landing-page__btn second-btn"
                  onClick={handleSignInBtnClick}
                >
                  <FormattedMessage id="LANDING.HEADER.NAV.SIGN_IN" />
                </button>
              )}
              <div
                className={`burger ${menuActive ? "active-burger" : ""}`}
                onClick={toggleMenu}
              >
                <div className={`nav__menu ${menuActive ? "active" : ""}`}>
                  <ul className="">
                    <li className="nav__item" data-select-wrapper>
                      <Link
                        to={ROUTE.HOME_PERSONAL_PAGE_PATH}
                        className="nav__item-link"
                        data-scroll
                      >
                        <FormattedMessage id="LANDING.HEADER.NAV.PERSONAL" />
                      </Link>
                    </li>

                    <li className="nav__item" data-select-wrapper>
                      <Link
                        to={ROUTE.HOME_BUSINESS_PAGE_PATH}
                        className="nav__item-link"
                        data-scroll
                      >
                        <FormattedMessage id="LANDING.HEADER.NAV.BUSINESS" />
                      </Link>
                    </li>
                  </ul>
                  <div className="nav__others-mobile">
                    {context?.language === "ru" && (
                      <div
                        className="sidebar__user"
                        onClick={handleLanguagesModalOpen}
                      >
                        <img
                          className="sidebar__user-icon"
                          src="/img/icons/ru.svg"
                          alt={intl.formatMessage({ id: "RUSSIAN" })}
                        />

                        <span>RU</span>
                      </div>
                    )}
                    {context?.language === "en" && (
                      <div
                        className="sidebar__user"
                        onClick={handleLanguagesModalOpen}
                      >
                        <img
                          className="sidebar__user-icon"
                          src="/img/icons/en.svg"
                          alt={intl.formatMessage({ id: "ENGLISH" })}
                        />

                        <span>EN</span>
                      </div>
                    )}
                    {context?.language === "ch" && (
                      <div
                        className="sidebar__user"
                        onClick={handleLanguagesModalOpen}
                      >
                        <img
                          className="sidebar__user-icon"
                          src="/img/icons/chi.svg"
                          alt={intl.formatMessage({ id: "CHINESE" })}
                        />

                        <span>CH</span>
                      </div>
                    )}
                    {context?.language === "vn" && (
                      <div
                        className="sidebar__user"
                        onClick={handleLanguagesModalOpen}
                      >
                        <img
                          className="sidebar__user-icon"
                          src="/img/icons/vietnam.svg"
                          alt={intl.formatMessage({ id: "VIETNAMESE" })}
                        />

                        <span>CH</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <AuthModalContainer
        open={authModalOpened}
        onClose={handleCloseAuthModal}
        onNavRegister={handleNavRegister}
        onNavRecover={handleNavRecover}
        onNavDescribeProblem={handleNavDescribeProblem}
        onNavBlockProfile={onNavBlockProfile}
      />

      <RegisterModalContainer
        open={registerModalOpened}
        onClose={handleCloseRegisterModal}
      />

      <RecoverModalContainer
        open={recoverModalOpened}
        onClose={handleRecoverBtnClick}
      />

      <DescribeProblemModalContainer
        open={describeProblemModalOpened}
        onClose={handleCloseDescribeProblemModal}
      />

      <BlockProfileModalContainer
        open={blockProfileModalOpened}
        onClose={handleCloseBlockProfileModal}
      />

      <LanguagesModalContainer
        open={isLanguagesModalOpen}
        onClose={handleCloseLanguagesModal}
      />
    </header>
  );
};

export default LandingHeader;
