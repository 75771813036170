// functions for converting the deposit term into a timestamp and back
const SECONDS_IN_DAY = 86400
const MILISECONDS_IN_DAY = SECONDS_IN_DAY * 1000

export function daysToMiliseconds(days: number): number {
    return days * MILISECONDS_IN_DAY
}

export function milisecondsToDays(miliseconds: number): number {
        const days = miliseconds / MILISECONDS_IN_DAY
        return Number(days.toFixed(0))
}

// export function daysToTimeStamp(days: number): number {
//     const timeNowSeconds = Math.trunc(Date.now() / 1000)
//     const daysInSeconds = days * SECONDS_IN_DAY
//     return timeNowSeconds + daysInSeconds
// }

// export function timeStampToDays(timeStamp: number, createDate: number): number {
//     const diffTime = timeStamp - createDate
//     const days = diffTime / SECONDS_IN_DAY
//     return Number(days.toFixed(0))
// }