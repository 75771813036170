const userCurrencies = [
    {
        currency: 'USDT',
        name: 'Tether',
        disabled: false,
    },
    {
        currency: 'USDC',
        name: '',
        disabled: true,
    },
    {
        currency: 'RUB',
        name: '',
        disabled: true,
    },
    {
        currency: 'USD',
        name: '',
        disabled: true,
    },
    {
        currency: 'AED',
        name: '',
        disabled: true,
    },
]

export default userCurrencies