import { useState } from "react";
import {
  ChangePasswordError,
  ChangePasswordForm,
  ChnagePasswordFormData,
} from "../ChangePasswordForm";
import { ApiError, AuthenticatedService } from "@awex-api";
import toast from "react-hot-toast";
import { UseFormReset } from "react-hook-form";
import { useIntl } from "react-intl";


export function ChangePasswordFormContainer() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ChangePasswordError | null>(null);
  const intl = useIntl();

  
  const handleChangePassword = (
    opts: {
      oldPassword: string;
      password: string;
    },
    reset: UseFormReset<ChnagePasswordFormData>
  ) => {
    setLoading(true);
    AuthenticatedService.passwordSet({
      oldPassword: opts.oldPassword,
      password: opts.password,
    })
      .then(() => {
        reset();
        toast.success(intl.formatMessage({ id: "SUCCESS.PASSWORD_UPDATED" }));
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          const body: unknown = error.body;
          console.log(body);
          if (
            typeof body === "object" &&
            body !== null &&
            "errors" in body &&
            body.errors instanceof Array &&
            body.errors.length &&
            typeof body.errors[0] === "string"
          ) {
            const isInvalidPass = body.errors[0].includes("Incorrect old password")
            const error_message = !isInvalidPass ? body.errors[0] : intl.formatMessage({
              id: "ERROR.INCORRECT_OLD_PASS",
            })
            setError({
              type: "GENERAL",
              message: error_message,
            });
          } else {
            setError({
              type: "GENERAL",
              message: intl.formatMessage({
                id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED",
              }),
            });
          }
        } else if (error instanceof Error) {
          setError({ type: "GENERAL", message: error.message });
        } else {
          setError({
            type: "GENERAL",
            message: intl.formatMessage({
              id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED",
            }),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ChangePasswordForm
      loading={loading}
      error={error || undefined}
      onChangePassword={handleChangePassword}
    />
  );
}
