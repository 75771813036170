import { useState } from "react";
import { AuthenticatedService } from "@awex-api";
import GoogleAuthForm from "./googleAuthForm";
import { removeOtpRequired } from "../../../services/user.service";
import GmailForm from "./gmailForm";
import { FormattedMessage, useIntl } from "react-intl";

export interface ConfirmOtpModalContentProps {
  open: boolean;
  loading: boolean;
  title: string;
  error?: { type: "unknown"; message?: string };
  onClose: () => void;
  otpEnableds: string[];
  isOtpLoading: boolean;
  sendEmail: () => void;
}

export type OtpLoginCode = {
  _2faType: "googleAuthenticator" | "gmail" | "facebook";
  otp: string;
};

export function ConfirmOtpModalContent(props: ConfirmOtpModalContentProps) {
  const { otpEnableds, isOtpLoading, sendEmail } = props;
  const [sendInProcess, setSendInProcess] = useState<boolean>(false);

  const intl = useIntl();

  function checkOtpType(type: string): boolean {
    let status: string | undefined = otpEnableds.find((item) => {
      return item === type;
    });
    return status ? true : false;
  }

  async function sendOtpCdoe(code: OtpLoginCode): Promise<boolean> {
    if (sendInProcess) return false;
    setSendInProcess(true);

    const rezult = await AuthenticatedService.otpLogin(code)
      .then((response) => {
        if (!response) return false;
        removeOtpRequired();
        props.onClose();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      })
      .finally(() => {
        setSendInProcess(false);
      });
    return rezult;
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />
            <h2>
              <FormattedMessage id="OTP.FORM.TITLE" />
            </h2>
          </div>

          <button className="close-modal-btn" onClick={props.onClose}>
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        {isOtpLoading && (
          <div className="modal-content__main">
            <FormattedMessage id="OTP.FORM.LOADING" />
          </div>
        )}

        {!isOtpLoading && (
          <>
            <p className="modal-content__text">
              <FormattedMessage
                id="OTP.FORM.ENTER_THE_CODE"
                values={{
                  message:
                    otpEnableds.length > 1 &&
                    "одного из выбранных вами способа",
                }}
              />
            </p>

            {checkOtpType("googleAuthenticator") && (
              <GoogleAuthForm sendOtpCdoe={sendOtpCdoe} />
            )}

            {checkOtpType("gmail") && (
              <GmailForm sendOtpCdoe={sendOtpCdoe} sendEmail={sendEmail} />
            )}
          </>
        )}
      </div>
    </>
  );
}
