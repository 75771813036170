import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldErrors, useForm } from "react-hook-form";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

export interface RequestAdditionalInfoModalProps {
  open: boolean;
  loading: boolean;
  error?: string;
  onClose: () => void;
  onSubmitRequest: (opts: { message: string }) => void;
}

export type RequestAdditionalInfoModalFormData = {
  message: string;
};

const DEFAULT_FORM_DATA: RequestAdditionalInfoModalFormData = {
  message: "",
};

export function RequestAdditionalInfoModal(
  props: RequestAdditionalInfoModalProps
) {
  const intl = useIntl();

  const requestAdditionalInfoFormSchema = yup.object({
    message: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<RequestAdditionalInfoModalFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(requestAdditionalInfoFormSchema),
  });

  useEffect(() => {
    reset();
  }, [props.open]);

  useEffect(() => {
    if (props.error) {
      setError("root", {
        message: intl.formatMessage({ id: "NOT_IMPLEMENTED" }),
      });
    }
  }, [props.error]);

  const handleRequestAdditionalInfoFormSubmit = handleSubmit((formData) => {
    props.onSubmitRequest({ message: formData.message });
  });

  return (
    <div className={classNames("modal modal-enter", { show: props.open })}>
      <form
        className="modal-content"
        onSubmit={handleRequestAdditionalInfoFormSubmit}
      >
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />

            <h2>
              <FormattedMessage id="DESCRIBE_REQUESTING_DATA" />
            </h2>
          </div>

          <button
            type="button"
            className="close-modal-btn"
            onClick={props.onClose}
          >
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <div className="my-projects__group project-group">
            <textarea
              className="my-projects__input project-textarea"
              placeholder={intl.formatMessage({
                id: "DESCRIBE_REQUESTING_DATA",
              })}
              disabled={props.loading}
              {...register("message")}
            ></textarea>
            {renderFieldError(errors, "message")}
          </div>

          {errors.root && errors.root.message && (
            <div className="modal-content__error">{errors.root.message}</div>
          )}
        </div>

        <button
          type="submit"
          className="modal-content__btn second-btn"
          disabled={props.loading}
        >
          <FormattedMessage id="SEND" />
        </button>
      </form>
    </div>
  );
}

function renderFieldError(
  errors: FieldErrors<RequestAdditionalInfoModalFormData>,
  field: keyof RequestAdditionalInfoModalFormData
) {
  const error = errors[field];
  if (!error || !error.message) {
    return null;
  }
  return <div className="project-error">{error.message}</div>;
}
