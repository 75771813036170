import React from "react";
import { IAdminDepositItem } from "src/generated/awex-api/models/AdminDepositItem";
import AdminDepositCard from "../AdminDepositCard";

interface IProps {
  applications: IAdminDepositItem[];
}

const AdminDepositList: React.FC<IProps> = ({ applications }) => {
  return (
    <ul className="admin-applications__list admin-applications__list--six-column pb-30">
      {applications?.map((application) => (
        <AdminDepositCard application={application} key={application.id} />
      ))}
    </ul>
  );
};

export default AdminDepositList;
