import { useEffect, useId, useState } from "react";
import { object, string } from "yup";
import { FieldErrors, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthenticatedService } from "@awex-api";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

interface AddCodeWordFormData {
  oldWord?: string;
  codeWord: string;
  repeatCodeWord: string;
}

export function AddCodeWord() {
  const codeWordId = useId();
  const repeatCodeWordId = useId();
  const [isSet, setIsSet] = useState<boolean>(false);
  const [settigInProcess, setSettigInProcess] = useState<boolean>(false);

  const intl = useIntl();

  const addCodeWordFormSchema = object({
    oldWord: string(),
    codeWord: string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" }))
      .min(12, intl.formatMessage({ id: "FORM.MAX_LENGTH_12" })),
    repeatCodeWord: string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" }))
      .min(12, intl.formatMessage({ id: "FORM.MAX_LENGTH_12" })),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<AddCodeWordFormData>({
    resolver: yupResolver(addCodeWordFormSchema),
  });

  useEffect(() => {
    checkSecret();
  }, []);

  function checkSecret() {
    AuthenticatedService.accountProfileCheckSecret()
      .then((response) => {
        if (!response || !("isSet" in response)) {
          setIsSet(false);
          return;
        }
        setIsSet(response.isSet);
      })
      .catch((error) => {
        console.error(error);
        setIsSet(false);
      });
  }

  const handleAddedSubmit = handleSubmit((formData) => {
    if (settigInProcess) return;

    if (isSet && (!formData.oldWord || formData.oldWord?.length <= 0)) {
      toast.error(intl.formatMessage({ id: "ERROR.SECRET_WORD" }));
      setError("oldWord", {
        message: intl.formatMessage({ id: "ERROR.SECRET_WORD" }),
      });
      return;
    }

    if (formData.codeWord !== formData.repeatCodeWord) {
      setError("repeatCodeWord", {
        message: intl.formatMessage({ id: "ERROR.SECRET_WORD_DOESNT_MATCH" }),
      });
      return;
    }
    setSettigInProcess(true);

    AuthenticatedService.accountProfileSetSecret(
      formData.codeWord,
      formData.oldWord
    )
      .then((response) => {
        if (!response) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }
        toast.success(intl.formatMessage({ id: "SUCCESS.SAVED" }));
        checkSecret();
        reset();
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 400) {
          toast.error(intl.formatMessage({ id: "ERROR.SECRET_WORD_WRONG" }));
          setError("oldWord", {
            message: intl.formatMessage({ id: "ERROR.SECRET_WORD_WRONG" }),
          });
          return;
        }
        toast.error(intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }));
      })
      .finally(() => {
        setSettigInProcess(false);
      });
  });

  return (
    <form className="settings-profile__select" onSubmit={handleAddedSubmit}>
      <div className="settings-security__header">
        <h3 className="settings-security__title">
          <FormattedMessage id="CODEWORD" />
        </h3>
      </div>

      <div className="settings-security__middle">
        <p className="settings-security__text">
          <FormattedMessage id="CODEWORD.DESCRIPTION" />
        </p>
      </div>

      <div className="settings-security__middle">
        {isSet && (
          <div className="my-projects__group project-group">
            <label
              className="my-projects__label project-label"
              htmlFor={codeWordId}
            >
              <FormattedMessage id="FORM.CURRENT_CODEWORD.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "FORM.CURRENT_CODEWORD.PLACEHOLDER",
              })}
              id={codeWordId}
              {...register("oldWord")}
            />

            {renderFieldError(errors, "oldWord")}
          </div>
        )}

        <div className="my-projects__group project-group">
          <label
            className="my-projects__label project-label"
            htmlFor={codeWordId}
          >
            <FormattedMessage id="FORM.NEW_CODEWORD.LABEL" />
          </label>

          <input
            className="my-projects__input project-input"
            type="text"
            placeholder={intl.formatMessage({
              id: "FORM.NEW_CODEWORD.PLACEHOLDER",
            })}
            id={codeWordId}
            {...register("codeWord")}
          />

          {renderFieldError(errors, "codeWord")}
        </div>

        <div className="my-projects__group project-group">
          <label
            className="my-projects__label project-label"
            htmlFor={repeatCodeWordId}
          >
            <FormattedMessage id="FORM.REPEAT_NEW_CODEWORD" />
          </label>

          <input
            className="my-projects__input project-input"
            type="text"
            placeholder={intl.formatMessage({ id: "FORM.REPEAT_NEW_CODEWORD" })}
            id={repeatCodeWordId}
            {...register("repeatCodeWord")}
          />

          {renderFieldError(errors, "repeatCodeWord")}
        </div>
      </div>

      <button type="submit" className="settings-security__btn main-btn">
        <FormattedMessage id="SAVE" />
      </button>
    </form>
  );
}

function renderFieldError(
  errors: FieldErrors<AddCodeWordFormData>,
  field: keyof AddCodeWordFormData
) {
  const error = errors[field];
  if (!error || !error.message) {
    return null;
  }
  return <div className="project-error">{error.message}</div>;
}
