import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "../i18n/en.json";
import chMessages from "../i18n/ch.json";
import ruMessages from "../i18n/ru.json";
import vnMessages from "../i18n/vn.json";
import { LanguageContext } from "../contexts/LanguageContext";

const messages: any = {
  ru: ruMessages,
  en: enMessages,
  ch: chMessages,
  vn: vnMessages,
};

interface IProps {
  children: React.ReactNode;
}

const LanguageProvider: React.FC<IProps> = ({ children }) => {
  const context = useContext(LanguageContext);

  return (
    <IntlProvider
      locale={context?.language!}
      messages={messages[context?.language!]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
