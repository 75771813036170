import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  EditProjectForm,
  EditProjectFormData,
} from "@components/EditProjectForm";
import { CreateProjectFooter } from "./CreateProjectFooter";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { toast } from "react-hot-toast";
import { AuthorizedService } from "@awex-api";
import { useEffect, useState } from "react";
import { getCompanies } from "@store/companies/slice";
import { AppProject } from "src/types";
import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, useIntl } from "react-intl";

const DEFAULT_CURRENCIES: { name: string; type: "fiat" | "stablecoin" }[] = [];

export function CreateProjectPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [currenciesLoading, setCurrenciesLoading] = useState(false);
  const [currenciesLoadingError, setCurrencyLoadingError] = useState(false);
  const [currencies, setCurrenceis] = useState(DEFAULT_CURRENCIES);

  const companiesLoading = useAppSelector((state) => state.companies.loading);
  const companies = useAppSelector((state) => state.companies.data);

  const intl = useIntl();

  useEffect(() => {
    setCurrenciesLoading(true);
    AuthorizedService.currenciesList()
      .then((response) => {
        if (response.convertTo) {
          const nextCurrencies: {
            name: string;
            type: "fiat" | "stablecoin";
          }[] = [];

          for (const { name, type } of response.convertTo) {
            if (name && type) {
              if (type === "fiat") {
                nextCurrencies.push({ name, type });
              } else if (type === "stablecoin") {
                nextCurrencies.push({ name, type: "stablecoin" });
              }
            }
          }
          setCurrenceis(nextCurrencies);
        }
      })
      .catch((error) => {
        setCurrencyLoadingError(
          error?.message ||
            intl.formatMessage({ id: "ERROR.FAILED_TO_LOAD_CURRENCIES" })
        );
      })
      .finally(() => {
        setCurrenciesLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const handleCancelBtnClick = () => {
    navigate(ROUTE.PROJECTS_PATH);
  };

  const handleSubmit = (formData: EditProjectFormData) => {
    const companyId = parseInt(formData.companyId, 10);

    const projectData: AppProject = {
      companyId: companyId,
      name: formData.name,
      description: formData.description,
      feePayee: formData.feePayee,
      paymentBills: formData.paymentBills,
      paymentWeb: formData.paymentWeb,
      paymentTelegram: formData.paymentTelegram,
      activity: formData.activity,
      convertTo: formData.convertTo?.length ? "stablecoin" : undefined, // formData.convertTo : undefined,
      urlWeb: formData.urlWeb,
      urlNotification: formData.urlNotification,
      urlPaymentSuccess: formData.urlPaymentSuccess,
      urlPaymentFailure: formData.urlPaymentFailure,
    };

    setProjectsLoading(true);
    AuthorizedService.projectCreate(projectData)
      .then((response) => {
        toast.success(intl.formatMessage({ id: "SUCCESS.PROJECT_CREATED" }));
        navigate(`${ROUTE.PROJECTS_PROJECTID_PATH}${response?.id}`);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          intl.formatMessage({ id: "ERROR.FAILED_TO_CREATE_PROJECT" })
        );
      })
      .finally(() => {
        setProjectsLoading(false);
      });
  };

  return (
    <div className="wrapper">
      <Helmet title={intl.formatMessage({ id: "ADDING_PROJECT" })} />

      <section className="my-projects">
        <div className="my-projects__header">
          <h1 className="my-projects__title main-title">
            <FormattedMessage id="ADDING_PROJECT" />
          </h1>
        </div>

        <h2 className="main-title" hidden>
          <FormattedMessage id="PROJECT_WINDOW" />
        </h2>

        <EditProjectForm
          currencies={currencies}
          companies={companies}
          loading={companiesLoading || currenciesLoading || projectsLoading}
          footer={<CreateProjectFooter onCancel={handleCancelBtnClick} />}
          onSubmit={handleSubmit}
        />
      </section>
    </div>
  );
}
