import { useEffect } from "react"
import { useIntegerFractionNumber } from "../../../hooks/useIntegerFractionNumber"
import { RetentionItem } from "../../DepositRetentionPage"
import { FormattedMessage } from "react-intl"


interface DepositWithholdInfoProps {
    retentionData: RetentionItem
}


export default function DepositWithholdInfo(props: DepositWithholdInfoProps) {
    const { retentionData } = props
    const { integer, fraction, setData } = useIntegerFractionNumber(0)


    useEffect(() => {
        setData(Number(retentionData.depositAmount))
        console.log("retentionData.buyerIdentifier", !!retentionData.buyerIdentifier)
    }, [retentionData])

    return (
        <div className="deposit-retention__info">
            <div className="deposit-retention__info-groups">
                <div className="deposit-retention__deposit">
                    <div className="deposit-retention__deposit-label">
                        <FormattedMessage id="CURRENT_DEPOSIT" /> #{ retentionData.id }
                    </div>

                    <div className="deposit-retention__deposit-sum">
                        { integer }<span>,{ fraction }USDT</span>
                    </div>
                </div>

                <div className="deposit-retention__info-group">
                    <div className="deposit-retention__project">
                        <div className="deposit-retention__project-label">
                            <FormattedMessage id="PROJECT" />:
                        </div>

                        <div className="deposit-retention__project-name">
                            { retentionData.projectName }
                        </div>
                        <a className="deposit-retention__project-link" href={retentionData.projectWeb}>{retentionData.projectWeb}</a>
                    </div>

                    { !!retentionData.buyerIdentifier && (

                        <div className="deposit-retention__id">
                            <div className="deposit-retention__label">
                                <FormattedMessage id="ID_CUSTOMER" />
                            </div>

                            <p className="deposit-retention__descr">
                                #{ retentionData.buyerIdentifier }
                            </p>
                            

                            {/* <p className="deposit-retention__descr">
                                *Нет данных о клиенте*
                            </p> */}
                        </div>
                    )}
                </div>
            </div>

            <div className="deposit-retention__about">
                <div className="deposit-retention__comments">
                    <div className="deposit-retention__label">
                        <FormattedMessage id="TABLE.COMMENT" />:
                    </div>

                    <p className="deposit-retention__descr">
                        { retentionData.data?.name }
                    </p>
                </div>
            </div>
        </div>
    )
}