import CurrencyIcon from "@components/CurrencyIcon";
import { FormattedMessage, useIntl } from "react-intl";

export interface MyActivesCheckProps {
  currency: string;
  currencyDesc: string;
  currencyIcon: string;
  balance: number;
  swap: boolean;
  sell: boolean;
  onWithdraw: () => void;
  onSwap: () => void;
  onSell: () => void;
}

export function MyActivesCheck(props: MyActivesCheckProps) {
  const intl = useIntl();

  return (
    <li className="my-actives__check">
      <div className="my-actives__check-info">
        <div className="my-actives__check-currency">

          <CurrencyIcon
            size={30}
            currency={props.currencyIcon}
          />

          <div className="my-actives__check-name">
            <h4 className="my-actives__check-title">{props.currency}</h4>

            <p className="my-actives__check-detail">{props.currencyDesc}</p>
          </div>
        </div>

        <div className="my-actives__check-sums">
          <h4 className="my-actives__check-title">{props.balance}</h4>

          <p className="my-actives__check-detail">{props.balance}</p>
        </div>
      </div>

      <div className="my-actives__check-operations">
        <div
          className="my-actives__check-operation ___not_implemented"
          onClick={() => {
            alert(
              intl.formatMessage({ id: "NOT_IMPLEMENTED" })
            ); /*props.onWithdraw*/
          }}
        >
          <img
            className="my-actives__check-operation-img"
            src="/img/icons/conclusion.svg"
            alt={intl.formatMessage({ id: "WITHDRAWAL" })}
          />

          <p className="my-actives__check-operation-descr">
            <FormattedMessage id="WITHDRAWAL" />
          </p>
        </div>

        {props.sell && (
          <div
            className="my-actives__check-operation ___not_implemented"
            onClick={() => {
              alert(
                intl.formatMessage({ id: "NOT_IMPLEMENTED" })
              ); /*props.onSell*/
            }}
          >
            <img
              className="my-actives__check-operation-img"
              src="/img/icons/sell.svg"
              alt={intl.formatMessage({ id: "SELL" })}
            />

            <p className="my-actives__check-operation-descr">
              <FormattedMessage id="SELL" />
            </p>
          </div>
        )}

        {props.swap && (
          <div
            className="my-actives__check-operation ___not_implemented"
            onClick={() => {
              alert(
                intl.formatMessage({ id: "NOT_IMPLEMENTED" })
              ); /*props.onSwap*/
            }}
          >
            <img
              className="my-actives__check-operation-img"
              src="/img/icons/swap.svg"
              alt={intl.formatMessage({ id: "SWAP" })}
            />

            <p className="my-actives__check-operation-descr">
              <FormattedMessage id="SWAP" />
            </p>
          </div>
        )}
      </div>
    </li>
  );
}
