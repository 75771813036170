import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ProfileForm } from "./ProfileForm";
import { useEffect, useState } from "react";
import {
  getAccountProfile,
  setAccountProfile,
} from "@store/accountProfile/slice";
import { getConfigSettings } from "@store/accountConfigSettings/slice";
import { AuthenticatedService, ProfileData } from "@awex-api";
import toast from "react-hot-toast";
import userCurrencies from "@constants/userCurrencies";
import { FormattedMessage, useIntl } from "react-intl";

export type UserCurrency = {
  currency: string;
  disabled?: boolean;
};

export function ProfileFormContainer() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.accountProfile.data);
  const profileLoading = useAppSelector(
    (state) => state.accountProfile.loadingStatus === "pending"
  );
  const countries = useAppSelector(
    (state) => state.accountConfigSettings.data?.countries
  );
  const currenciesLoading = useAppSelector(
    (state) => state.accountConfigSettings.loading
  );
  // const [userCurrencies, setUserCurrencies] = useState<UserCurrency[]>()

  const intl = useIntl();

  useEffect(() => {
    dispatch(getAccountProfile());
    dispatch(getConfigSettings());
    // getProfileCurrencies()
  }, []);

  // function getProfileCurrencies() {
  //   AuthenticatedService.accountProfileCurrencies()
  //   .then((response) => {
  //     if(!response) {
  //       setUserCurrencies([])
  //       return
  //     }
  //     const newCurrencies: UserCurrency[] = response.currencies.map((currency: string) => {
  //       return {
  //         currency
  //       }
  //     })
  //     setUserCurrencies(newCurrencies)
  //   })
  //   .catch((error: string) => {
  //     console.error(error)
  //   })
  // }

  const handleUpdateProfile = (opts: ProfileData) => {
    const requests: ProfileData = {
      name: opts.name,
      email: opts.email,
      telegram: opts.telegram,
      displayCurrency: opts.displayCurrency,
    };
    dispatch(setAccountProfile(requests))
      .unwrap()
      .then(() => {
        toast.success(<FormattedMessage id="SUCCESS.PROFILE_UPDATED" />);
      })
      .catch((error) => {
        console.error(error);
        toast.error(<FormattedMessage id="ERROR.FAILED_TO_UPDATE_PROFILE" />);
      });
  };

  return (
    <ProfileForm
      profile={profile}
      loading={profileLoading || currenciesLoading}
      countries={countries}
      currencies={userCurrencies}
      onUpdateProfile={handleUpdateProfile}
    />
  );
}
