

export function useFormatCurrency(value: number): [string, string] {
    const fixed = value.toFixed(2)
    let integerPart = fixed.slice(0, -3)
  
    if (integerPart.length > 3) {
      integerPart = integerPart.replace(/\d(?=(\d{3})+$)/g, "$&.")
    }
    const fractionalPart = fixed.slice(-2)
    return [integerPart, fractionalPart]
}