import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { RetentionItem } from "../../DepositRetentionPage"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"


interface DepositWithholdFormProps {
    retentionData: RetentionItem
    onSubmit: (formData: DepositWithholdFormData) => void
    isDisabled: boolean
    isRepeatedRequest: boolean
}

export type DepositWithholdFormData = {
    amount: number
    document?: FileList
    comment?: string
}


const MAX_FILE_SIZE = 10000000;
const VALID_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'tiff', 'webp', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'avi', 'mp4', 'mov', 'wmv', 'flv', 'f4v', 'swf', 'mkv', 'webm']


export default function DepositWithholdForm(props: DepositWithholdFormProps) {
    const { retentionData, onSubmit, isDisabled, isRepeatedRequest } = props
    const navigate = useNavigate()
    const intl = useIntl()
    // const {} = useForm()

    const depositWithholdFormValidator = yup.object({
        amount: yup
            .number()
            .required(intl.formatMessage({ id: "FORM.REQUIRED" }))
            .positive()
            .max(Number(retentionData.depositAmount), intl.formatMessage({ id: "ERROR.MAX_SIZE_FOR_DEPOSIT_WITHHOLD" })),
        document: yup
            .mixed<FileList>()
            .test("is-added", intl.formatMessage({ id: "FORM.REQUIRED" }),
                (value: any) => value.length > 0
            )
            .test("is-valid-type", intl.formatMessage({ id: "ERROR.FILE_TYPE" }),
                (value: any) =>  isValidFileType(value)
            )
            .test("is-valid-size", intl.formatMessage({ id: "ERROR.MAX_SIZE_FOR_FILE" }),
                (value: any) => isValidFileSize(value)
            ),
        comment: yup.string()
    })

    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<DepositWithholdFormData>({
        resolver: yupResolver(depositWithholdFormValidator)
    })


    useEffect(() => {
        setValuesForm()
    }, [retentionData])


    const handleDepositWithholdFormSubmit = handleSubmit((formData) => {
        onSubmit(formData)
    })

    function setValuesForm() {
        if(!retentionData || retentionData.status !== "waitMerchant") return

        retentionData.deposit?.withholdAmount && setValue("amount", retentionData.deposit?.withholdAmount)
        retentionData.deposit?.withholdComment && setValue("comment", retentionData.deposit?.withholdComment)
    }

    function isValidFileType(value: FileList): boolean {
        const valueArray = Array.from(value)
        let status = true

        valueArray.forEach((file) => {
            const fileType = file.name.split('.').pop()
            if(!fileType) { 
                status = false
                return
            }
            if(VALID_FILE_EXTENSIONS.indexOf(fileType.toLowerCase()) < 0) {
                status = false
                return
            }
        })
        return status
    }

    function isValidFileSize(value: FileList): boolean {
        const valueArray = Array.from(value)
        let rezult = true

        valueArray.forEach((file) => {
            if(file.size > MAX_FILE_SIZE) rezult = false
        })
        return rezult
    }


    return (
        <form className="deposit-retention__checked-moment"
            onSubmit={handleDepositWithholdFormSubmit}
        >
            <div className="deposit-retention__groups">
                <div className="about-deposit__generation-select invoice__generation-select">
                    <div className="about-deposit__generation-selected about-deposit__generation-selected--not-reverse">
                        <div className="about-deposit__generation-info">
                            <h5 className="about-deposit__generation-title">
                                <FormattedMessage id="WITHHOLDING_AMOUNT" />:
                            </h5>

                            <input className="about-deposit__generation-input"
                                type="number"
                                placeholder={intl.formatMessage({ id: "WRITE_AMOUNT" })}
                                {...register("amount")}
                                disabled={isRepeatedRequest}
                            />
                            {errors.amount?.message && (
                                <div className="project-error">
                                    {errors.amount.message}
                                </div>
                            )}
                        </div>

                        <div className="about-deposit__generation-currency">
                            <div className="about-deposit__generation-curr">USDT</div>
                        </div>
                    </div>
                </div>

                <div className={`deposit-retention__file ${ isRepeatedRequest ? "active" : "" }`}>
                    <input className="deposit-retention__file-input" id="fileInput"
                        type="file"
                        multiple
                        accept={`.${VALID_FILE_EXTENSIONS.join(', .')}`}
                        {...register("document")}
                    />

                    <label className="deposit-retention__file-label" htmlFor="fileInput">
                        <FormattedMessage id="DOCUMENTS_FOR_DEPOSIT_RETENTION" />
                        <p className="deposit-retention__file-text">
                            <FormattedMessage id="ATTACH_DOCUMENTS" />
                        </p>
                        <img className="deposit-retention__file-img" src="/img/icons/file.svg" alt=""/>
                    </label>
                    
                    {errors.document?.message && (
                        <div className="project-error">
                            {errors.document.message.toString()}
                        </div>
                    )}
                </div>
            </div>

            <div className="invoice-project__group project-group invoice__group-textarea">
                <label className="invoice-project__label project-label" htmlFor="#">
                    <FormattedMessage id="COMMENT_FOR_ADMIN" />:
                </label>

                <textarea className="invoice-project__textarea project-textarea"
                    placeholder={intl.formatMessage({ id: "MESSAGE_FOR_ADMIN" })}
                    {...register("comment")}
                    disabled={isRepeatedRequest}
                ></textarea>
                    
                {errors.comment?.message && (
                    <div className="project-error">
                        {errors.comment.message}
                    </div>
                )}
            </div>

            { !!retentionData?.deposit?.info && (
                <div className="deposit-retention__groups">
                    <p>
                        <strong><FormattedMessage id="ADMINISTRATOR_COMMENT" />: </strong>
                        { retentionData?.deposit?.info }</p>
                </div>
            )}
            
            <div className="deposit-retention__item-btns">
                <button type="button" className="deposit-retention__btn third-btn"
                    onClick={() => navigate(-1)}
                >
                    <FormattedMessage id="BACK" />
                </button>
                <button type="submit" className="deposit-retention__btn second-btn"
                    disabled={isDisabled}
                >
                    <FormattedMessage id="CONFIRM" />
                </button>
            </div>
        </form>
    )
}