import { useEffect, useState } from "react";
import { AuthorizedService } from "@awex-api";
import toast from "react-hot-toast";
import StatsFilters from "./StatsFilters";
import Tabs from "@components/Tabs";
import StatsChart from "./StatsChart";
import StatsFooter from "./StatsFooter";
import { FormattedMessage, useIntl } from "react-intl";


export type StatsFiltersType = {
  projectId?: string;
  startTime?: number;
  endTime?: number;
  pointsNumber?: string;
};

type ChartData = {
  time: number;
  value: number;
};

export type StatsDataObject = {
  chartData: ChartData[];
  paidSum: number;
  createdNumber: number;
  paidNumber?: number;
  partialPaidNumber?: number;
  returnedNumber?: number;
  returnedSum?: number;
  withheldNumber?: number;
  withheldSum?: number;
  createdNumber24Percents?: string,
  paidNumber24Percents?: string,
  paidSum24Percents?: string,
  partialPaidNumber24Percents?: string,
  returnedNumber24Percents?: string,
  returnedSum24Percents?: string,
  withheldNumber24Percents?: string,
  withheldSum24Percents?: string
};

type TabElems = "orderData" | "depositData";

interface AllData {
  orderData: StatsDataObject;
  depositData: StatsDataObject;
}


const tatsFiltersDefault: StatsFiltersType = {
  projectId: undefined,
  startTime: undefined,
  endTime: undefined,
  pointsNumber: undefined,
};


export default function StatsPage() {
  const [statsFilters, setStatsFilters] = useState<StatsFiltersType>(tatsFiltersDefault);
  const [data, setData] = useState<AllData | null>(null);
  const intl = useIntl();
  const tabsItems: Array<{ value: TabElems; label: string }> = [
    {
      value: "orderData",
      label: intl.formatMessage({ id: "DASHBOARD.SIDEBAR.MOBILE.BILLS" }),
    },
    { value: "depositData", label: intl.formatMessage({ id: "DEPOSITS" }) },
  ];
  const [showTab, setShowTab] = useState<TabElems>(tabsItems[0].value);
  const [displayedData, setDisplayedData] = useState<StatsDataObject | null>(null);


  useEffect(() => {
    getData();
  }, [statsFilters]);

  useEffect(() => {
    calcDisplayedData();
  }, [showTab, data]);


  function getData(): void {
    AuthorizedService.accountStatistics(statsFilters)
      .then((response) => {
        if (!response) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }
        setData(response);
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.SERVER_ERROR" }));
      });
  }

  function onSelectTab(item: number) {
    setShowTab(tabsItems[item].value);
  }

  function calcDisplayedData() {
    if (!data) {
      setDisplayedData(null);
      return;
    }
    setDisplayedData(data[showTab]);
  }

  function setFilters(newFilter: StatsFiltersType): void {
    const newStatsFilters = {
      ...statsFilters,
      ...newFilter,
    };
    setStatsFilters(newStatsFilters);
  }


  return (
    <div className="wrapper">
      <section className="stats">
        <div className="deposits__header deposits__header--mob-column">
          <h1 className="deposits__title main-title">
            <FormattedMessage id="STATISTIC" />
          </h1>
          <StatsFilters setFilters={setFilters} />
        </div>

        <div className="stats__inner">
          <div className="stats__header">
            <div className="stats__selects-wrapper">
              <Tabs items={tabsItems} onSelect={onSelectTab} />

              <StatsChart
                data={displayedData ? displayedData.chartData : null}
              />
            </div>
          </div>

          <StatsFooter data={displayedData} />
        </div>
      </section>
    </div>
  );
}
