import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { RetentionItem } from "../DepositRetentionPage"
import DepositWithholdInfo from "./DepositWithholdInfo"
import DepositWithholdForm, { DepositWithholdFormData } from "./DepositWithholdForm"
import { AuthorizedService } from "@awex-api"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import toast from "react-hot-toast"


export default function DepositWithholdPage() {
    const [retentionData, setRetentionData] = useState<RetentionItem | null>(null)
    const [inProcess, setInProcess] = useState<boolean>(false)
    const [isRepeatedRequest, setIsRepeatedRequest] = useState<boolean>(false)
    const location = useLocation()
    const intl = useIntl()
    const navigate = useNavigate()

    
    useEffect(() => {
        getRetentionData()
    }, [location])

    useEffect(() => {
        checkForReRequest()
    }, [retentionData])


    function getRetentionData() {
        if (location.state && "retentionItem" in location.state) {
            setRetentionData(location.state.retentionItem)
        }
    }

    function checkForReRequest() {
        if(retentionData?.status === "waitMerchant" && retentionData?.deposit?.info) {
            setIsRepeatedRequest(true)
        }
    }

    async function handleDepositWithholdFormSubmit(data: DepositWithholdFormData): Promise<void> {
        if(inProcess) return
        
        if(!retentionData || retentionData.id === undefined) return
        setInProcess(true)
        const rezultUploadFiles = await withholdRequestDocumentsUpload(data.document)

        if(!rezultUploadFiles) {
            console.log('Error')
            setInProcess(false)
            return
        }

        const rezult = await withholdRequest(retentionData.id.toString(), data.amount, data.comment)
        setInProcess(false)
        
        if(!rezult) {
            console.log('Error')
            return
        }

        setTimeout(() => {
            navigate(-1)
        }, 2000)
    }

    async function withholdRequestDocumentsUpload(files: FileList | undefined) {
        if(!files || !retentionData?.id) return
        const formData = new FormData()
        const filesArray = Array.from(files)
        filesArray.forEach((file) => {
            const fileObject = new Blob([file], { type: file.type })
            formData.append("upload", fileObject, file.name)
        })

        const uploadData = {
          upload: formData.getAll("upload") as Blob[],
        }

        try {
            let rezult = await AuthorizedService.withholdRequestUpload(retentionData?.id?.toString(), uploadData)
            if(!rezult) {
                toast.error(intl.formatMessage({ id: "ERROR.UPLOAD_FILE" }))
                return false
            }
            console.log('Files upload sucess')
            return rezult
        } catch(error) {
            console.error(error)
            toast.error(intl.formatMessage({ id: "ERROR.UPLOAD_FILE" }))
            return false
        }
    }

    async function withholdRequest(id: string, withholdAmount:number, comment: string | undefined) {
        try {
            let rezult

            if(!isRepeatedRequest) {
                rezult = await AuthorizedService.createWithholdRequest(id, {
                    withholdAmount: withholdAmount,
                    withholdComment: comment
                })
            } else {
                rezult = await AuthorizedService.createWithholdReRequest(id)
            }

            if(!rezult) {
                toast.error(intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" }))
                return false
            }
            toast.success(intl.formatMessage({ id: "SUCCESS.APPLICATION_SENT" }))
            return rezult
        } catch(error) {
            console.error(error)
            toast.error(intl.formatMessage({ id: "ERROR.FAIL_HAPPENED" }))
            return false
        }
    }


    return (
        <div className="wrapper">
            <section className="deposit-retention">
                <div className="deposits__header deposits__header--start">
                    <h1 className="deposits__title main-title">
                        <FormattedMessage id="DEPOSIT_RETENTION" />
                    </h1>
                </div>

                { !!retentionData && (
                    <div className="deposit-retention__inner">
                        <DepositWithholdInfo
                            retentionData={retentionData}
                        />

                        <DepositWithholdForm
                            retentionData={retentionData}
                            onSubmit={handleDepositWithholdFormSubmit}
                            isDisabled={inProcess}
                            isRepeatedRequest={isRepeatedRequest}
                        />
                    </div>
                )}
            </section>
        </div>
    )
}