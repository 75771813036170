import { AuthenticatedService } from "@awex-api";
import { Pagination } from "@components/Pagination";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

// type SessionData = {
//   page: number
//   pages: number
//   list: [
//     {
//       id: number
//       current: boolean
//       data: {
//         browser: {
//           version: string
//           type: string
//           name: string
//           os: string
//         }
//         ip: string
//         geo: {}
//       }
//     }
//   ]
// }

type Session = {
  id: number;
  current: boolean;
  data: {
    browser: Record<string, any>;
    ip: string;
    geo: Record<string, any>;
  };
};

type SessionData = {
  page: number;
  pages: number;
  list: Array<Session>;
};

// {
//   "page": 1,
//   "pages": 11,
//   "list": [
//     {
//       "id": 540,
//       "data": {
//         "browser": {
//           "version": "18.17.0",
//           "type": "node",
//           "name": "node",
//           "os": "linux"
//         },
//         "ip": "212.113.117.131",
//         "geo": {
//           "ip": "212.113.117.131",
//           "continent_code": "EU",
//           "continent_name": "Europe",
//           "country_code2": "RU",
//           "country_code3": "RUS",
//           "country_name": "Russia",
//           "country_name_official": "Russian Federation",
//           "country_capital": "Moscow",
//           "state_prov": "Northwestern Federal District",
//           "state_code": "VE-MN",
//           "district": "",
//           "city": "Saint Petersburg",
//           "zipcode": "199162",
//           "latitude": "59.93319",
//           "longitude": "30.30609",
//           "is_eu": false,
//           "calling_code": "+7",
//           "country_tld": ".ru",
//           "languages": "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,av,sah,nog",
//           "country_flag": "https://ipgeolocation.io/static/flags/ru_64.png",
//           "geoname_id": "10129130",
//           "isp": "TimeWeb Ltd.",
//           "connection_type": "",
//           "organization": "TimeWeb Ltd.",
//           "currency": {
//             "code": "RUB",
//             "name": "Russian Ruble",
//             "symbol": "₽"
//           },
//           "time_zone": {
//             "name": "Europe/Moscow",
//             "offset": 3,
//             "offset_with_dst": 3,
//             "current_time": "2024-01-15 15:50:04.072+0300",
//             "current_time_unix": 1705323004.072,
//             "is_dst": false,
//             "dst_savings": 0
//           }
//         }
//       },
//       "current": false
//     },
//     {
//       "id": 545,
//       "data": {
//         "browser": {
//           "version": "18.17.0",
//           "type": "node",
//           "name": "node",
//           "os": "linux"
//         },
//         "ip": "212.113.117.131",
//         "geo": {
//           "ip": "212.113.117.131",
//           "continent_code": "EU",
//           "continent_name": "Europe",
//           "country_code2": "RU",
//           "country_code3": "RUS",
//           "country_name": "Russia",
//           "country_name_official": "Russian Federation",
//           "country_capital": "Moscow",
//           "state_prov": "Northwestern Federal District",
//           "state_code": "VE-MN",
//           "district": "",
//           "city": "Saint Petersburg",
//           "zipcode": "199162",
//           "latitude": "59.93319",
//           "longitude": "30.30609",
//           "is_eu": false,
//           "calling_code": "+7",
//           "country_tld": ".ru",
//           "languages": "ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,av,sah,nog",
//           "country_flag": "https://ipgeolocation.io/static/flags/ru_64.png",
//           "geoname_id": "10129130",
//           "isp": "TimeWeb Ltd.",
//           "connection_type": "",
//           "organization": "TimeWeb Ltd.",
//           "currency": {
//             "code": "RUB",
//             "name": "Russian Ruble",
//             "symbol": "₽"
//           },
//           "time_zone": {
//             "name": "Europe/Moscow",
//             "offset": 3,
//             "offset_with_dst": 3,
//             "current_time": "2024-01-15 16:29:20.763+0300",
//             "current_time_unix": 1705325360.763,
//             "is_dst": false,
//             "dst_savings": 0
//           }
//         }
//       },
//       "current": false
//     }
//   ]
// }

export function SessionList() {
  const [sessionData, setSessionData] = useState<SessionData | null>(null);

  const intl = useIntl();

  useEffect(() => {
    getSessionData();
  }, []);

  function getSessionData(page: number = 1): void {
    AuthenticatedService.sessionList(page.toString())
      .then((response) => {
        if (!response) {
          setSessionData(null);
          return;
        }
        setSessionData(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function goToPageSessionData(page: number): void {
    getSessionData(page);
  }

  const handleLogoutClick = () => {
    AuthenticatedService.sessionDeleteAll()
      .then((response) => {
        if (!response) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }
        toast.success(intl.formatMessage({ id: "SUCCESS.LOGOUT" }));
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }));
      })
      .finally(() => {
        getSessionData();
      });
  };

  return (
    <div className="settings-profile__select">
      <div className="settings-security__header">
        <h3 className="settings-security__title">
          <FormattedMessage id="LATEST_ENTRIES" />
        </h3>

        <button
          type="button"
          className="settings-security__header-btn third-btn"
          onClick={handleLogoutClick}
        >
          <FormattedMessage id="LOGOUT_FROM_ALL_DEVICES" />
        </button>
      </div>

      {sessionData && (
        <>
          <div className="settings-security__middle">
            <div className="settings-security__enters">
              {sessionData &&
                sessionData.list.map((sessionItem) => {
                  const { browser, geo } = sessionItem.data;
                  return (
                    <div
                      className={`settings-security__enter ${
                        sessionItem.current && "settings-security__enter--green"
                      }`}
                      key={sessionItem.id}
                    >
                      <img src="/img/icons/globe.svg" alt="globe" />

                      <div className="settings-security__enter-info">
                        <h4 className="settings-security__enter-label">
                          {`${browser?.os}
                          ${browser?.name}
                          ${browser?.version}
                        `}
                        </h4>

                        <p className="settings-security__enter-text">
                          {`
                          ${geo.isp}
                          ${geo.country_name}
                          ${geo.city}
                          (${geo.ip})
                        `}
                          <br />
                          <FormattedMessage id="ACTIVITY" />{" "}
                          {geo.time_zone.current_time}
                          <br />
                          {sessionItem.current &&
                            intl.formatMessage({ id: "CURRENT_SESSION" })}
                        </p>
                      </div>
                    </div>
                  );
                })}

              {/* 
                <div className="settings-security__enter settings-security__enter--green">
                  <img src="/img/icons/globe.svg" alt="globe" />

                  <div className="settings-security__enter-info">
                    <h4 className="settings-security__enter-label">
                      Windows 10.0, Google Chrome 105.0.0.0
                    </h4>

                    <p className="settings-security__enter-text">
                      WEB, TEST-RU, Russia, Moscow (213.134.209.99)
                      <br />
                      активность 8 ноя 2022 20:21:42 +4 по 8 ноя 2022 20:21:44 +4
                      <br />
                      активная сессия (текущая)
                    </p>
                  </div>
                </div>

                <div className="settings-security__enter">
                  <img src="/img/icons/mobile.svg" alt="mobile" />

                  <div className="settings-security__enter-info">
                    <h4 className="settings-security__enter-label">
                      Mobile App AWEX, 202.345.0.0.0
                    </h4>

                    <p className="settings-security__enter-text">
                      WEB, TEST-RU, Russia, Moscow (213.134.209.99)
                      <br />
                      активность 8 ноя 2022 20:21:42 +4 по 8 ноя 2022 20:21:44 +4
                      <br />
                      активная сессия (текущая)
                    </p>
                  </div>
                </div>
              */}
            </div>
          </div>

          {sessionData.pages > 1 && (
            <Pagination
              currentPage={sessionData.page}
              pages={sessionData.pages}
              goToPage={goToPageSessionData}
            />
          )}
        </>
      )}
    </div>
  );
}
