import { useId } from "react";
import * as yup from "yup";
import QRCode from "react-qr-code";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

interface GoogleModalProps {
  isOpen: boolean;
  uri: string;
  close: () => void;
  confirm: (otpCode: string) => void;
}

interface FormData {
  otpCode: string;
}

export default function GoogleModal(props: GoogleModalProps) {
  const { isOpen, uri, close, confirm } = props;
  const inputId = useId();

  const intl = useIntl();

  const otpCodeFormValidator = yup.object({
    otpCode: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(otpCodeFormValidator),
  });

  const connectGoogleAuthenticator = handleSubmit((formData) => {
    confirm(formData.otpCode);
  });

  return (
    <div
      className={`modal ${isOpen && "show"}`}
      id="googlemodal"
      onClick={close}
    >
      <form
        className="modal-content"
        onClick={(ev) => ev.stopPropagation()}
        onSubmit={connectGoogleAuthenticator}
      >
        <div className="modal-content__header">
          <button className="close-modal-btn" type="button" onClick={close}>
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <p className="modal-content__text">
            <FormattedMessage id="SCAN_GOOGLE_2FA_QR" />
          </p>

          <div className="modal-content__qr">
            <QRCode className="modal-content__qr-img" value={uri} size={156} />
          </div>

          <div className="settings-security__middle">
            <div className={"my-projects__group project-group"}>
              <label
                className="my-projects__label project-label"
                htmlFor={inputId}
              >
                <FormattedMessage id="ENTER_CODE_FROM_APP" />
              </label>

              <input
                className="my-projects__input project-input"
                type="text"
                id={inputId}
                placeholder={intl.formatMessage({
                  id: "FORM.ENTER_CODE_FROM_APP.PLACEHOLDER",
                })}
                {...register("otpCode")}
              />
              {errors.otpCode?.message && (
                <div className="project-error">{errors.otpCode.message}</div>
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="modal-content__btn second-btn">
          <FormattedMessage id="BIND" />
        </button>
      </form>
    </div>
  );
}
