const convertingСurrencies = [
    {
        currency: 'USDT',
        name: 'Tether',
        disabled: false,
    },
    // {
    //     currency: 'USDC',
    //     disabled: true,
    // },
    // {
    //     currency: 'RUB',
    //     disabled: true,
    // },
    // {
    //     currency: 'USD',
    //     disabled: true,
    // },
    // {
    //     currency: 'AED',
    //     disabled: true,
    // },
]

export default convertingСurrencies