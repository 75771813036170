import { FormattedMessage } from "react-intl";

type ReferralRuleModalProps = {
  isOpen: boolean;
  setStatus: (value: boolean) => void;
};

export function ReferralRuleModal(props: ReferralRuleModalProps) {
  const { isOpen, setStatus } = props;

  function close(): void {
    setStatus(false);
  }

  function stop(event: any) {
    event.stopPropagation();
  }

  return (
    <div
      className={`modal modal-cash-to-office modal-link-generated modal-genation-links${
        isOpen ? " show" : ""
      }`}
      onClick={close}
    >
      <form action="#" className="modal-content" onClick={stop}>
        <div className="modal-content__header">
          <h4 className="modal-content__title">
            <FormattedMessage id="REFERRAL_RULES" />
          </h4>

          <button className="close-modal-btn" onClick={close}>
            <img src="/img/icons/close-modal.svg" alt="" />
          </button>
        </div>

        <div className="modal-content__main">
          <div className="actives-action__withdrawal-group invoice-project__group-select">
            <div className="scrollable-container">
              <FormattedMessage id="REFERRAL_RULES.TEXT" />
            </div>
          </div>
        </div>

        <button
          type="button"
          className="modal-content__btn second-btn"
          onClick={close}
        >
          <FormattedMessage id="CLOSE" />
        </button>
      </form>
    </div>
  );
}
