import { useEffect, useState } from "react"


interface ICurrencyIconProps {
    currency: string
    size: 25 | 30
}


export default function CurrencyIcon({ currency, size }: ICurrencyIconProps) {
    const [imageClass, setImageClass] = useState<string>('')
    const [isError, setIsError] = useState<boolean>(false)

    const currencyUppercase = currency ? currency.toUpperCase() : ''
    const coinName = isError ? '_default_coin' : currencyUppercase
    const imageUrl = `/img/currencies/${coinName}@${size}`
    const imageUrl2x = `/img/currencies/${coinName}@${size * 2}`


    useEffect(() => {
        switch(size) {
            case 25:
                setImageClass("crypto__item-pic")
                break
            case 30:
                setImageClass("my-actives__check-pic")
                break
            default:
                setImageClass("my-actives__check-pic")
        }
    }, [size])


    function onloadError() {
        setIsError(true)
    }
 

    return (
        <picture>
            <source srcSet={`${imageUrl}.webp, ${imageUrl2x}.webp 2x`} type="image/webp" />
            <source srcSet={`${imageUrl}.png, ${imageUrl2x}.png 2x`} type="image/png" />
            <img
                className={`${imageClass} ${isError ? 'currency-icon-error' : ''}`}
                src={`${imageUrl}.png`}
                alt={currencyUppercase}
                onError={onloadError}
            />
        </picture>
    )
}