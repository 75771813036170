import React from "react";
import { FormattedMessage } from "react-intl";

interface ConfirmationModalProps {
  isOpen: boolean;
  text: string;
  data: any;
  answer: (data: any, ansver: boolean) => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps
) => {
  const { isOpen, text, data, answer } = props;

  return (
    <div
      className={`modal modal-notifications modal-genation-links${
        isOpen ? " show" : ""
      }`}
    >
      <form action="#" className="modal-content">
        <div className="modal-content__header">
          <h4 className="modal-content__title">{text}</h4>
        </div>

        <div className="modal-content__btns">
          <button
            type="button"
            className="modal-content__btn third-btn"
            onClick={() => answer(data, false)}
          >
            <FormattedMessage id="CANCEL" />
          </button>
          <button
            type="button"
            className="modal-content__btn second-btn"
            onClick={() => answer(data, true)}
          >
            <FormattedMessage id="ACCEPT" />
          </button>
        </div>
      </form>
    </div>
  );
};
