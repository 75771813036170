import { useState } from "react";
import GmailModal from "./GmailModal";
import { OtpEnable } from ".";
import { FormattedMessage } from "react-intl";

interface GmailPrpos {
  otpEnable: (val: OtpEnable) => void;
  isGlobalProcess: boolean;
  status: boolean;
}

const _2FA_TYPE = "gmail";

export default function Gmail(props: GmailPrpos) {
  const { otpEnable, isGlobalProcess, status } = props;
  const [gmailModalOpen, setGmailModalOpen] = useState<boolean>(false);

  function gmailAuthHandlerClick(): void {
    if (isGlobalProcess) return;
    setGmailModalOpen(true);
  }

  function cancel(): void {
    setGmailModalOpen(false);
  }

  function confirm(otpCode: string): void {
    setGmailModalOpen(false);
    otpEnable({
      _2faType: _2FA_TYPE,
      otp: otpCode,
    });
  }

  return (
    <>
      {status && (
        <button className="green-btn" type="button" disabled>
          <FormattedMessage id="GMAIL" />
        </button>
      )}

      {!status && (
        <>
          <button
            className="settings-security__btn main-btn"
            type="button"
            disabled={isGlobalProcess}
            onClick={gmailAuthHandlerClick}
          >
            <FormattedMessage id="GMAIL" />
          </button>

          <GmailModal
            isOpen={gmailModalOpen}
            close={cancel}
            confirm={confirm}
          />
        </>
      )}
    </>
  );
}
