import { useEffect, useId, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldErrors, useForm } from "react-hook-form";
import classNames from "classnames";
import { CommonService } from "@awex-api";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

export interface BlockProfileModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

export type BlockProfileModalFormData = {
  login: string;
  secretWrod: string;
};

const DEFAULT_FORM_DATA: BlockProfileModalFormData = {
  login: "",
  secretWrod: "",
};

export function BlockProfileModal(props: BlockProfileModalProps) {
  const [inProcess, setInProcess] = useState<boolean>(false);
  const loginID = useId();
  const wordID = useId();

  const intl = useIntl();

  const blockProfileFormSchema = yup.object({
    login: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
    secretWrod: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<BlockProfileModalFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(blockProfileFormSchema),
  });

  useEffect(() => {
    reset();
  }, [props.open]);

  const handleBlockProfileFormSubmit = handleSubmit((formData) => {
    if (inProcess) return;
    setInProcess(true);
    CommonService.accountProfileBlock(formData.login, formData.secretWrod)
      .then((response) => {
        if (!response) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }
        toast.success(intl.formatMessage({ id: "SUCCESS.PROFILE_BLOCKED" }));
        reset();
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.INCORRECT_DATA" }));
        setError("login", {
          message: intl.formatMessage({ id: "ERROR.INCORRECT_DATA" }),
        });
        setError("secretWrod", {
          message: intl.formatMessage({ id: "ERROR.INCORRECT_DATA" }),
        });
      })
      .finally(() => {
        setInProcess(false);
      });
  });

  return (
    <div className={classNames("modal modal-enter", { show: props.open })}>
      <form className="modal-content" onSubmit={handleBlockProfileFormSubmit}>
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />
            <h2>
              <FormattedMessage id="BLOCK_PROFILE.TITLE" />
            </h2>
          </div>

          <button
            type="button"
            className="close-modal-btn"
            onClick={props.onClose}
          >
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <p className="modal-content__text">
            <FormattedMessage id="BLOCK_PROFILE.DESCRIPTION" />
          </p>

          <p className="modal-content__text">
            <FormattedMessage id="BLOCK_PROFILE.DESCRIPTION_DETAIL" />
          </p>

          <div className="my-projects__group project-group">
            <label
              className="my-projects__label project-label"
              htmlFor={loginID}
            >
              <FormattedMessage id="LOGIN" />
            </label>
            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "SIGN_IN.FORM.LOGIN.PLACEHOLDER",
              })}
              id={loginID}
              {...register("login")}
            />
            {renderFieldError(errors, "login")}
          </div>

          <div className="my-projects__group project-group">
            <label
              className="my-projects__label project-label"
              htmlFor={wordID}
            >
              <FormattedMessage id="BLOCK_PROFILE.SECRET.LABEL" />
            </label>
            <input
              className="my-projects__input project-input"
              type="text"
              placeholder={intl.formatMessage({
                id: "BLOCK_PROFILE.SECRET.PLACEHOLDER",
              })}
              id={wordID}
              {...register("secretWrod")}
            />
            {renderFieldError(errors, "secretWrod")}
          </div>

          {errors.root && errors.root.message && (
            <div className="modal-content__error">{errors.root.message}</div>
          )}
        </div>

        <button
          type="submit"
          className="modal-content__btn second-btn"
          disabled={props.loading || inProcess}
        >
          <FormattedMessage id="BLOCK_PROFILE" />
        </button>
      </form>
    </div>
  );
}

function renderFieldError(
  errors: FieldErrors<BlockProfileModalFormData>,
  field: keyof BlockProfileModalFormData
) {
  const error = errors[field];
  if (!error || !error.message) {
    return null;
  }
  return <div className="project-error">{error.message}</div>;
}
