import { useState } from "react";
import GmailFormMail from "./GmailFormMail";
import GmailFormOptCode from "./GmailFormOptCode";

interface GoogleModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: (otpCode: string) => void;
}

export default function GmailModal(props: GoogleModalProps) {
  const { isOpen, close, confirm } = props;
  const [emailStatus, setEmailStatus] = useState<boolean>(false);

  return (
    <div
      className={`modal modal-notifications ${isOpen && "show"}`}
      id="gmailmodal"
      onClick={close}
    >
      {!emailStatus && (
        <GmailFormMail
          close={close}
          isSentEmail={() => setEmailStatus(true)}
          isFocus={isOpen}
        />
      )}

      {emailStatus && (
        <GmailFormOptCode
          close={close}
          confirm={confirm}
          isFocus={emailStatus}
        />
      )}
    </div>
  );
}
