import { InvoiceLight } from "@components/InvoiceLight";
import { useIntl } from "react-intl";

export interface GenerationLinksModalProps {
  open: boolean;
  onClose: () => void;
}

export function GenerationLinksModal(props: GenerationLinksModalProps) {
  const intl = useIntl();

  const closeModal = () => {
    props.onClose();
  };

  return (
    <div
      className={`modal modal-genation-links ${props.open && "show"}`}
      onClick={closeModal}
    >
      <div
        className="modal-content"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        <div className="modal-content__header">
          <button className="close-modal-btn" onClick={closeModal}>
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <InvoiceLight isMobile={true} onSubmit={closeModal} />
      </div>
    </div>
  );
}
