import { AuthorizedService } from "@awex-api";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AdminApplicationAreaNavbar from "../../../layouts/AdminAreaLayout/AdminApplicationAreaLayout/AdminApplicationAreaNavbar";
import AdminDepositList from "./AdminDepositList";
import { IAdminDeposit } from "src/generated/awex-api/models/AdminDepositList";
import { IAdminDepositItem } from "src/generated/awex-api/models/AdminDepositItem";

const DEFAULT_SEARCH = "";
const QUERY_PARAM_SEARCH = "search";

const AdminDepositProcessing: React.FC = () => {
  const [applications, setApplications] = React.useState<IAdminDepositItem[]>();
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [searchText, setSearchText] = useState(DEFAULT_SEARCH);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    submitTextFilter();
  };

  const submitTextFilter = () => {
    const normalizedSearchText = searchText.trim();

    if (searchParams.get(QUERY_PARAM_SEARCH) === normalizedSearchText) {
      return;
    }

    const nextSearchParams = new URLSearchParams(searchParams);
    if (normalizedSearchText.length === 0) {
      nextSearchParams.delete(QUERY_PARAM_SEARCH);
    } else {
      nextSearchParams.set(QUERY_PARAM_SEARCH, normalizedSearchText);
    }
    setSearchParams(nextSearchParams);
  };

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.currentTarget.value);
  };

  function changePage(page: number): void {
    setPage(page);
  }

  const submittedSearchText = useMemo(() => {
    const searchText = searchParams.get(QUERY_PARAM_SEARCH);
    return searchText === null ? DEFAULT_SEARCH : searchText;
  }, [searchParams]);

  useEffect(() => {
    setSearchText(submittedSearchText);
  }, []);

  useEffect(() => {
    if (searchInputFocused) {
      return;
    }
    submitTextFilter();
  }, [searchInputFocused]);

  useEffect(() => {
    const searchText = searchParams.get(QUERY_PARAM_SEARCH);
    AuthorizedService.administratorDepositsGet(page.toString())
    .then((res) => {
      setApplications(res.list!);
      setTotalPages(res.pages!);
      setPage(res.page!);
    });
  }, [page, totalPages]);

  return (
    <div className="admin-statistic admin-applications">
      <div className="admin-statistic__container">
        <form
          className="admin-applications__from"
          onSubmit={handleSearchFormSubmit}
        >
          <div className="admin-applications__search search-group">
            <input
              className="admin-applications__src search-input"
              type="search"
              name="search"
              placeholder="Поиск по ID/названию/ИНН/адресу"
              value={searchText}
              onChange={handleSearchInputChange}
              onFocus={() => setSearchInputFocused(true)}
              onBlur={() => setSearchInputFocused(false)}
            />
            <img
              className="admin-applications__search-img search-img"
              src="/img/icons/search.svg"
              alt="Поиск"
            />
            <button
              className={classNames(
                "search-apply-btn",
                searchInputFocused && "search-apply-btn--active"
              )}
              type="button"
            >
              Применить
            </button>
          </div>
        </form>
      </div>

      <AdminApplicationAreaNavbar />

      <div className="admin-applications__main">
        <AdminDepositList applications={applications!} />
      </div>
    </div>
  );
};

export default AdminDepositProcessing;
