import { useEffect, useId } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

interface GmailFormOptCodeProps {
  close: () => void;
  confirm: (otpCode: string) => void;
  isFocus: boolean;
}

interface GmailFormData {
  otpCode: string;
}

export default function GmailFormOptCode(props: GmailFormOptCodeProps) {
  const { close, confirm, isFocus } = props;
  const inputId = useId();
  const intl = useIntl();

  const otpCodeFormValidator = yup.object({
    otpCode: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<GmailFormData>({
    resolver: yupResolver(otpCodeFormValidator),
  });

  useEffect(() => {
    if (isFocus) {
      setFocus("otpCode");
    }
  }, [isFocus]);

  const confirmOtpCode = handleSubmit((formData) => {
    confirm(formData.otpCode);
    reset();
  });

  return (
    <form
      className="modal-content"
      onClick={(ev) => ev.stopPropagation()}
      onSubmit={confirmOtpCode}
    >
      <div className="modal-content__header">
        <button className="close-modal-btn" type="button" onClick={close}>
          <img
            src="/img/icons/close-modal.svg"
            alt={intl.formatMessage({ id: "ICON.X_MARK" })}
          />
        </button>
      </div>

      <div className="modal-content__main">
        <div className="settings-security__middle">
          <div className={"my-projects__group project-group"}>
            <label
              className="my-projects__label project-label"
              htmlFor={inputId}
            >
              <FormattedMessage id="FORM.CODE_FROM_GMAIL.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              id={inputId}
              placeholder={intl.formatMessage({
                id: "FORM.CODE_FROM_GMAIL.PLACEHOLDER",
              })}
              {...register("otpCode")}
            />

            {errors.otpCode?.message && (
              <div className="project-error">{errors.otpCode.message}</div>
            )}
          </div>
        </div>
      </div>

      <button type="submit" className="modal-content__btn second-btn">
        <FormattedMessage id="CONNECT_GMAIL" />
      </button>
    </form>
  );
}
