import { useEffect, useId } from "react";
import * as yup from "yup";
import { FieldErrors, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

export type RecoverError = { type: "USER_NOT_FOUND" | "GENERAL" };

export interface RecoverModalContentProps {
  open: boolean;
  loading: boolean;
  error?: RecoverError;
  onClose: () => void;
  onRecover: (opts: { email: string }) => void;
}

interface RecoverModalFormData {
  email: string;
}

const DEFAULT_FORM_DATA = {
  email: "",
};

export function RecoverModalContent(props: RecoverModalContentProps) {
  const emailId = useId();

  const intl = useIntl();

  const recoverFormSchema = yup.object({
    email: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" }))
      .email(intl.formatMessage({ id: "FORM.EMAIL_INVALID" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<RecoverModalFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(recoverFormSchema),
  });

  useEffect(() => {
    reset();
  }, [props.open]);

  useEffect(() => {
    if (props.error) {
      if (props.error.type === "USER_NOT_FOUND") {
        setError("root", {
          message: intl.formatMessage({ id: "ERROR.USER_NOT_FOUND" }),
        });
      } else {
        setError("root", {
          message: intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }),
        });
      }
    }
  }, [props.error]);

  const handleRecoverFormSumbit = handleSubmit((formData) => {
    props.onRecover({ email: formData.email });
  });

  return (
    <div className={classNames("modal modal-enter", { show: props.open })}>
      <form className="modal-content" onSubmit={handleRecoverFormSumbit}>
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />

            <h2>
              <FormattedMessage id="RESET_PASSWORD.TITLE" />
            </h2>
          </div>

          <button
            type="button"
            className="close-modal-btn"
            onClick={props.onClose}
          >
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <p className="modal-content__text">
            <FormattedMessage id="RESET_PASSWORD.SUBTITLE" />
          </p>

          <div className="my-projects__group project-group">
            <label
              className="my-projects__label project-label"
              htmlFor={emailId}
            >
              <FormattedMessage id="REGISTRATION.FORM.EMAIL.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              id={emailId}
              type="email"
              placeholder={intl.formatMessage({
                id: "REGISTRATION.FORM.EMAIL.PLACEHOLDER",
              })}
              disabled={props.loading}
              {...register("email")}
            />

            {renderFieldError(errors, "email")}
          </div>
          {errors.root && errors.root.message && (
            <div className="modal-content__error">{errors.root.message}</div>
          )}
        </div>

        <button
          type="submit"
          className="modal-content__btn second-btn"
          disabled={props.loading}
        >
          <FormattedMessage id="RESET_PASSWORD.FORM.BTN.SUBMIT" />
        </button>
      </form>
    </div>
  );
}

function renderFieldError(
  errors: FieldErrors<RecoverModalFormData>,
  field: keyof RecoverModalFormData
) {
  const error = errors[field];
  if (!error || !error.message) {
    return null;
  }
  return <div className="project-error">{error.message}</div>;
}
