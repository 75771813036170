import React, { useContext } from "react";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../../contexts/LanguageContext";

export interface LanguagesModalProps {
  open: boolean;
  loading: boolean;
  error?: string;
  onClose: () => void;
}

const LanguagesModal: React.FC<LanguagesModalProps> = (props) => {
  const context = useContext(LanguageContext);
  const intl = useIntl();

  const handleLanguageChange = (newLocale: string) => {
    context?.switchLanguage(newLocale);
    props.onClose();
  };

  return (
    <div className={classNames("modal modal-enter", { show: props.open })}>
      <div className="modal-content">
        <div className="modal-content__header">
          <div className="modal-content__header-logo">
            <img
              src="/img/icons/logo-mini.svg"
              alt={intl.formatMessage({ id: "LOGO.AWEX_LOGO.ALT" })}
            />

            <h2>
              <FormattedMessage id="LANGUAGE" />
            </h2>
          </div>

          <button
            type="button"
            className="close-modal-btn"
            onClick={props.onClose}
          >
            <img
              src="/img/icons/close-modal.svg"
              alt={intl.formatMessage({ id: "ICON.X_MARK" })}
            />
          </button>
        </div>

        <div className="modal-content__main">
          <ul className="landing-page__languages__list">
            <li
              className="landing-page__languages__item"
              onClick={() => handleLanguageChange("ru")}
            >
              <img
                className="landing-page__languages__pic"
                src="/img/icons/ru.svg"
                alt={intl.formatMessage({ id: "RUSSIAN" })}
              />
              <span>
                <FormattedMessage id="RUSSIAN" />
              </span>
            </li>
            <li
              className="landing-page__languages__item"
              onClick={() => handleLanguageChange("en")}
            >
              <img
                className="landing-page__languages__pic"
                src="/img/icons/en.svg"
                alt={intl.formatMessage({ id: "ENGLISH" })}
              />
              <span>
                <FormattedMessage id="ENGLISH" />
              </span>
            </li>
            <li
              className="landing-page__languages__item"
              onClick={() => handleLanguageChange("ch")}
            >
              <img
                className="landing-page__languages__pic"
                src="/img/icons/chi.svg"
                alt={intl.formatMessage({ id: "CHINESE" })}
              />
              <span>
                <FormattedMessage id="CHINESE" />
              </span>
            </li>
            <li
              className="landing-page__languages__item"
              onClick={() => handleLanguageChange("vn")}
            >
              <img
                className="landing-page__languages__pic"
                src="/img/icons/vietnam.svg"
                alt={intl.formatMessage({ id: "VIETNAMESE" })}
              />
              <span>
                <FormattedMessage id="VIETNAMESE" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguagesModal;
