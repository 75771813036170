import { useEffect, useState } from "react";
import { ReferralFilters } from ".";
import { DepositsFiltersSelect } from "../../../components/DepositsFilterSelect";
import {
  DepositsFilterDate,
  DateRange,
} from "../../../components/DepositsFilterDate";
import { useIntl } from "react-intl";

type ReferralsFiltersProps = {
  setFilter: (newFilter: ReferralFilters) => void;
};

type ReferralStatus = "active" | "inActive";

interface StatusFilterType {
  value: string;
  options: { value: ReferralStatus | ""; label: string }[];
}

const defaultDateFilterValue: DateRange = {
  from: undefined,
  to: undefined,
};

export function ReferralsFilters(props: ReferralsFiltersProps) {
  const { setFilter } = props;
  const intl = useIntl();

  const statusFilterDefault: StatusFilterType = {
    value: "",
    options: [
      { value: "", label: intl.formatMessage({ id: "ALL" }) },
      { value: "active", label: intl.formatMessage({ id: "STATE.ACTIVE" }) },
      {
        value: "inActive",
        label: intl.formatMessage({ id: "STATE.INACTIVE" }),
      },
    ],
  };

  const [statusFilter, setStatusFilter] =
    useState<StatusFilterType>(statusFilterDefault);
  const [dateFilter, setDateFilter] = useState<DateRange | undefined>(
    defaultDateFilterValue
  );

  function handleStatusFilterChange(value: string) {
    const newStatusFilter = {
      ...statusFilter,
      value,
    };
    setStatusFilter(newStatusFilter);
    const referralStatusValue: ReferralStatus | "" =
      stringToReferralStatus(value);
    setFilter({
      status: referralStatusValue !== "" ? referralStatusValue : undefined,
    });
  }

  function handleDateFilterChange(value?: DateRange) {
    if (!value) {
      setDateFilter(defaultDateFilterValue);
      return;
    }
    setDateFilter(value);
  }

  function dateFilterChangeFine() {
    setFilter({
      startTime: dateFilter?.from
        ? Date.parse(dateFilter.from.toString()) / 1000
        : undefined,
      endTime: dateFilter?.to
        ? Date.parse(dateFilter.to.toString()) / 1000
        : undefined,
    });
  }

  function stringToReferralStatus(str: string): ReferralStatus | "" {
    if (str === "active" || str === "inActive") return str;
    return "";
  }

  return (
    <div className="history-operations__header">
      <DepositsFiltersSelect
        label={intl.formatMessage({ id: "STATUS" })}
        value={statusFilter.value}
        options={statusFilter.options}
        onChange={handleStatusFilterChange}
      />

      <DepositsFilterDate
        label={intl.formatMessage({ id: "DATE" })}
        value={dateFilter}
        onChange={handleDateFilterChange}
        onClose={dateFilterChangeFine}
      />
    </div>
  );
}
