import { Helmet } from "react-helmet-async";
import { InfocenterItem } from "./InfocenterItem";
import { FormattedMessage, useIntl } from "react-intl";

export function InfocenterPage() {
  const intl = useIntl();

  return (
    <div className="wrapper">
      <Helmet title={intl.formatMessage({ id: "LANDING.FOOTER.FAQ" })} />
      <section className="infocenter">
        <div className="deposits__header">
          <h1 className="deposits__title main-title">
            <FormattedMessage id="LANDING.FOOTER.FAQ" />
          </h1>
        </div>
        <div className="infocenter__inner">
          <div className="infocenter__header">
            <a className="infocenter__header-link main-btn" href="#">
              <FormattedMessage id="COMMISSION" />
            </a>
            <a className="infocenter__header-link main-btn" href="#">
              <FormattedMessage id="SUPPORT" />
            </a>
          </div>
          <div className="infocenter__info">
            <h2 className="infocenter__info-title main-title">
              <FormattedMessage id="FREQUENTLY_ASKED_QUESTIONS" />
            </h2>
            <ul className="infocenter__list">
              <InfocenterItem
                title={intl.formatMessage({
                  id: "INTRODUCTION_TO_THE_SERVICE",
                })}
              >
                <p>
                  <FormattedMessage id="INTRODUCTION_TO_THE_SERVICE.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({
                  id: "ADDING_AND_CONFIGURING_PROJECTS",
                })}
              >
                <p>
                  <FormattedMessage id="ADDING_AND_CONFIGURING_PROJECTS.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({
                  id: "HOW_TO_CREATE_A_PAYMENT_LINK",
                })}
              >
                <p>
                  <FormattedMessage id="HOW_TO_CREATE_A_PAYMENT_LINK.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({ id: "HOW_TO_ADD_EMPLOYEE" })}
              >
                <p>
                  <FormattedMessage id="HOW_TO_ADD_EMPLOYEE.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({ id: "PAYMENTS_WITH_DEPOSIT" })}
              >
                <p>
                  <FormattedMessage id="PAYMENTS_WITH_DEPOSIT.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({ id: "REFERRAL_PROGRAM" })}
              >
                <p>
                  <FormattedMessage id="REFERRAL_PROGRAM.DESCRIPTION" />
                </p>
              </InfocenterItem>
              <InfocenterItem
                title={intl.formatMessage({ id: "HOW_TO_WITHDRAW_FIATS" })}
              >
                <p>
                  <FormattedMessage id="HOW_TO_WITHDRAW_FIATS.DESCRIPTION" />
                </p>
              </InfocenterItem>
            </ul>

            <a className="infocenter__link third-btn" href="#">
              <FormattedMessage id="ALL_QUESTIONS" />
            </a>
          </div>
          <div className="infocenter__news">
            <h3 className="infocenter__new-title main-title">
              <FormattedMessage id="NEWS" />
              <img
                src="/img/icons/angle-right-circle.svg"
                alt={intl.formatMessage({ id: "ICON.ARROW_RIGHT.ALT" })}
              />
            </h3>
            <ul className="infocenter__news-list">
              <li className="infocenter__news-item">
                <a className="infocenter__news-link" href="#">
                  <div className="infocenter__news-info">
                    <img src="/img/icons/mobile.svg" alt="mobile" />
                    <div className="infocenter__news-titles">
                      <h4 className="infocenter__news-label">
                        <FormattedMessage id="TG_WEB_APP_NEWS" />
                      </h4>
                      <p className="infocenter__news-descr">25.07.2023</p>
                    </div>
                  </div>
                  <img src="/img/icons/arrow-right.svg" alt="arrow-right" />
                </a>
              </li>
              <li className="infocenter__news-item">
                <a className="infocenter__news-link" href="#">
                  <div className="infocenter__news-info">
                    <img src="/img/icons/mobile.svg" alt="mobile" />
                    <div className="infocenter__news-titles">
                      <h4 className="infocenter__news-label">
                        <FormattedMessage id="TG_WEB_APP_NEWS" />
                      </h4>
                      <p className="infocenter__news-descr">25.07.2023</p>
                    </div>
                  </div>
                  <img src="/img/icons/arrow-right.svg" alt="arrow-right" />
                </a>
              </li>
              <li className="infocenter__news-item">
                <a className="infocenter__news-link" href="#">
                  <div className="infocenter__news-info">
                    <img src="/img/icons/mobile.svg" alt="mobile" />
                    <div className="infocenter__news-titles">
                      <h4 className="infocenter__news-label">
                        <FormattedMessage id="TG_WEB_APP_NEWS" />
                      </h4>
                      <p className="infocenter__news-descr">25.07.2023</p>
                    </div>
                  </div>
                  <img src="/img/icons/arrow-right.svg" alt="arrow-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
