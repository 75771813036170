import { useEffect, useId, useState } from "react";
import * as yup from "yup";
import { FieldErrors, UseFormReset, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordStrength } from "check-password-strength";
import { useDebounce } from "usehooks-ts";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";


export type ChangePasswordError = { type: "GENERAL"; message?: string };

export interface ChangePasswordFormProps {
  loading: boolean;
  error?: ChangePasswordError;
  onChangePassword: (
    opts: { oldPassword: string; password: string },
    reset: UseFormReset<ChnagePasswordFormData>
  ) => void;
}

export interface ChnagePasswordFormData {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
}

type PasswordStrength = "weak" | "medium" | "strong";


const DEFAULT_FORM_DATA: ChnagePasswordFormData = {
  password: "",
  newPassword: "",
  newPasswordRepeat: "",
};


export function ChangePasswordForm(props: ChangePasswordFormProps) {
  const oldPasswordId = useId();
  const newPasswordId = useId();
  const newPasswordRepeatId = useId();
  const [newPasswordVisisble, setNewPasswordVisible] = useState(false);
  const [newPasswordRepeatVisible, setNewPasswordRepeatVisible] = useState(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const debouncedNewPassword = useDebounce(newPassword, 200);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength | null>(null);
  const intl = useIntl();
  
  const changePasswordFormSchema = yup.object({
    password: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
    newPassword: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
    newPasswordRepeat: yup
      .string()
      .required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<ChnagePasswordFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(changePasswordFormSchema),
  });


  useEffect(() => {
    if (props.error) {
      setError("root", {
        message:
          props.error.message ||
          intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }),
      });
    }
  }, [props.error]);

  useEffect(() => {
    if (debouncedNewPassword.length === 0) {
      setPasswordStrength(null);
      return;
    }
    const nextPasswordStrength = getPasswordStrength(debouncedNewPassword);
    setPasswordStrength(nextPasswordStrength);
  }, [debouncedNewPassword]);


  const handleChangePasswordFormSubmit = handleSubmit((formData) => {
    if (formData.newPassword !== formData.newPasswordRepeat) {
      setError("newPasswordRepeat", {
        message: intl.formatMessage({ id: "FORM.PASSWORD_DOESNT_MATCH" }),
      });
      return;
    }
    const nextPasswordStrength = getPasswordStrength(formData.newPassword);
    if (passwordStrength === "weak") {
      setPasswordStrength(nextPasswordStrength);
      setError("newPassword", { type: "passwordStrength" });
      return;
    }

    props.onChangePassword(
      {
        oldPassword: formData.password,
        password: formData.newPassword,
      },
      reset
    );
    setPasswordStrength(null);
  });

  const handleNewPasswordInputKeyUp = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setNewPassword(ev.currentTarget.value);
  };
  

  return (
    <form
      className="settings-profile__select"
      onSubmit={handleChangePasswordFormSubmit}
    >
      <div className="settings-security__header">
        <h3 className="settings-security__title">
          <FormattedMessage id="CHANGE_PASSWORD" />
        </h3>

        <button className="settings-security__header-btn settings-security__header-btn--password third-btn">
          <FormattedMessage id="CHANGE" />
        </button>
      </div>

      <div className="settings-security__middle">
        <div
          className={classNames("my-projects__group project-group", {
            "project-group--error": !!errors.password,
          })}
        >
          <label
            className="my-projects__label project-label"
            htmlFor={oldPasswordId}
          >
            <FormattedMessage id="FORM.OLD_PASSWORD.LABEL" />
          </label>

          <input
            className="my-projects__input project-input"
            id={oldPasswordId}
            type="password"
            placeholder={intl.formatMessage({
              id: "FORM.OLD_PASSWORD.PLACEHOLDER",
            })}
            disabled={props.loading}
            {...register("password")}
          />

          {renderFieldError(errors, "password")}
        </div>

        <div
          className={classNames(
            "my-projects__group project-group",
            getNewPasswordModifier(errors, passwordStrength)
          )}
        >
          <label
            className="my-projects__label project-label"
            htmlFor={newPasswordId}
          >
            <FormattedMessage id="FORM.NEW_PASSWORD.LABEL" />
          </label>

          <input
            className="my-projects__input project-input"
            id={newPasswordId}
            type={newPasswordVisisble ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "FORM.NEW_PASSWORD.PLACEHOLDER",
            })}
            disabled={props.loading}
            onKeyUp={handleNewPasswordInputKeyUp}
            {...register("newPassword")}
          />

          <button
            type="button"
            className="project-input__see"
            onClick={() => void setNewPasswordVisible(!newPasswordVisisble)}
          >
            <img
              src={
                newPasswordVisisble
                  ? "/img/icons/eye-close.svg"
                  : "/img/icons/eye-open.svg"
              }
              alt={intl.formatMessage({ id: "ICON.EYE.ALT" })}
            />
          </button>

          {renderFieldError(errors, "newPassword")}
        </div>

        {renderPasswordStrengthLabel(passwordStrength)}

        <div
          className={classNames("my-projects__group project-group", {
            "project-group--error": !!errors.newPasswordRepeat,
          })}
        >
          <label
            className="my-projects__label project-label"
            htmlFor={newPasswordRepeatId}
          >
            <FormattedMessage id="FORM.REPEAT_NEW_PASSWORD" />
          </label>

          <input
            className="my-projects__input project-input"
            id={newPasswordRepeatId}
            type={newPasswordRepeatVisible ? "text" : "password"}
            placeholder={intl.formatMessage({ id: "FORM.REPEAT_NEW_PASSWORD" })}
            disabled={props.loading}
            {...register("newPasswordRepeat")}
          />

          <button
            type="button"
            className="project-input__see"
            onClick={() =>
              void setNewPasswordRepeatVisible(!newPasswordRepeatVisible)
            }
          >
            <img
              src={
                newPasswordRepeatVisible
                  ? "/img/icons/eye-close.svg"
                  : "/img/icons/eye-open.svg"
              }
              alt={intl.formatMessage({ id: "ICON.EYE.ALT" })}
            />
          </button>

          {renderFieldError(errors, "newPasswordRepeat")}
        </div>

        {errors.root && errors.root.message && (
          <div className="my-projects__error">{errors.root.message}</div>
        )}
      </div>
      <button
        type="submit"
        className="settings-security__btn main-btn"
        disabled={props.loading}
      >
        <FormattedMessage id="SAVE" />
      </button>
    </form>
  );
}

function renderFieldError(
  errors: FieldErrors<ChnagePasswordFormData>,
  field: keyof ChnagePasswordFormData
) {
  const error = errors[field];
  if (!error || !error.message) {
    return null;
  }
  return <div className="project-error">{error.message}</div>;
}

function getNewPasswordModifier(
  errors: FieldErrors<ChnagePasswordFormData>,
  passwordStrength: PasswordStrength | null
): string | null {
  if (errors.newPassword) {
    return "project-group--error";
  }

  switch (passwordStrength) {
    case "weak": {
      return "project-group--error";
    }
    case "medium":
    case "strong": {
      return "project-group--successfully";
    }
    case null: {
      return null;
    }
  }
}

function renderPasswordStrengthLabel(
  passwordStrength: PasswordStrength | null
) {
  switch (passwordStrength) {
    case "weak": {
      return (
        <div className="settings-security__input-error project-password__complexity project-password__complexity--bad">
          <div className="project-password__complexity-circle"></div>
          <p className="project-password__complexity-text">
            <FormattedMessage id="BAD_PASSWORD" />
          </p>
        </div>
      );
    }
    case "medium": {
      return (
        <div className="settings-security__input-error project-password__complexity project-password__complexity--middle">
          <div className="project-password__complexity-circle"></div>
          <p className="project-password__complexity-text">
            <FormattedMessage id="MEDIUM_PASSWORD" />
          </p>
        </div>
      );
    }
    case "strong": {
      return (
        <div className="settings-security__input-error project-password__complexity project-password__complexity--perfect">
          <div className="project-password__complexity-circle"></div>
          <p className="project-password__complexity-text">
            <FormattedMessage id="STRONG_PASSWORD" />
          </p>
        </div>
      );
    }
    case null: {
      return null;
    }
  }
}

function getPasswordStrength(password: string): PasswordStrength {
  const strength = passwordStrength(password);
  switch (strength.id) {
    case 0:
    case 1: {
      return "weak";
    }
    case 2: {
      return "medium";
    }
    default: {
      return "strong";
    }
  }
}
