import { ROUTE } from "@constants/path-locations";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

export function HistoryOperationsHeader() {
  const intl = useIntl();

  return (
    <div className="history-operations__label">
      <h3 className="history-operations__title main-title">
        <FormattedMessage id="OPERATIONS_HISTORY" />
      </h3>

      <NavLink className="history-operations__link" to={ROUTE.HISTORY_PATH}>
        <FormattedMessage id="ICON.ARROW_RIGHT.MY_ACTIVES.ALT" />
        <img
          className="history-operations__link-img"
          src="/img/icons/arrow-right.svg"
          alt={intl.formatMessage({ id: "ICON.ARROW_RIGHT.MY_ACTIVES.ALT" })}
        />
      </NavLink>
    </div>
  );
}
