import classNames from "classnames"
import { useEffect, useMemo } from "react"
import { useDropdown } from "../../../../../hooks/useDropdown"


export interface CurrencySelectorOpts {
  options: { label: string; value: string; disabled?: boolean }[]
  value: string
  disabled?: boolean
  onChange: (value: string) => void
}


export function CurrencySelector(props: CurrencySelectorOpts) {
  const dropdown = useDropdown<HTMLDivElement>()

  const valueToLabel = useMemo(
    () => new Map(props.options.map((option) => [option.value, option.label])),
    [props.options]
  )


  useEffect(() => {
    dropdown.toggle(false)
  }, [props.disabled])


  const handleSelectClick = () => {
    if (props.disabled || props.options.length === 0)  return
    dropdown.toggle()
  }

  const handleOptionClick = (value: string, disabled: boolean | undefined) => {
    if (props.disabled || disabled || value === null)  return

    props.onChange(value)
    dropdown.toggle(false)
  }


  return (
    <div className="my-projects__group-select"
      data-select-wrapper=""
      ref={dropdown.containerRef}
    >
      <div className={classNames(
          "my-projects__group-selected",
          Boolean(props.disabled) && "my-projects__group-selected--disabled"
        )}
        data-select-arrow=""
        onClick={handleSelectClick}
      >
        {valueToLabel.get(props.value) || "---"}

        <img className={classNames("my-projects__group-select-arrow", {
            active: dropdown.opened,
          })}
          src="/img/icons/mini-arrow-down.svg"
          alt="mini-arrow-down"
        />
      </div>

      <ul className={classNames("my-projects__group-list select-list", {
          active: dropdown.opened,
        })}
        data-select-list=""
      >
        {props.options.map(({ label, value, disabled }) => (
          <li className={`my-projects__group-item select-item${disabled ? ' disabled' : ''}`}
            onClick={() => handleOptionClick(value, disabled)}
            key={value}
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  )
}