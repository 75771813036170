import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NityfySubType, useAccountNotifications } from "../../hooks/useAccountNotifications";
import { NotificationsFilters } from "./NotificationsFilters";
import { NotificationsAccordion } from "./NotificationsAccordion";
import { Pagination } from "@components/Pagination";
import { FormattedMessage } from "react-intl";
import { ROUTE } from "@constants/path-locations";


export function NotificationsPage() {
  const location = useLocation();
  const [openedNotificationId, setOpenedNotificationId] = useState<
    number | null
  >(null);
  const {
    notificationsFiltered,
    page,
    pages,
    count,
    unread,
    notificationTypes,
    setNotificationFilter,
    setStatusRead,
  } = useAccountNotifications();
  const navigate = useNavigate();


  useEffect(() => {
    if (location.state && "notificationID" in location.state) {
      const id = location.state.notificationID;
      setOpenedNotificationId(id);
      setStatusRead(id, true);
    }
  }, [location]);


  function toggleOpenedNotification(notificationID: number, type: string, notificationSubType: NityfySubType, orderId: number | undefined): void {
    if (notificationID === openedNotificationId) {
      setOpenedNotificationId(null);
      return;
    }
    setOpenedNotificationId(notificationID);
    setStatusRead(notificationID, true);
    
    if(notificationSubType) {
      setStatusRead(notificationID, true);
      let navigateLink

      switch (notificationSubType) {
        case "return":
          navigateLink = ROUTE.DEPOSIT_RETENTION_PATH
          break;
        case "infoRequested":
          navigateLink = ROUTE.DEPOSIT_WITHHOLD_REQUESTS
          break;
        default:
          navigateLink = ROUTE.DEPOSIT_RETENTION_PATH
          break;
      }
      navigate(navigateLink, {
        replace: false,
        state: { orderId }
      })
    }
  }

  function changePage(page: number): void {
    setNotificationFilter({
      page: page.toString(),
    });
  }


  return (
    <div className="wrapper">
      <div className="notifications">
        <div className="notifications__header about-deposit__header-notif">
          <h1 className="notifications__title main-title">
            <FormattedMessage id="NOTIFICATIONS_TITLE" />
          </h1>
          {unread && unread > 0 ? <span>{unread}</span> : ""}
        </div>

        <NotificationsFilters setFilter={setNotificationFilter} />

        <NotificationsAccordion
          notifications={notificationsFiltered}
          openedNotificationId={openedNotificationId}
          clickNotification={toggleOpenedNotification}
        />

        <Pagination currentPage={page} pages={pages} goToPage={changePage} />
      </div>
    </div>
  );
}
