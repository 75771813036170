import { useEffect, useState } from "react"


const useIntegerFractionNumber = (initialData: number | undefined, decimals: number = 2) => {
    const [data, setData] = useState(initialData ? initialData : 0)
    const [integer, setInteger] = useState('')
    const [fraction, setFraction] = useState('')


    useEffect(() => {
        if(!data || data === 0) {
            setInteger('0')
            setFraction('0')
            return
        }

        const integerPart = Math.trunc(data)
        const fractionalPart = ((data - integerPart).toString()).slice(2, decimals + 2)
        let integerPartStr = integerPart.toString()
        if (integerPartStr.length > 3) {
            integerPartStr = integerPartStr.replace(/\d(?=(\d{3})+$)/g, "$&.")
        }

        setInteger(integerPartStr)
        setFraction(fractionalPart)
    }, [data])

    return { integer, fraction, setData }
}

export { useIntegerFractionNumber }