import { useEffect, useId, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AuthenticatedService } from "@awex-api";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

interface GmailFormMailProps {
  close: () => void;
  isSentEmail: () => void;
  isFocus: boolean;
}

interface GmailFormData {
  gmail: string;
}

export default function GmailFormMail(props: GmailFormMailProps) {
  const { close, isSentEmail, isFocus } = props;
  const inputId = useId();
  const [emailInProcess, setEmailInProcess] = useState<boolean>(false);
  const intl = useIntl();

  const gmailFormValidator = yup.object({
    gmail: yup.string().required(intl.formatMessage({ id: "FORM.REQUIRED" })),
  });

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<GmailFormData>({
    resolver: yupResolver(gmailFormValidator),
  });

  useEffect(() => {
    if (isFocus) {
      setFocus("gmail");
    }
  }, [isFocus]);

  const sendGmailCode = handleSubmit((formData) => {
    if (emailInProcess) return;
    setEmailInProcess(true);

    if (gmailValidate(formData.gmail)) {
      AuthenticatedService.setGmail({ email: formData.gmail })
        .then((response) => {
          if (!response) {
            toast.error(
              intl.formatMessage({
                id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED",
              })
            );
            return;
          }
          reset();
          sendGmail();
          isSentEmail();
        })
        .catch((error) => {
          toast.error(intl.formatMessage({ id: "ERROR.EMAIL_NOT_SENT" }));
          console.error(error);
        })
        .finally(() => {
          setEmailInProcess(false);
        });
      return;
    }
    setEmailInProcess(false);
    toast.error(intl.formatMessage({ id: "ERROR.GMAIL_ONLY" }));
    setError("gmail", {
      type: "manual",
      message: intl.formatMessage({ id: "ERROR.GMAIL_ONLY" }),
    });
  });

  function gmailValidate(email: string): boolean {
    const rez = email.indexOf("@gmail.");
    return rez > 0 ? true : false;
  }

  function sendGmail(): void {
    AuthenticatedService.sendGmail()
      .then((response) => {
        if (!response) {
          toast.error(intl.formatMessage({ id: "ERROR.EMAIL_NOT_SENT" }));
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(intl.formatMessage({ id: "ERROR.EMAIL_NOT_SENT" }));
      });
  }

  return (
    <form
      className="modal-content"
      onClick={(ev) => ev.stopPropagation()}
      onSubmit={sendGmailCode}
    >
      <div className="modal-content__header">
        <button className="close-modal-btn" type="button" onClick={close}>
          <img
            src="/img/icons/close-modal.svg"
            alt={intl.formatMessage({ id: "ICON.X_MARK" })}
          />
        </button>
      </div>

      <div className="modal-content__main">
        <div className="settings-security__middle">
          <div className={"my-projects__group project-group"}>
            <label
              className="my-projects__label project-label"
              htmlFor={inputId}
            >
              <FormattedMessage id="FORM.GMAIL.LABEL" />
            </label>

            <input
              className="my-projects__input project-input"
              type="text"
              id={inputId}
              placeholder={intl.formatMessage({ id: "FORM.GMAIL.PLACEHOLDER" })}
              {...register("gmail")}
            />

            {errors.gmail?.message && (
              <div className="project-error">{errors.gmail.message}</div>
            )}
          </div>
        </div>
      </div>

      <button type="submit" className="modal-content__btn second-btn">
        <FormattedMessage id="RESET_PASSWORD.FORM.BTN.SUBMIT" />
      </button>
    </form>
  );
}
