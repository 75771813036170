import React from "react";
import OfficeAddressCard from "./OfficeAddressCard";

interface IProps {
  officeAddresses: any[];
  deleteAddressHandler: (id: number) => void;
}

const OfficeAddressList: React.FC<IProps> = ({
  officeAddresses,
  deleteAddressHandler,
}) => {
  return (
    <ul className="settings-requisites__list settings-requisites__list--addresses">
      {officeAddresses?.map((officeAddress) => (
        <OfficeAddressCard
          key={officeAddress?.id}
          officeAddress={officeAddress}
          deleteAddressHandler={deleteAddressHandler}
        />
      ))}
    </ul>
  );
};

export default OfficeAddressList;
