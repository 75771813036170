import { AuthorizedService } from "@awex-api";
import { useEffect, useState } from "react";
import { DepositInSumm } from "../DepositInSumm";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "@constants/path-locations";


interface DepositInfoData {
  withdrawRequestsNumber: number;
  withdrawRequestsAmount: string;
  activeDepositsNumber: number;
  activeDepositsAmount: string;
  onReviewWithdrawRequestsNumber: number;
}


export function DepositInfo() {
  const [data, setData] = useState<DepositInfoData | null>(null);
  const [dataisLoading, setDataisLoading] = useState<boolean>(false);
  const navigate = useNavigate()


  useEffect(() => {
    getData();
  }, []);


  function getData() {
    if (dataisLoading) return;
    setDataisLoading(true);
    AuthorizedService.getOrderDepositData()
      .then((response) => {
        if (!response) return;
        setData(response);
      })
      .catch((error) => {
        console.error(error);
        setData(null);
      })
      .finally(() => {
        setDataisLoading(false);
      });
  }

  function withdrawRequestsClickHanle() {
    navigate(ROUTE.DEPOSIT_RETENTION_PATH)
  }

  function reviewWithdrawRequestsClickHanle() {
    navigate(ROUTE.DEPOSIT_WITHHOLD_REQUESTS)
  }


  return (
    <>
      <div className="deposits__infos">
        <div className={`deposits__info ${data?.withdrawRequestsNumber && data?.withdrawRequestsNumber > 0 ? "deposits__info--black" : ""}`}
          onClick={withdrawRequestsClickHanle}
        >
          <div className="deposits__info-row">
            <div className="deposits__info-name">
              <FormattedMessage id="RETURN_REQUESTS" />
            </div>
            <div className={`deposits__info-count ${data?.withdrawRequestsNumber && data?.withdrawRequestsNumber > 0 ? "deposits__info-count--red" : ""}`}>
              {data?.withdrawRequestsNumber}
            </div>
          </div>

          <div className="deposits__info-row">
            <div className="deposits__info-label">
              <FormattedMessage id="DASHBOARD.FOR_THE_AMOUNT" />
            </div>
            <div className="deposits__info-sum">
              {data?.withdrawRequestsAmount}
            </div>
          </div>
        </div>

        <div className="deposits__info">
          <div className="deposits__info-row">
            <div className="deposits__info-name">
              <FormattedMessage id="DASHBOARD.ACTIVE_DEPOSITS" />
            </div>
            <div className="deposits__info-count">
              {data?.activeDepositsNumber}
            </div>
          </div>

          <div className="deposits__info-row">
            <div className="deposits__info-label">
              <FormattedMessage id="DASHBOARD.FOR_THE_AMOUNT" />
            </div>
            <div className="deposits__info-sum">
              {data?.activeDepositsAmount}
            </div>
          </div>
        </div>

        <div className={`deposits__info ${ data?.onReviewWithdrawRequestsNumber && data?.onReviewWithdrawRequestsNumber > 0 ? "deposits__info--black" : ""}`}
          onClick={reviewWithdrawRequestsClickHanle}
        >
          <div className="deposits__info-row">
            <div className="deposits__info-name">
              <FormattedMessage id="UNDER_REVIEW" />
            </div>
            <div className={`deposits__info-count ${data?.onReviewWithdrawRequestsNumber && data?.onReviewWithdrawRequestsNumber > 0 ? "deposits__info-count--red" : ""}`}>
              {data?.onReviewWithdrawRequestsNumber}
            </div>
          </div>
        </div>
      </div>

      <DepositInSumm amount={Number(data?.activeDepositsAmount)} />
    </>
  );
}
