import { FormattedMessage } from "react-intl";

export interface CreateProjectFooterProps {
  onCancel: () => void;
}

export function CreateProjectFooter(props: CreateProjectFooterProps) {
  return (
    <div className="my-projects__item-btns">
      <button
        type="button"
        className="my-projects__btn third-btn"
        onClick={props.onCancel}
      >
        <FormattedMessage id="CANCEL" />
      </button>

      <button type="submit" className="my-projects__btn second-btn">
        <FormattedMessage id="CREATE_PROJECT" />
      </button>
    </div>
  );
}
