import classNames from "classnames";
import { AuthModalContent, AuthStage } from "./AuthModalContent";
import { ConfirmEmailModalContent } from "@components/ConfirmEmailModalContent";
import { ConfirmOtpModalContent } from "@components/ConfirmOtpModalContent";
import { useIntl } from "react-intl";

export { type AuthStage } from "./AuthModalContent";

export interface AuthModalProps {
  open: boolean;
  stage: AuthStage;
  loading: boolean;
  signInError?: { type: "unknown" | "auth" };
  verifyEmail: string;
  verifyError?: { type: "unknown"; message?: string };
  onClose: () => void;
  onSignIn: (opts: { login: string; password: string }) => void;
  onNavRegister: () => void;
  onNavRecover: () => void;
  onNavDescribeProblem: () => void;
  onNavBlockProfile: () => void;
  onConfirmCode: (code: string) => void;
  onResendCode: () => void;
  otpEnableds: string[];
  isOtpLoading: boolean;
  sendEmail: () => void;
}

export function AuthModal(props: AuthModalProps) {
  const intl = useIntl();

  let modalContent;
  switch (props.stage) {
    case "auth": {
      modalContent = (
        <AuthModalContent
          open={props.open}
          loading={props.loading}
          error={props.signInError}
          onClose={props.onClose}
          onSignIn={props.onSignIn}
          onNavRegister={props.onNavRegister}
          onNavRecover={props.onNavRecover}
          onNavDescribeProblem={props.onNavDescribeProblem}
          onNavBlockProfile={props.onNavBlockProfile}
        />
      );
      break;
    }
    case "verify": {
      modalContent = (
        <ConfirmEmailModalContent
          open={props.open}
          loading={props.loading}
          title={intl.formatMessage({ id: "ENTRANCE" })}
          error={props.verifyError}
          verifyEmail={props.verifyEmail}
          onClose={props.onClose}
          onConfirmCode={props.onConfirmCode}
          onResendCode={props.onResendCode}
        />
      );
      break;
    }
    case "otpRequired": {
      modalContent = (
        <ConfirmOtpModalContent
          open={props.open}
          loading={props.loading}
          title={intl.formatMessage({ id: "ENTRANCE" })}
          error={props.verifyError}
          onClose={props.onClose}
          otpEnableds={props.otpEnableds}
          isOtpLoading={props.isOtpLoading}
          sendEmail={props.sendEmail}
        />
      );
      break;
    }
  }
  return (
    <div className={classNames("modal modal-enter", { show: props.open })}>
      {modalContent}
    </div>
  );
}
