import { AuthenticatedService } from "@awex-api";
import { useState } from "react";
import toast from "react-hot-toast";
import GoogleModal from "./GoogleModal";
import { OtpEnable } from ".";
import { FormattedMessage, useIntl } from "react-intl";

interface GoogleAuthenticatorPrpos {
  otpEnable: (val: OtpEnable) => void;
  isGlobalProcess: boolean;
  status: boolean;
}

const _2FA_TYPE = "googleAuthenticator";

export default function GoogleAuthenticator(props: GoogleAuthenticatorPrpos) {
  const { otpEnable, isGlobalProcess, status } = props;
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [googleUri, setGoogleUri] = useState<string>("");
  const [googleModalOpen, setGoogleModalOpen] = useState<boolean>(false);

  const intl = useIntl();

  function googleAuthHandlerClick(event: any): void {
    event.preventDefault();

    if (inProcess || isGlobalProcess) return;
    setInProcess(true);
    AuthenticatedService.setGoogleAuthenticator()
      .then((response) => {
        if (!response || !response.uri) {
          toast.error(
            intl.formatMessage({ id: "ERROR.AN_UNEXPECTED_ERROR_HAS_OCCURRED" })
          );
          return;
        }
        let uri: string = response.uri;
        getOptCode(uri);
      })
      .catch((error) => {
        toast.error(intl.formatMessage({ id: "ERROR.CONNECTION_FAILED" }));
        console.error(error);
      })
      .finally(() => {
        setInProcess(false);
      });
  }

  function getOptCode(uri: string): void {
    setGoogleUri(uri);
    setGoogleModalOpen(true);
  }

  function cancel(): void {
    setGoogleUri("");
    setGoogleModalOpen(false);
  }

  function confirm(otpCode: string): void {
    setGoogleModalOpen(false);
    otpEnable({
      _2faType: _2FA_TYPE,
      otp: otpCode,
    });
  }

  return (
    <>
      {status && (
        <button className="green-btn" type="button" disabled>
          <FormattedMessage id="GOOGLE_AUTHENTICATOR" />
        </button>
      )}

      {!status && (
        <>
          <button
            className="settings-security__btn main-btn"
            type="button"
            disabled={inProcess || isGlobalProcess}
            onClick={googleAuthHandlerClick}
          >
            <FormattedMessage id="GOOGLE_AUTHENTICATOR" />
          </button>

          <GoogleModal
            isOpen={googleModalOpen}
            uri={googleUri}
            close={cancel}
            confirm={confirm}
          />
        </>
      )}
    </>
  );
}
